import http from '../utils/http';
import { apiConstants } from '../constants';

export function fetchNearbyRestaurants() {
  return http.get(apiConstants.NEARBY_RESTAURANTS, {
    params: {
      limit: 8,
    },
  });
}

export function fetchSpecialOffers() {
  return http.get(apiConstants.SPECIAL_OFFER, {
    params: {
      limit: 8,
    },
  });
}

export function fetchTopRestaurants() {
  return http.get(apiConstants.TOP_RESTAURANTS, {
    params: {
      limit: 8,
    },
  });
}

export function fetchRestaurantDetails(id) {
  return http.get(`${apiConstants.RESTAURANTS}/${id}`);
}

export function fetchRestaurantProducts(id) {
  return http.get(`${apiConstants.RESTAURANTS}/${id}/categories/-/products`);
}

export function fetchRestaurants(searchValue = [], searchParams = []) {
  let url = `${apiConstants.RESTAURANTS}?`;
  searchParams.forEach((single, o) => {
    url += `filter[${single}]=${searchValue[o]}&`;
  });
  return http.get(url);
}

export function fetchRestaurantTypes() {
  return http.get(apiConstants.RESTAURANT_TYPES);
}

export function fetchAddons(id) {
  return http.get(`/categories/-/products/${id}/addon`);
}

export function fetchThreeLevelMenu(id) {
  return http.get(`/restaurants/${id}/categories-products`);
}
