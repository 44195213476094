import { reducerConstants } from '../constants';

export default function creditPoint(
  state = {
    info: {},
    isLogin: false,
  },
  action
) {
  switch (action.type) {
    case reducerConstants.SET_CREDIT_POINT:
      return { ...state, info: action.payload };
    default:
      return state;
  }
}
