import { calculateItems, calculations } from '../utils/carts';
import { reducerConstants } from '../constants';

export default function cartData(
  state = {
    items: [],
    calculated: {},
    restaurantInfo: {},
  },
  action
) {
  let items = [...state.items];
  let restaurantInfo = { ...state.restaurantInfo };
  let temp = {};
  switch (action.type) {
    case reducerConstants.ADD_TO_CART:
      if (restaurantInfo.id !== action.restaurantInfo.id) {
        items = [action.payload];
      } else {
        items = [...state.items, action.payload];
      }
      items = calculateItems(items);
      temp = {
        items,
        calculated: calculations(items, action.restaurantInfo),
        restaurantInfo: action.restaurantInfo,
      };
      localStorage.setItem('cart', JSON.stringify(temp));
      return {
        ...state,
        ...temp,
      };
    case reducerConstants.REMOVE_FROM_CART:
      if (action.index > -1) {
        items.splice(action.index, 1);
      }
      temp = {
        items,
        calculated: calculations(items, restaurantInfo),
        restaurantInfo,
      };
      localStorage.setItem('cart', JSON.stringify(temp));
      return {
        ...state,
        ...temp,
      };
    case reducerConstants.REPLACE_CART:
      if (action.index > -1) {
        items[action.index] = action.payload;
      }
      temp = {
        items: calculateItems(items),
        calculated: calculations(items, state.restaurantInfo, action.deliveryType),
        restaurantInfo: state.restaurantInfo,
      };
      localStorage.setItem('cart', JSON.stringify(temp));
      return { ...state, ...temp };
    case reducerConstants.SET_CART:
      return {
        ...state,
        items: action.items,
        calculated: action.calculated,
        restaurantInfo: action.restaurantInfo,
      };
    case reducerConstants.DELIVERY_TYPE:
      return {
        ...state,
        calculated: calculations(
          state.items,
          state.restaurantInfo,
          action.deliveryType
        ),
      };
    case reducerConstants.RESET_CART:
      return {
        ...state,
        items: [],
        calculated: {},
        restaurantInfo: {},
      };
    default:
      return state;
  }
}
