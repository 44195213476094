import React from 'react';
import { MdPlace, MdMail } from 'react-icons/md';
import { AiFillFacebook } from 'react-icons/ai';
import { RiShoppingCartLine, RiUserLine } from 'react-icons/ri';
import { NavLink, withRouter } from 'react-router-dom';
import { Dropdown } from 'react-bootstrap';
import { connect } from 'react-redux';
import Draggable from 'react-draggable';

import Login from '../modals/Login';
import Register from '../modals/Register';
import Forgot from '../modals/Forgot';
import logo from '../../assets/images/logo.png';
import Cart from './Cart';
import { setCart } from '../../actions/carts';
import { setLanguage } from '../../actions/language';
import { getInfo, getAvailableCreditPoints } from '../../services';
import { profileUtils } from '../../utils/profile';
import {
  setProfile,
  resetProfile,
  closeLogin,
  setCreditPoint,
} from '../../actions/profile';
import { storage } from '../../utils/storage';
import { language } from '../../constants';
import england from '../../assets/images/england.png';
import finland from '../../assets/images/finland.png';
import googlePlay from '../../assets/images/google-play.png';
import appStore from '../../assets/images/app-store.png';

class MainLayout extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      restaurant: '',
      isLogin: false,
      isRegister: false,
      isForgot: false,
      isCart: false,
      isAuthenticated: false,
      noOfItems: 0,
      creditPoint: 0,
    };

    this.onDrag = this.onDrag.bind(this);
    this.onStop = this.onStop.bind(this);
  }

  componentDidMount() {
    const fullNewOrder = JSON.parse(localStorage.getItem('FULL_NEW_ORDER'));

    if (fullNewOrder && fullNewOrder.order_detail) {
      this.setState({
        noOfItems: fullNewOrder.order_detail.length,
      });
    } else {
      let cartItems = localStorage.getItem('cart');
      cartItems = JSON.parse(cartItems);
      if (cartItems) {
        this.props.setCart(
          cartItems.items,
          cartItems.calculated,
          cartItems.restaurantInfo
        );
        this.setState({
          noOfItems: Object.keys(cartItems.items).length,
        });
      }
    }

    if (Object.keys(this.props.profile).length > 0) {
      this.setState({ isAuthenticated: true });
    } else {
      this.setState({ isAuthenticated: false });
      const userId = profileUtils.getUserId();
      if (userId) {
        getInfo(userId).then((res) => {
          this.props.setProfile(res.data);
        });
        getAvailableCreditPoints().then((res) => {
          this.props.setCreditPoint(res.data);
        });
      }
    }
    const currentlang = storage.getLanguage();
    if (currentlang) {
      this.props.setLanguage(currentlang);
    }
  }

  checkAuthenticated = () => {
    if (Object.keys(this.props.profile).length > 0) {
      this.setState({ isAuthenticated: true });
    } else {
      this.setState({ isAuthenticated: false });
    }
  };

  componentDidUpdate(prevProps) {
    if (this.props.profile !== prevProps.profile) {
      this.checkAuthenticated();
    }
    if (this.props.isLogin !== prevProps.isLogin) {
      this.setState({
        isLogin: this.props.isLogin,
      });
    }
    if (this.props.cartData !== prevProps.cartData) {
      this.setState({
        noOfItems: Object.keys(this.props.cartData.items).length,
      });
    }
    if (this.props.newOrder !== prevProps.newOrder) {
      this.setState({
        noOfItems: this.props.newOrder.order_detail.length,
      });
    }

    if (
      this.props.creditPoint !== prevProps.creditPoint ||
      this.props.creditPoint !== this.state.creditPoint
    ) {
      this.setState({
        creditPoint: this.props.creditPoint,
      });
    }
  }

  changeRestaurant = (e) => {
    this.setState({
      restaurant: e.target.value,
    });
  };

  searchRestaurant = () => {
    this.props.history.push(`/restaurants?name=${this.state.restaurant}`);
    this.setState({
      restaurant: '',
    });
  };

  closeLoginModal = () => {
    this.setState({
      isLogin: false,
    });
    this.props.closeLogin();
  };

  goToRegister = () => {
    this.setState({
      isLogin: false,
      isRegister: true,
    });
  };

  goToLogin = () => {
    this.setState({
      isLogin: true,
      isRegister: false,
    });
  };

  goToForgot = () => {
    this.setState({
      isLogin: false,
      isForgot: true,
    });
  };

  closeRegister = () => {
    this.setState({
      isRegister: false,
    });
  };

  closeForgot = () => {
    this.setState({ isForgot: false });
  };

  toggleCart = () => {
    this.props.history.push('/my-basket');
    // this.setState({
    //   isCart: !this.state.isCart,
    // });
  };

  goToOrderHistory = () => {
    this.props.history.push('/order-history');
  };

  goToProfile = () => {
    this.props.history.push('/profile');
  };

  onLogout = () => {
    storage.logout();
    this.props.resetProfile();
    this.props.history.push('/');
  };

  onkeyPress = (e) => {
    if (e.keyCode === 13) {
      this.searchRestaurant();
    }
  };

  changeImage = () => {
    const lang = this.props.lang === 'fin' ? 'en' : 'fin';
    this.props.setLanguage(lang);
    storage.setLanguage(lang);
  };

  trackOrder = () => {
    this.props.history.push(`/track-order/${this.props.currentOrder.id}`);
  };

  onDrag = () => {
    this.setState({ dragging: true });
  };

  onStop = () => {
    const { dragging } = this.state;
    if (!dragging) {
      this.trackOrder();
    }

    this.setState({ dragging: false });
  };

  render() {
    const { lang, isDashboard, currentOrder } = this.props;
    const {
      isLogin,
      isRegister,
      isForgot,
      isCart,
      isAuthenticated,
      noOfItems,
      creditPoint,
    } = this.state;
    const img = currentOrder.restaurant && currentOrder.restaurant.logo;
    return (
      <React.Fragment>
        <Login
          show={isLogin}
          closeModal={this.closeLoginModal}
          goToRegister={this.goToRegister}
          goToForgot={this.goToForgot}
        />
        <Register
          show={isRegister}
          closeModal={this.closeRegister}
          goToLogin={this.goToLogin}
        />
        <Forgot show={isForgot} closeModal={this.closeForgot} />
        <Cart isOpen={isCart} closeCart={this.toggleCart} />
        <header className='header'>
          <div className='container'>
            <div className='row big-screen'>
              <div className='col-md-3 col-4 logo'>
                <div className='d-flex align-items-center'>
                  <a href='/'>
                    <img src={logo} alt='Mulas' />
                  </a>
                  <span
                    className='company-name'
                    onClick={() => {
                      this.props.history.push('/');
                    }}
                    style={{ cursor: 'pointer' }}
                  >
                    Mulas
                  </span>
                </div>
              </div>
              <div className='col-md-6 col-4'></div>
              {isAuthenticated ? (
                <div className='col-md-3 col-4 logo d-flex header-action-icons'>
                  <span
                    style={{
                      padding: '5px 5px 5px 5px',
                      borderBottom: '1px solid #ef692a',
                      marginRight: '10px',
                    }}
                    title='Credit Point'
                  >
                    <i
                      className='fa fa-hand-holding-usd'
                      style={{ color: '#ef692a' }}
                    >
                      &nbsp;€{creditPoint.credit_point}
                    </i>
                  </span>
                  <img
                    src={lang === 'fin' ? england : finland}
                    onClick={this.changeImage}
                    className='big-screen-flag'
                    alt='flag'
                  />
                  <RiShoppingCartLine
                    className='header-action-icon'
                    onClick={this.toggleCart}
                  />
                  {noOfItems ? (
                    <span className='badge badge-mulas'>{noOfItems}</span>
                  ) : (
                    ''
                  )}
                  <Dropdown>
                    <Dropdown.Toggle>
                      <RiUserLine
                        className='header-action-icon dropdown-toggle'
                        data-toggle='dropdown'
                        id='dropdownProfile'
                      />
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                      <Dropdown.Item onClick={this.goToProfile}>
                        <span>{language(lang).EDIT_PROFILE}</span>
                      </Dropdown.Item>
                      <Dropdown.Item onClick={this.goToOrderHistory}>
                        <span>{language(lang).ORDER_HISTORY}</span>
                      </Dropdown.Item>
                      <Dropdown.Item onClick={this.onLogout}>
                        <span>{language(lang).SIGN_OUT}</span>
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
              ) : (
                <div className='col-md-3 col-4 logo text-right'>
                  <img
                    src={lang === 'fin' ? england : finland}
                    onClick={this.changeImage}
                    className='big-screen-flag'
                    alt='flag'
                  />
                  <button className='login-button' onClick={this.goToLogin}>
                    {language(lang).LOGIN}
                  </button>
                </div>
              )}
            </div>
            <div className='row small-screen' style={{ width: '100%' }}>
              <div
                className='d-flex'
                style={{ justifyContent: 'space-between' }}
              >
                <div className='logo'>
                  <a href='/'>
                    <img src={logo} alt='Mulas' />
                  </a>
                </div>
                <div style={{ flex: 6, margin: '0 5px' }}></div>
                <div>
                  {isAuthenticated ? (
                    <div className='logo d-flex header-action-icons'>
                      <span
                        style={{
                          padding: '5px 5px 5px 5px',
                          borderBottom: '1px solid #ef692a',
                          marginRight: '10px',
                        }}
                        title='Credit Point'
                      >
                        <i
                          className='fa fa-hand-holding-usd'
                          style={{ color: '#ef692a' }}
                        >
                          &nbsp;€{creditPoint.credit_point}
                        </i>
                      </span>
                      <img
                        src={lang === 'fin' ? england : finland}
                        onClick={this.changeImage}
                        className='small-screen-flag'
                        alt='flag'
                      />
                      <RiShoppingCartLine
                        className='header-action-icon'
                        onClick={this.toggleCart}
                      />
                      {noOfItems ? (
                        <span className='badge badge-mulas-sm'>
                          {noOfItems}
                        </span>
                      ) : (
                        ''
                      )}
                      <Dropdown>
                        <Dropdown.Toggle>
                          <RiUserLine
                            className='header-action-icon dropdown-toggle'
                            data-toggle='dropdown'
                            id='dropdownProfile'
                          />
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                          <Dropdown.Item onClick={this.goToProfile}>
                            <span>{language(lang).EDIT_PROFILE}</span>
                          </Dropdown.Item>
                          <Dropdown.Item onClick={this.goToOrderHistory}>
                            <span>{language(lang).ORDER_HISTORY}</span>
                          </Dropdown.Item>
                          <Dropdown.Item onClick={this.onLogout}>
                            <span>{language(lang).SIGN_OUT}</span>
                          </Dropdown.Item>
                        </Dropdown.Menu>
                      </Dropdown>
                    </div>
                  ) : (
                    <div className='logo text-right'>
                      <img
                        src={lang === 'fin' ? england : finland}
                        onClick={this.changeImage}
                        className='small-screen-flag'
                        alt='flag'
                      />
                      <button className='login-button' onClick={this.goToLogin}>
                        {language(lang).LOGIN}
                      </button>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </header>
        {img ? (
          <Draggable onDrag={this.onDrag} onStop={this.onStop}>
            <img
              src={img}
              className='sticky-order rounded-circle'
              alt='restaurant'
            />
          </Draggable>
        ) : null}
        <div style={{ height: '51px' }}></div>
        {this.props.children}
        {isDashboard && (
          <footer>
            <div className='container'>
              <div className='row'>
                <div className='col-sm-12 text-center'>
                  <a href='https://apps.apple.com/fi/app/mulas-orders/id1549921862'>
                    <img
                      src={appStore}
                      style={{ maxHeight: '69px', cursor: 'pointer' }}
                      alt='app-store'
                    />
                  </a>
                  <a href='https://play.google.com/store/apps/details?id=com.mulas.customer.app'>
                    <img
                      src={googlePlay}
                      style={{ maxHeight: '100px', cursor: 'pointer' }}
                      alt='google-play'
                    />
                  </a>
                </div>
              </div>
              <div className='row mt-2'>
                <div className='col-md-4'>
                  <div>
                    <span>
                      <strong>{language(lang).GET_IN_TOUCH}</strong>
                    </span>
                    <ul className='remove-bullet'>
                      <li>
                        <MdPlace className='mulas-icon' />
                        <span>Linnavuoretie 19, Helsinki, Finland</span>
                      </li>
                      <li>
                        <MdMail className='mulas-icon' />
                        <span>info@mulas.fi</span>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className='col-md-3'>
                  <div>
                    <span>
                      <strong>{language(lang).CONTACT_US}</strong>
                    </span>
                  </div>
                  <div>
                    <a href='https://www.facebook.com/Mulas-111042607278282/'>
                      <AiFillFacebook size={25} className='fb-icon' />
                    </a>
                  </div>
                </div>
                <div className='col-md-5' style={{ paddingBottom: '5px' }}>
                  <div>
                    <span>
                      <strong>{language(lang).ABOUT_US}</strong>
                    </span>
                  </div>
                  <div>
                    <span>{language(lang).ABOUT_US_TEXT}</span>
                  </div>
                </div>
                <div className='col-md-12 copyright text-center'>
                  <span>&copy; 2020&nbsp;Mulas |</span>{' '}
                  <NavLink to='/privacy-policy'>
                    {language(lang).PRIVACY_POLICY}
                  </NavLink>
                </div>
              </div>
            </div>
          </footer>
        )}
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  cartData: state.cartData,
  profile: state.profile.info,
  isLogin: state.profile.isLogin,
  lang: state.language.language,
  newOrder: state.orders.reduxOrder,
  currentOrder: state.orders.currentOrder,
  creditPoint: state.creditPoint.info,
});
const mapDispatchToProps = (dispatch) => ({
  setCart: (items, caluclated, restaurant) =>
    dispatch(setCart(items, caluclated, restaurant)),
  setProfile: (data) => dispatch(setProfile(data)),
  setCreditPoint: (data) => dispatch(setCreditPoint(data)),
  resetProfile: () => dispatch(resetProfile()),
  closeLogin: () => dispatch(closeLogin()),
  setLanguage: (value) => dispatch(setLanguage(value)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(MainLayout));
