import Swal from 'sweetalert2';
import { connect } from 'react-redux';
import Expand from 'react-expand-animated';
import { finnishNumber } from '../../utils/common';
import React, { useState, useEffect } from 'react';
import { replaceCart, removeFromCart } from '../../actions/carts';

const NewMenu = ({
  data = {},
  changeOrder,
  orderDetail = {},
  isDisabled,
  restaurantId,
  fullOrder,
  detailedOrder,
  error,
  isOpen,
}) => {
  const [order, setOrder] = useState({});
  const [localOrderDetail, setLocalOrderDetail] = useState({});
  const [openAddon, setOpenAddon] = useState(-1);
  const [productError, setProductError] = useState([]);
  const [defaultValues, setDefaultValues] = useState({});

  let isNewMeal = false;

  let mealDetail = {
    acceptId: -1,
    productId: -1,
    rejectId: -1,
  };

  const productDetailedOrder = detailedOrder[data.id] || {};

  useEffect(() => {
    data.product_property.forEach((property) => {
      const defaultProperty = property.product_property_detail.find(
        (propertyDetail) => propertyDetail.is_default === '1'
      );

      if (defaultProperty) {
        setDefaultValues({
          ...defaultValues,
          [defaultProperty.product_property_id]: [defaultProperty.id],
        });
      }
    });
    if (data.product_property[0]) {
      setOpenAddon(data.product_property[0].id);
    }
    if (orderDetail.id) {
      setLocalOrderDetail({ ...orderDetail });
      setOrder(orderDetail.order);
    }
  }, []);

  useEffect(() => {
    let tempProductError = error[data.id] || [];
    setProductError(tempProductError);
    if (tempProductError.length > 0) {
      setOpenAddon(tempProductError[0]);
    }
  }, [error]);

  const showClosedMessage = () => {
    Swal.fire({
      title: 'Restaurant',
      text: 'Restaurant is closed at the moment.',
      icon: 'warning',
      confirmButtonColor: '#ef692a',
      confirmButtonText: 'OK',
    });
  };

  const addCount = () => {
    // if (!isOpen) {
    //   showClosedMessage();
    //   return;
    // }
    let temp = { ...localOrderDetail };
    if (!localOrderDetail.id) {
      temp.id = data.id;
      temp.name = data.name;
      temp.notes = '';
      temp.qty = 1;
    } else {
      temp.qty += 1;
    }

    const parsedOldOrder = JSON.parse(localStorage.getItem('cart'));
    const fullNewOrder = JSON.parse(localStorage.getItem('FULL_NEW_ORDER'));
    const oldRestaurantId = fullOrder?.restaurant_id;

    if (
      (oldRestaurantId && oldRestaurantId != parseInt(restaurantId)) ||
      (parsedOldOrder && parsedOldOrder.items) ||
      (fullNewOrder &&
        fullNewOrder.restaurant &&
        fullNewOrder.restaurant.id !== parseInt(restaurantId))
    ) {
      Swal.fire({
        title: 'Replace cart?',
        text: 'You can only order items from one restaurant at a time.',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#ef692a',
        cancelButtonColor: '#6c757d',
        confirmButtonText: 'Yes, replace it!',
      }).then((result) => {
        if (result.value) {
          localStorage.removeItem('new_order');
          localStorage.removeItem('full_order');
          temp = {};
          temp.id = data.id;
          temp.name = data.name;
          temp.notes = '';
          temp.qty = 1;
          confirmChange(temp, order, true);
          setLocalOrderDetail(temp);
        }
      });
    } else {
      confirmChange(temp, order, false);
      setLocalOrderDetail(temp);
    }
  };

  const decreaseCount = () => {
    // if (!isOpen) {
    //   showClosedMessage();
    //   return;
    // }
    const qty = localOrderDetail.qty - 1;
    let temp = { ...localOrderDetail };
    if (qty > 0) {
      temp.qty = qty;
    } else {
      temp.qty = 0;
    }
    confirmChange(temp, order, false);
    setLocalOrderDetail(temp);
  };

  const changeAddon = (addonId, sizeId, limit, nextData) => {
    // if (!isOpen) {
    //   showClosedMessage();
    //   return;
    // }
    let tempOrder = { ...localOrderDetail };
    if (!localOrderDetail.id) {
      tempOrder.id = data.id;
      tempOrder.name = data.name;
      tempOrder.notes = '';
      tempOrder.qty = 1;
    }

    const parsedOldOrder = JSON.parse(localStorage.getItem('cart'));
    const fullNewOrder = JSON.parse(localStorage.getItem('FULL_NEW_ORDER'));
    const oldRestaurantId = fullOrder?.restaurant_id;

    if (
      (oldRestaurantId && oldRestaurantId != parseInt(restaurantId)) ||
      (parsedOldOrder && parsedOldOrder.items) ||
      (fullNewOrder &&
        fullNewOrder.restaurant &&
        fullNewOrder.restaurant.id !== parseInt(restaurantId))
    ) {
      Swal.fire({
        title: 'Replace cart?',
        text: 'You can only order items from one restaurant at a time.',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#ef692a',
        cancelButtonColor: '#6c757d',
        confirmButtonText: 'Yes, replace it!',
      }).then((result) => {
        if (result.value) {
          localStorage.removeItem('new_order');
          localStorage.removeItem('full_order');

          if (order[sizeId]) {
            let temp = [...order[sizeId]];
            temp.includes(addonId)
              ? temp.splice(temp.indexOf(addonId), 1)
              : temp.push(addonId);

            if (limit >= temp.length) {
              setOrder({ ...order, [sizeId]: temp });
              if (temp.length === limit) {
                if (nextData) {
                  setOpenAddon(nextData.id);
                } else {
                  setOpenAddon(-1);
                }
              }
              setLocalOrderDetail(tempOrder);
              confirmChange(tempOrder, { ...order, [sizeId]: temp }, true);
            } else {
              if (limit === 1) {
                temp = [addonId];
                setOrder({ ...order, [sizeId]: temp });
                if (temp.length === limit) {
                  setOpenAddon(nextData.id);
                } else {
                  setOpenAddon(-1);
                }
                setLocalOrderDetail(tempOrder);
                confirmChange(tempOrder, { ...order, [sizeId]: temp }, true);
              } else {
                Swal.fire({
                  text: `Maximum ${limit} allowed`,
                  icon: 'error',
                  showCancelButton: false,
                  confirmButtonColor: '#ef692a',
                  confirmButtonText: 'Ok',
                });
              }
            }
          } else {
            let temp = { ...order, [sizeId]: [addonId] };
            setOrder(temp);
            if (temp[sizeId].length === limit) {
              if (nextData) {
                setOpenAddon(nextData.id);
              } else {
                setOpenAddon(-1);
              }
            }
            setLocalOrderDetail(tempOrder);
            confirmChange(tempOrder, temp, false);
          }
        }
      });
    } else {
      if (order[sizeId]) {
        let temp = [...order[sizeId]];
        if (addonId === mealDetail.acceptId) {
          if (!temp.includes(addonId)) {
            isNewMeal = true;
          } else {
            isNewMeal = false;
          }
        } else if (addonId === mealDetail.rejectId) {
          if (!temp.includes(addonId)) {
            isNewMeal = false;
          }
        }
        temp.includes(addonId)
          ? temp.splice(temp.indexOf(addonId), 1)
          : temp.push(addonId);

        if (limit >= temp.length) {
          setOrder({ ...order, [sizeId]: temp });
          if (temp.length === limit) {
            if (nextData) {
              setOpenAddon(nextData.id);
            } else {
              setOpenAddon(-1);
            }
          }
          setLocalOrderDetail(tempOrder);
          confirmChange(tempOrder, { ...order, [sizeId]: temp });
        } else {
          if (limit === 1) {
            temp = [addonId];
            setOrder({ ...order, [sizeId]: temp });
            if (temp.length === limit) {
              if (nextData) {
                setOpenAddon(nextData.id);
              } else {
                setOpenAddon(-1);
              }
            }
            setLocalOrderDetail(tempOrder);
            confirmChange(tempOrder, { ...order, [sizeId]: temp });
          } else {
            Swal.fire({
              text: `Maximum ${limit} allowed`,
              icon: 'error',
              showCancelButton: false,
              confirmButtonColor: '#ef692a',
              confirmButtonText: 'Ok',
            });
          }
        }
      } else {
        if (addonId === mealDetail.detailId) {
          isNewMeal = true;
        }
        let temp = { ...order, [sizeId]: [addonId] };
        setOrder(temp);
        if (temp[sizeId].length === limit) {
          if (nextData) {
            setOpenAddon(nextData.id);
          } else {
            setOpenAddon(-1);
          }
        }
        setLocalOrderDetail(tempOrder);
        confirmChange(tempOrder, temp);
      }
    }
  };

  const confirmChange = (orderData, addonData, reset = false) => {
    let productProperty = [];
    Object.keys(defaultValues).forEach((single) => {
      if (!addonData[single]) {
        // Check array length
        addonData[single] = defaultValues[single];
        setOrder(addonData);
      }
    });
    Object.keys(addonData).forEach((single) => {
      if (addonData[single].length) {
        productProperty.push({
          product_property_id: parseInt(single),
          product_property_detail_ids: addonData[single],
        });
      }
    });
    changeOrder({ ...orderData, product_property: productProperty }, false);
  };

  const addonList = (addons = [], sizeId, limit, nextData) => {
    return addons.map((addon, o) => {
      const isChecked = order[sizeId]
        ? order[sizeId].includes(addon.id)
          ? true
          : false
        : false;
      const showLine = addons.length > o + 1;

      return (
        <div
          style={{ borderBottom: `${showLine ? '1px' : '0'} dotted #212529` }}
          key={addon.name + addon.id}
        >
          <div className='row'>
            <div
              className='col-10 product-list mobile-list'
              onClick={() => {
                changeAddon(addon.id, sizeId, limit, nextData);
              }}
            >
              <div className='form-check' key={addon.id}>
                <input
                  className='form-check-input'
                  type='checkbox'
                  id={`addon-check-${addon.name}-${addon.id}`}
                  style={{ cursor: 'pointer' }}
                  checked={isChecked}
                />
                <label
                  className='form-check-label'
                  style={{
                    margin: 'unset',
                    display: 'unset',
                    lineHeight: 'unset',
                    cursor: 'pointer',
                  }}
                >
                  {addon.name}
                </label>
              </div>
            </div>
            <div className='col-2 text-right align-self-center'>
              <small className='text-muted'>
                {addon.price ? `${finnishNumber(addon.price)}€` : ''}
              </small>
            </div>
          </div>
        </div>
      );
    });
  };

  const productData = [...data.product_property];

  const submenuList = [];

  productData.forEach((single, o) => {
    if (single.name === 'Ateria') {
      mealDetail.productId = single.id;
      single.product_property_detail.forEach((mealProduct) => {
        if (mealProduct.is_meal === '1') {
          if (mealDetail.acceptId === -1) {
            const isChecked = order[single.id]
              ? order[single.id].includes(mealProduct.id)
                ? true
                : false
              : false;
            isNewMeal = isChecked;
          }
          mealDetail.acceptId = mealProduct.id;
        } else {
          mealDetail.rejectId = mealProduct.id;
        }
      });
    }
    const isActive = openAddon === single.id;
    let showHr = data.product_property.length > o + 1;
    if (data.name === 'Ateria') {
      showHr = false;
    }
    const propertyDetail = productDetailedOrder[single.id] || {};
    let message;
    if (propertyDetail.min > propertyDetail.selected) {
      if (propertyDetail.selected) {
        message = `Please select additional ${
          propertyDetail.selected - propertyDetail.min
        } items.`;
      } else {
        message = `Please select at least ${propertyDetail.min} items.`;
      }
    } else {
      if (propertyDetail.min !== propertyDetail.max) {
        if (propertyDetail.max !== propertyDetail.selected) {
          message = `You can select additional ${
            propertyDetail.max - propertyDetail.selected
          } items.`;
        }
      }
    }

    if (single.type !== 'meal' || isNewMeal) {
      submenuList.push(
        <React.Fragment key={single.id}>
          <div
            className='row'
            onClick={() => {
              isActive ? setOpenAddon(-1) : setOpenAddon(single.id);
            }}
            style={{
              cursor: 'pointer',
              marginBottom: `${showHr ? '0' : '1rem'}`,
            }}
            id={`product-${single.id}`}
          >
            <div className='col-sm-12'>
              <span
                style={{
                  color: isActive ? '#ef692a' : 'unset',
                  fontWeight: isActive ? 'bold' : 'unset',
                }}
              >
                {single.name}
              </span>
            </div>
            <div className='col-sm-12'>
              <small
                className={`text-muted ${
                  productError.includes(single.id) ? 'text-error' : ''
                }`}
              >
                {message}
              </small>
            </div>
          </div>
          <Expand open={isActive}>
            {addonList(
              single.product_property_detail,
              single.id,
              single.max,
              data.product_property[o + 1]
            )}
          </Expand>
          {showHr ? <hr /> : null}
        </React.Fragment>
      );
    }
  });

  return (
    <React.Fragment>
      <hr />
      <div style={{ padding: '0 30px' }}>
        <div className='d-flex justify-content-between'>
          <div style={{ flex: 1, display: 'flex', alignItems: 'center' }}>
            <span>Count: </span>
            <span style={{ fontWeight: 'bold' }}>
              {localOrderDetail.qty || '0'}
            </span>
          </div>
          {localOrderDetail.qty ? (
            <div className='btn-group'>
              <button
                className={`btn checkout-button auto-button ${
                  isDisabled ? 'disabled-button' : ''
                }`}
                onClick={() => {
                  if (!isDisabled) decreaseCount();
                }}
                style={{ width: '45px', fontSize: '20px' }}
              >
                -
              </button>
              <button
                className={`btn checkout-button auto-button ${
                  isDisabled ? 'disabled-button' : ''
                }`}
                onClick={() => {
                  if (!isDisabled) addCount();
                }}
                style={{ width: '45px' }}
              >
                +
              </button>
            </div>
          ) : (
            <div>
              <button
                className={`btn checkout-button auto-button ${
                  isDisabled ? 'disabled-button' : ''
                }`}
                onClick={() => {
                  if (!isDisabled) addCount();
                }}
              >
                Add
              </button>
            </div>
          )}
        </div>
        <hr />
        {submenuList}
      </div>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => ({
  lang: state.language.language,
});
const mapDispatchToProps = (dispatch) => ({
  replaceCart: (index, food) => dispatch(replaceCart(index, food)),
  removeFromCart: (index) => dispatch(removeFromCart(index)),
});

export default connect(mapStateToProps, mapDispatchToProps)(NewMenu);
