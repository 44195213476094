import { authHttp, restaurantHttp } from '../utils/http';
import { apiConstants } from '../constants';

export function requestLogin(data) {
  return authHttp.post(apiConstants.LOGIN, data);
}

export function getInfo(id) {
  return authHttp.get(`customers/${id}`);
}

export function updateInfo(id, data) {
  return authHttp.patch(`customers/${id}`, data);
}

export function createUser(data) {
  return authHttp.post('customers', data);
}

export function createDriver(data) {
  return authHttp.post('drivers', data);
}

export function createRestaurant(data) {
  return restaurantHttp.post('restaurants', data);
}

export function socialLogin(data) {
  return authHttp.post('social-login', data);
}

export function changePassword(data) {
  return authHttp.post('forgot-password', data);
}

export function confirmUserRegistration(id, token) {
  return authHttp.get(`customers/${id}/verify?token=${token}`);
}
