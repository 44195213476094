import React, { useState } from "react";
import styles from "./RatingStar.module.css";

const Star = (props) => {
  const { starId, rating, onMouseEnter, onMouseLeave, onClick } = props;
  let styleClass = styles["star-rating-blank"];
  if (rating > starId) {
    styleClass = styles["star-rating-filled"];
  }

  return (
    <div
      className="star"
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      onClick={onClick}
    >
      <svg
        width="48"
        height="48"
        viewBox="0 0 48 48"
        className={styleClass}
        data-rating="1"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M45 19.5H28.875L24 4.5L19.125 19.5H3L16.125 28.5L11.0625 43.5L24 34.125L36.9375 43.5L31.875 28.5L45 19.5Z"
          strokeWidth="2"
          strokeLinejoin="round"
        />
      </svg>
    </div>
  );
};

const RatingStar = (props) => {
  // using hooks
  const [rating, setRating] = useState(0);

  const [hoverState, setHoverState] = useState(0);
  // using 5 stars rating

  const stars = [1, 2, 3, 4, 5];

  const updateRatingHandler = (count) => {
    setRating(count + 1);

    props.onUpdate(count + 1);
  };

  return (
    <div className={styles["flex-container"]}>
      {stars.map((star, i) => (
        <Star
          key={i}
          starId={i}
          rating={hoverState || rating}
          onMouseEnter={() => setHoverState(i + 1)}
          onMouseLeave={() => setHoverState(0)}
          onClick={updateRatingHandler.bind(null, i)}
        />
      ))}
    </div>
  );
};

export default RatingStar;
