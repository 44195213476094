export const reducerConstants = {
  ADD_TO_CART: 'ADD_TO_CART',
  REMOVE_FROM_CART: 'REMOVE_FROM_CART',
  REPLACE_CART: 'REPLACE_CART',
  SET_CART: 'SET_CART',
  SET_PROFILE: 'SET_PROFILE',
  RESET_PROFILE: 'RESET_PROFILE',
  OPEN_LOGIN: 'OPEN_LOGIN',
  CLOSE_LOGIN: 'CLOSE_LOGIN',
  INITIATE_ORDER: 'INITIATE_ORDER',
  CONFIRM_CHECKOUT: 'CONFIRM_CHECKOUT',
  RESET_ORDER: 'RESET_ORDER',
  RESET_CART: 'RESET_CART',
  SET_LANGUAGE: 'SET_LANGUAGE',
  DELIVERY_TYPE: 'DELIVERY_TYPE',
  SAVE_ORDER: 'SAVE_ORDER',
  CURRENT_ORDER: 'CURRENT_ORDER',
  SET_CREDIT_POINT: 'SET_CREDIT_POINT'
};
