import Swal from 'sweetalert2';
import { connect } from 'react-redux';
import { Modal } from 'react-bootstrap';
import React, { Component } from 'react';
import { MdAdd, MdRemove } from 'react-icons/md';

import { language } from '../../constants';
import { addToCart } from '../../actions/carts';
import { finnishNumber } from '../../utils/common';
import ComponentLoader from '../shared/ComponentLoader';

class AddToCart extends Component {
  state = {
    quantity: 1,
    addonObject: {},
    size: {
      id: '',
      price: '',
      name: '',
    },
    fullOrder: {},
  };
  componentDidMount() {
    const fullOrder = localStorage.getItem('full_order');
    if (fullOrder) {
      this.setState({
        fullOrder: JSON.parse(fullOrder),
      });
    }
  }
  changeQuantity = (type) => {
    const { quantity } = this.state;
    this.setState({
      quantity: type === 'add' ? quantity + 1 : quantity - 1 || 1,
    });
  };
  onCancel = () => {
    this.setState({
      quantity: 1,
      addonObject: {},
      size: {
        id: '',
        price: '',
        name: '',
      },
    });
    this.props.closeModal();
  };
  onSubmit = () => {
    let fullOrder = { ...this.state.fullOrder };
    if (Object.keys(fullOrder).length > 0) {
      Swal.fire({
        title: 'Replace cart?',
        text: 'You can only order items from one restaurant at a time.',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#ef692a',
        cancelButtonColor: '#6c757d',
        confirmButtonText: 'Yes, replace it!',
      }).then((result) => {
        if (result.value) {
          localStorage.removeItem('new_order');
          localStorage.removeItem('full_order');
          this.submitAction();
          this.setState({ fullOrder: {} });
        }
      });
    } else {
      this.submitAction();
    }
  };
  submitAction = () => {
    const { restaurantDetail, cartData, data, addToCart } = this.props;
    const { size } = this.state;
    const restaurant = {
      id: restaurantDetail.id,
      tax: restaurantDetail.tax && restaurantDetail.tax.rate,
      currency:
        restaurantDetail.currency && restaurantDetail.currency.currency_symbol,
      name: restaurantDetail.name,
      delivery_order: restaurantDetail.delivery_order,
      discount_rate: restaurantDetail.discount_rate || 0,
      discount_type: restaurantDetail.discount_type,
    };
    let addonKeys = Object.keys(this.state.addonObject);
    let addons = [];
    if (addonKeys.length > 0) {
      addonKeys.forEach((single) => {
        addons.push(this.state.addonObject[single]);
      });
    }
    let food = {
      id: data.id,
      name: data.name,
      price: data.price,
      qty: this.state.quantity,
      add_on: addons,
    };
    if (size.id) {
      food.price = size.price;
      food.product_size = size.name;
      food.product_size_id = size.id;
    }
    const parsedNewOrder = JSON.parse(localStorage.getItem('PARSED_NEW_ORDER'));
    const newOrderFlag = !!(
      parsedNewOrder && Object.keys(parsedNewOrder).length > 0
    );
    if (
      (restaurantDetail.id !== cartData.restaurantInfo.id &&
        cartData.items.length > 0) ||
      newOrderFlag
    ) {
      Swal.fire({
        title: 'Replace cart?',
        text: 'You can only order items from one restaurant at a time.',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#ef692a',
        cancelButtonColor: '#6c757d',
        confirmButtonText: 'Yes, replace it!',
      }).then((result) => {
        if (result.value) {
          localStorage.removeItem('new_order');
          localStorage.removeItem('full_order');
          localStorage.removeItem('PARSED_RESTAURANT_ORDER');
          localStorage.removeItem('FULL_RESTAURANT_ORDER');
          localStorage.removeItem('FULL_NEW_ORDER');
          localStorage.removeItem('PARSED_NEW_ORDER');
          addToCart(food, restaurant);
          this.onCancel();
        }
      });
    } else {
      addToCart(food, restaurant);
      this.onCancel();
    }
  };
  changeAddon = (e, addon) => {
    let { addonObject } = this.state;
    const isChecked = e.target.checked;
    if (isChecked) {
      addon['qty'] = 1;
      addonObject[addon.id] = addon;
    } else {
      delete addonObject[addon.id];
    }
    this.setState({
      addonObject,
    });
  };

  changeAddonQuantity = (addon, type) => {
    let temp = { ...addon };
    let { addonObject } = this.state;
    if (!temp.qty) {
      temp.qty = 0;
    }
    let qty = type === 'add' ? temp.qty + 1 : temp.qty - 1;
    temp.qty = qty >= 0 ? qty : 0;
    if (!temp.qty) {
      delete addonObject[addon.id];
    } else {
      addonObject[addon.id] = temp;
    }
    this.setState({
      addonObject,
    });
  };

  changeSize = (single) => {
    this.setState({
      size: {
        id: single.id,
        price: single.price,
        name: single.name,
      },
    });
  };

  render() {
    const {
      show,
      data,
      addons,
      isAddonLoading,
      currencySymbol,
      lang,
    } = this.props;
    const { quantity, addonObject, size } = this.state;
    const addOnKeys = Object.keys(addonObject);

    let addonsAmount = 0;
    const productSizes = data.product_size || [];

    addOnKeys.forEach((single) => {
      addonsAmount += addonObject[single].price * addonObject[single].qty;
    });
    const addonsList = addons.map((addon) => (
      <div className='row' key={addon.id}>
        <div className='col-12'>
          <span className='cart-item-title text-muted'>{addon.name}</span>
          <MdRemove
            className='cart-action'
            onClick={() => {
              this.changeAddonQuantity(addonObject[addon.id] || addon, 'diff');
            }}
          />
          <span className='cart-qty cart-qty-old'>{`${
            addonObject[addon.id] ? addonObject[addon.id].qty || 0 : 0
          }x`}</span>
          <MdAdd
            className='cart-action'
            onClick={() => {
              this.changeAddonQuantity(addonObject[addon.id] || addon, 'add');
            }}
          />
        </div>
      </div>
    ));
    const sizeList = productSizes.map((single) => {
      return (
        <div className='form-check' key={single.id}>
          <input
            className='form-check-input'
            type='radio'
            name='productSize'
            value={single.id}
            checked={size.id === single.id}
            id={`${single.name}-${single.id}`}
            onChange={() => {
              this.changeSize(single);
            }}
          />
          <label
            className='form-check-label'
            htmlFor={`${single.name}-${single.id}`}
          >
            {single.name}
          </label>
        </div>
      );
    });
    return (
      <Modal show={show} onHide={this.onCancel}>
        <Modal.Header closeButton>
          <Modal.Title>{data.name || '-'}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {isAddonLoading ? (
            <ComponentLoader />
          ) : (
            <React.Fragment>
              <div className='row'>
                <div className='col-12'>
                  <span className='text-muted'>{data.descriptions || ''}</span>
                </div>
              </div>
              <hr />
              <div className='row'>
                <div className='col-sm-6'>
                  <div className='row'>
                    <div className='col-12'>
                      <span className='cart-item-title text-muted'>
                        {language(lang).UNIT_PRICE}
                      </span>
                      <span>
                        {finnishNumber(
                          currencySymbol + (size.price || data.price)
                        )}
                      </span>
                    </div>
                  </div>
                  <div className='row'>
                    <div className='col-12'>
                      <span className='cart-item-title text-muted'>
                        {language(lang).QUANTITY}
                      </span>
                      <MdRemove
                        className='cart-action'
                        onClick={() => {
                          this.changeQuantity('diff');
                        }}
                      />
                      <span className='cart-qty cart-qty-old'>{`${quantity}x`}</span>
                      <MdAdd
                        className='cart-action'
                        onClick={() => {
                          this.changeQuantity('add');
                        }}
                      />
                    </div>
                  </div>
                  <div className='row'>
                    <div className='col-12'>
                      <span className='cart-item-title text-muted'>
                        {language(lang).TOTAL_AMOUNT}
                      </span>
                      <span>
                        {finnishNumber(
                          currencySymbol +
                            (
                              (size.price || data.price) * quantity +
                              addonsAmount
                            ).toFixed(2)
                        )}
                      </span>
                    </div>
                  </div>
                </div>
                {(addons.length > 0 || productSizes.length > 0) && (
                  <div
                    className='col-sm-6'
                    style={{ borderLeft: '1px solid #dee2e6' }}
                  >
                    {productSizes.length > 0 && (
                      <React.Fragment>
                        <div className='row'>
                          <div className='col-12'>
                            <div className='text-center'>
                              <span>
                                <b>{language(lang).PRODUCT_SIZE}</b>
                              </span>
                            </div>
                          </div>
                        </div>
                        <div className='row'>
                          <div className='col-12'>{sizeList}</div>
                        </div>
                      </React.Fragment>
                    )}
                    {addons.length > 0 && (
                      <React.Fragment>
                        <div className='row'>
                          <div className='col-12'>
                            <div className='text-center'>
                              <span className='cart-item-title'>
                                <b>Addons</b>
                              </span>
                            </div>
                          </div>
                        </div>
                        <div className='row'>
                          <div className='col-12'>{addonsList}</div>
                        </div>
                      </React.Fragment>
                    )}
                  </div>
                )}
              </div>
            </React.Fragment>
          )}
        </Modal.Body>
        <Modal.Footer className='auth-footer'>
          {!isAddonLoading && (
            <button className='login-button' onClick={this.onSubmit}>
              {language(lang).ADD_TO_CART}
            </button>
          )}
        </Modal.Footer>
      </Modal>
    );
  }
}

const mapStateToProps = (state) => ({
  cartData: state.cartData,
  lang: state.language.language,
});
const mapDispatchToProps = (dispatch) => ({
  addToCart: (food, restaurant) => dispatch(addToCart(food, restaurant)),
});

export default connect(mapStateToProps, mapDispatchToProps)(AddToCart);
