import React, { Component } from 'react';

import NewRestaurantDetail from './restaurantDetails/new';
import OldRestaurantDetail from './restaurantDetails/old';
import { withRestaurantDetails } from '../hoc/RestaurantDetails';

class RestaurantDetails extends Component {
  state = {
    restaurantDetail: {},
    isNew: false,
    isLoading: true,
  };
  componentDidMount() {
    this.props.getRestaurantDetails(this.props.match.params.id);
  }
  componentDidUpdate(prevProps) {
    if (this.props.restaurantDetail !== prevProps.restaurantDetail) {
      this.setState({
        restaurantDetail: this.props.restaurantDetail,
        isLoading: false,
      });
      if (this.props.restaurantDetail.menu_type !== 'default') {
        this.setState({ isNew: true });
      }
    }
  }

  render() {
    const { isNew, restaurantDetail, isLoading } = this.state;

    if (isLoading) {
      return null;
    }

    if (isNew) {
      return (
        <NewRestaurantDetail
          restaurantDetail={restaurantDetail}
          {...this.props}
        />
      );
    }

    return (
      <OldRestaurantDetail
        restaurantDetail={restaurantDetail}
        {...this.props}
      />
    );
  }
}

export default withRestaurantDetails(RestaurantDetails);
