import jwt_decode from 'jwt-decode';
import { storage } from './storage';

const getUserId = (bearerToken) => {
  let token = '';
  if (bearerToken) {
    token = bearerToken;
  } else {
    token = storage.getToken();
  }
  if (token) {
    const jwtToken = jwt_decode(token);
    return jwtToken.params.id;
  }
  return false;
};

export const profileUtils = {
  getUserId,
};
