const finnish = {
  DELICIOUS: 'herkullista',
  FOOD: 'ruokaa',
  ORDER_DELIVERY_AND_TAKE_OUT: 'Nouda Mukaan tai Tilaa Kuljetus',
  RESTAURANT_NAME: 'Ravintolan nimi',
  NEARBY_RESTAURANTS: 'Lähellä olevat ravintolat',
  YOUR_ADDRESS: 'Osoitteesi',
  MINIMUM_ORDER: 'Vähimmäistilaus',
  POPULAR_RESTAURANTS: 'Suositut ravintolat',
  LIST_YOUR_RESTAURANT_AT_MULAS: 'Hae ravintolakumppaniksi',
  BE_PART_OF_MULAS_AS_A_DELIVERY_BOY: 'Hae töihin',
  SEND_REQUEST: 'Lähetä pyyntö',
  GET_IN_TOUCH: 'Yhteystiedot',
  CONTACT_US: 'Ota yhteyttä',
  BE_PART_OF_MULAS_AS_A_DELIVERY_PARTNER: 'Hae lähettikumppaniksi',
  LOGIN: 'Kirjaudu',
  CATEGORIES: 'Kategoriat',
  SEARCH_RESTAURANT_BY_NAME: 'Etsi ravintola nimellä',
  SEARCH: 'Etsi',
  ENTER_YOUR_USERNAME: 'Käyttäjätunnuksesi',
  ENTER_YOUR_PASSWORD: 'Salasanasi',
  FORGOT_YOUR_PASSWORD: 'Unohditko salasanasi',
  DID_NOT_HAVE_ACCOUNT: 'Rekisteröidy',
  EDIT_PROFILE: 'Muokkaa profiilia',
  ORDER_HISTORY: 'Tilaushistoria',
  SIGN_OUT: 'Kirjaudu ulos',
  UNIT_PRICE: 'Hinta',
  QUANTITY: 'Määrä',
  ADD_TO_CART: 'Lisää ostoskoriin',
  SUB_TOTAL: 'Välihinta',
  TOTAL: 'Kokonaishinta',
  DELIVERY_TYPE: 'Toimitustapa',
  PICKUP: 'Nouto',
  RESTAURANT_DELIVERY: 'Kuljetus',
  CONFIRM_CHECKOUT: 'Vahvista tilaus',
  STREET: 'Osoitteesi',
  CONFIRM_ADDRESS: 'vahvista osoitteesi',
  ITEM: 'Ruoka',
  QTY: 'Määrä',
  PRICE: 'Hinta',
  DELIVERY_CHARGE: 'Kuljetushinta',
  ORDER_NOTES_DETAILS: 'Lisätietoja',
  ENTER_NOTES: 'Korttinumero',
  CUSTOMER_DETAILS: 'Asiakkaan tiedot',
  NAME: 'Nimi',
  EMAIL: 'sähköpostiosoite',
  PHONE: 'Puhelinnumero',
  DISTANCE: 'Etäisyys',
  INVALID_PHONE_NUMBER: 'virheellinen puhelinnumero',
  CHECKOUT_ORDER: 'Kassalle',
  CARD_NUMBER: 'Card Number',
  REGISTER_IN: 'rekisteröidy sisään',
  ENTER_YOUR_FIRST_NAME: 'Anna etunimesi',
  ENTER_YOUR_LAST_NAME: 'kirjoita sukunimesi',
  CONTINUE_WITH_FACEBOOK: 'jatka facebookissa',
  CONTINUE_WITH_GOOGLE: 'jatka google',
  ENTER_YOUR_EMAIL_ADDRESS: 'Syötä sähköpostiosoitteesi',
  ENTER_YOUR_ADDRESS: 'kirjoita osoitteesi',
  ENTER_YOUR_PHONE_NUMBER: 'kirjoita puhelinnumerosi',
  ENTER_YOUR_ZIP_CODE: 'kirjoita postinumero',
  ALREADY_HAVE_AN_ACCOUNT: 'Onko sinulla jo tili?',
  SEND_RESET_LINK: 'lähetä nollauslinkki',
  PRIVACY_POLICY: 'Tietosuojakäytäntö',
  MENU: 'Valikko',
  INFO: 'Tiedot',
  OPENING_HOURS: 'aukioloajat',
  RESTAURANTS: 'Ravintolat',
  ALL: 'Kaikki',
  CART: 'kärry',
  YOUR_CART_IS_EMPTY: 'Ostoskorisi on tyhjä',
  FIRST_NAME: 'etunimi',
  LAST_NAME: 'sukunimi',
  ADDRESS: 'osoite',
  CITY: 'Kaupunki',
  ZIP_CODE: 'postinumero',
  PHONE_NUMBER: 'Puhelinnumero',
  UPDATE_PROFILE: 'Päivitä profiili',
  FILTERS: 'Tilauksen tilan',
  TOTAL_AMOUNT: 'kokonaismäärä',
  PRODUCT_SIZE: 'Tee valintasi',
  PLEASE_SELECT_DELIVERY_TYPE: 'valitse toimitustapa',
  STREET_REQUIRED: 'katu vaaditaan',
  CITY_REQUIRED: 'kaupunki vaaditaan',
  BILLING_DETAILS: 'laskutustiedot',
  PAY: 'maksaa',
  REGISTER_RESTAURANT: 'rekisteröi ravintola',
  ENTER_RESTAURANT_NAME: 'kirjoita ravintolan nimi',
  ENTER_CONTACT_NAME: 'kirjoita yhteyshenkilön nimi',
  SELECT_CURRENCY: 'Valitse valuutta',
  SELECT_RESTAURANT_LOGO: 'valitse ravintolan logo',
  CHOOSE_IMAGE: 'valitse kuva',
  REGISTER_AS_DRIVER: 'rekisteröidä kuljettajaksi',
  CHANGE_PASSWORD: 'Vaihda salasana',
  PASSWORD: 'Salasana',
  CONFIRM_PASSWORD: 'Vahvista salasana',
  PENDING: 'Odottaa',
  RECEIVE: 'Vastaanotettu',
  DELIVERY: 'Valmis',
  ON_THE_WAY: 'Matkalla',
  DENY: 'Peruutettu',
  CONFIRM_REGISTRATION: 'vahvista rekisteröinti',
  CLICK_CONFIRM_BUTTON:
    'Napsauta vahvista-painiketta rekisteröinnin viimeistelemiseksi',
  CONFIRM: 'Vahvistaa',
  REGISTER_SUCCESS:
    'Rekisteröinti onnistui. Tarkista sähköpostisi aktivoidaksesi tilin.',
  DISCOUNT_AMOUNT: 'Alennuksen määrä',
  DISCOUNT: 'Alennus',
  ABOUT_US: 'Meistä',
  ABOUT_US_TEXT: `Mulas on ruokien online-tilaus portaali, jossa voit tilata vaivattomasti ruokaa ravintoloista, ilman turhia puhelinkeskusteluja. Tällä hetkellä moni ruokien tilauspalvelut saattavat periä jopa 30 % tilauksen summasta, jolloin ravintolan siivusta menee merkittävä summa pois. Mulas perii pelkästään välttämättömät minimaaliset kustannukset, jolloin ravintola saa melkein koko summan käteensä. minimikustannukset poislukien. Näin voit auttaa lähiravintoloitasi tilaamalla mulas-kautta. 
    Tällä hetkellä mulas toimii monissa kaupungeissa vain noudon tueksi, mutta tarkoituksena on vähitellen aloittaa kuljetuspalvelu joissakin kaupungeissa.`,
  PAY_BY_CARD: 'Maksa kortilla',
  INBUILT_PAY: 'Google / Apple Pay',
  DELIVERY_DETAILS: 'Toimitustiedot',
  FLOOR_ROOM: 'esim. kerros, huone nro.',
  FLAT_NUMBER: 'Tasainen numero',
  FLOOR_NUMBER: 'Kerrosnumero',
  DOOR_CODE: 'Ovikoodi',
  MY_BASKET: 'Oma ostoskori',
  SHOW_OTHER_OPTIONS: 'Näytä muut vaihtoehdot',
  PAYTRAIL_BANK_PAYMENT: 'Pankkimaksu',
  FREE_DELIVERY: 'Ilmainen toimitus',
  TRACK_ORDER: 'Seuraa tilausta',
  TRACK_THIS_ORDER: 'Seuraa tilausta',
  ORDER_PLACED: 'Tilaus on tehty',
  ORDER_RECEIVED_BY_RESTAURANT: 'Ravintola on vastaanottanut tilauksesi',
  ORDER_READY_BY_RESTAURANT: 'Ravintola on valmistanut tilauksesi',
  ORDER_PICKED_BY_DRIVER: 'Kuljettaja toimittaa tilaustasi',
  ORDER_DELIVERED: 'Tilaus toimitettu',
  KITCHEN_ESTIMATE_TIME: 'Arvioitu aika valmis tilauksen',
  DRIVER_ESTIMATE_TIME: 'Arvioitu toimitusaika',
  SPECIAL_OFFER: 'Erikoistarjous',
  OR: 'Tai',
  ESTIMATED_TIME: 'Arvioitu Aika',
  CHANGE_ORDER: 'Muokkaa tilausta',
  LOGIN_TO_ORDER: 'Kirjaudu sisään tilataksesi',
  ADDITIONAL_INFO_FOR_RESTAURANT: 'Lisätietoja ravintolalle',
  TOTAL_AMOUNT_OF_ORDER: 'Tilauksen kokonaissumma',
  ACCEPTED_BY_RESTAURANT: 'Ravintola hyväksyy'
};

const english = {
  DELICIOUS: 'Delicious',
  FOOD: 'Food',
  ORDER_DELIVERY_AND_TAKE_OUT: 'Order Delivery & Take Out',
  RESTAURANT_NAME: 'Restaurant Name',
  NEARBY_RESTAURANTS: 'Nearby Restaurants',
  YOUR_ADDRESS: 'Your Address',
  MINIMUM_ORDER: 'Minimum Order',
  POPULAR_RESTAURANTS: 'Popular Restaurants',
  LIST_YOUR_RESTAURANT_AT_MULAS: 'List Your Restaurant At Mulas',
  BE_PART_OF_MULAS_AS_A_DELIVERY_BOY: 'Be part of Mulas as a Delivery Boy',
  SEND_REQUEST: 'Send Request',
  GET_IN_TOUCH: 'Get in touch',
  CONTACT_US: 'Contact Us',
  BE_PART_OF_MULAS_AS_A_DELIVERY_PARTNER: 'Be part of Mulas as a Partner',
  LOGIN: 'Login',
  CATEGORIES: 'Categories',
  SEARCH_RESTAURANT_BY_NAME: 'Search Restaurant by name',
  SEARCH: 'Search',
  ENTER_YOUR_USERNAME: 'Enter your username',
  ENTER_YOUR_PASSWORD: 'Enter your password',
  FORGOT_YOUR_PASSWORD: 'Forgot your password',
  DID_NOT_HAVE_ACCOUNT: 'Sign up',
  EDIT_PROFILE: 'Edit Profile',
  ORDER_HISTORY: 'Order History',
  SIGN_OUT: 'Signout',
  UNIT_PRICE: 'Unit Price',
  QUANTITY: 'Quantity',
  ADD_TO_CART: 'Add to cart',
  SUB_TOTAL: 'Sub Total',
  TOTAL: 'Total',
  DELIVERY_TYPE: 'Delivery Type',
  PICKUP: 'Pickup',
  RESTAURANT_DELIVERY: 'Delivery',
  CONFIRM_CHECKOUT: 'Confirm Checkout',
  CONFIRM_ADDRESS: 'Confirm Address',
  ITEM: 'Item',
  QTY: 'Qty',
  PRICE: 'Price',
  DELIVERY_CHARGE: 'Delivery Charge',
  ORDER_NOTES_DETAILS: 'Order Notes Details',
  ENTER_NOTES: 'Enter Notes',
  CUSTOMER_DETAILS: 'Customer Details',
  NAME: 'Name',
  STREET: 'Street',
  PHONE: 'Phone',
  DISTANCE: 'Distance',
  INVALID_PHONE_NUMBER: 'Invalid Phone Number',
  CHECKOUT_ORDER: 'Checkout Order',
  CARD_NUMBER: 'Card Number',
  REGISTER_IN: 'Register In',
  ENTER_YOUR_FIRST_NAME: 'Enter your first name',
  ENTER_YOUR_LAST_NAME: 'Enter your last name',
  CONTINUE_WITH_FACEBOOK: 'Continue with facebook',
  CONTINUE_WITH_GOOGLE: 'Contiue with google',
  ENTER_YOUR_EMAIL_ADDRESS: 'Enter your email address',
  ENTER_YOUR_ADDRESS: 'Enter your address',
  ENTER_YOUR_PHONE_NUMBER: 'Enter your phone number',
  ENTER_YOUR_ZIP_CODE: 'Enter your zip code',
  ALREADY_HAVE_AN_ACCOUNT: 'Already have an account?',
  SEND_RESET_LINK: 'Send reset link',
  PRIVACY_POLICY: 'Privacy Policy',
  MENU: 'Menu',
  INFO: 'Info',
  OPENING_HOURS: 'Opening Hours',
  RESTAURANTS: 'Restaurants',
  ALL: 'All',
  CART: 'Cart',
  YOUR_CART_IS_EMPTY: 'Your cart is emty',
  FIRST_NAME: 'First Name',
  LAST_NAME: 'LastName',
  EMAIL: 'Email',
  ADDRESS: 'Address',
  CITY: 'City',
  ZIP_CODE: 'Zip code',
  PHONE_NUMBER: 'Phone number',
  UPDATE_PROFILE: 'Update Profile',
  FILTERS: 'Your order status',
  TOTAL_AMOUNT: 'Total Amount',
  PRODUCT_SIZE: 'Make your selection',
  PLEASE_SELECT_DELIVERY_TYPE: 'Please select delivery type.',
  STREET_REQUIRED: 'Street is required.',
  CITY_REQUIRED: 'City is required.',
  BILLING_DETAILS: 'Billing Details',
  PAY: 'Pay',
  REGISTER_RESTAURANT: 'Register Restaurant',
  ENTER_RESTAURANT_NAME: 'Enter restaurant name',
  ENTER_CONTACT_NAME: 'Enter contact person name',
  SELECT_CURRENCY: 'Select currency',
  SELECT_RESTAURANT_LOGO: 'Select restaurant logo',
  CHOOSE_IMAGE: 'Choose image',
  REGISTER_AS_DRIVER: 'Register as driver',
  CHANGE_PASSWORD: 'Change password',
  PASSWORD: 'Password',
  CONFIRM_PASSWORD: 'Confirm Password',
  PENDING: 'Pending',
  RECEIVE: 'Received',
  DELIVERY: 'Ready',
  ON_THE_WAY: 'On The Way',
  DENY: 'Cancelled',
  CONFIRM_REGISTRATION: 'Confirm Registration',
  CLICK_CONFIRM_BUTTON:
    'Click on confirm button below to complete the registration.',
  CONFIRM: 'Confirm',
  REGISTER_SUCCESS:
    'Registration Successful. Please check your mail to activate account.',
  DISCOUNT_AMOUNT: 'Discount Amount',
  DISCOUNT: 'Discount',
  ABOUT_US: 'About us',
  ABOUT_US_TEXT: `Mulas on ruokien online-tilaus portaali, jossa voit tilata vaivattomasti ruokaa ravintoloista, ilman turhia puhelinkeskusteluja. Tällä hetkellä moni ruokien tilauspalvelut saattavat periä jopa 30 % tilauksen summasta, jolloin ravintolan siivusta menee merkittävä summa pois. Mulas perii pelkästään välttämättömät minimaaliset kustannukset, jolloin ravintola saa melkein koko summan käteensä. minimikustannukset poislukien. Näin voit auttaa lähiravintoloitasi tilaamalla mulas-kautta. 
    Tällä hetkellä mulas toimii monissa kaupungeissa vain noudon tueksi, mutta tarkoituksena on vähitellen aloittaa kuljetuspalvelu joissakin kaupungeissa.`,
  PAY_BY_CARD: 'Pay by card',
  INBUILT_PAY: 'Google / Apple Pay',
  DELIVERY_DETAILS: 'Delivery Details',
  FLOOR_ROOM: 'eg. floor, room no.',
  FLAT_NUMBER: 'Flat Number',
  FLOOR_NUMBER: 'Floor Number',
  DOOR_CODE: 'Door Code',
  MY_BASKET: 'My Basket',
  SHOW_OTHER_OPTIONS: 'Show other options',
  PAYTRAIL_BANK_PAYMENT: 'Paytrail Bank Payment',
  FREE_DELIVERY: 'Free Delivery',
  TRACK_ORDER: 'Track Your Order',
  TRACK_THIS_ORDER: 'Track this order',
  ORDER_PLACED: 'Order Placed',
  ORDER_RECEIVED_BY_RESTAURANT: 'Order Received by Restaurant',
  ORDER_READY_BY_RESTAURANT: 'Order Ready by Restaurant',
  ORDER_PICKED_BY_DRIVER: 'Order Picked by Driver',
  ORDER_DELIVERED: 'Order Delivered',
  KITCHEN_ESTIMATE_TIME: 'Estimated time to complete your order',
  DRIVER_ESTIMATE_TIME: 'Estimated delivery time',
  SPECIAL_OFFER: 'Special offer',
  OR: 'Or',
  ESTIMATED_TIME: 'Estimated Time',
  CHANGE_ORDER: 'Change Order',
  LOGIN_TO_ORDER: 'Login to order',
  ADDITIONAL_INFO_FOR_RESTAURANT: 'Any Additional Info for Restaurant',
  TOTAL_AMOUNT_OF_ORDER: 'Total amount of order',
  ACCEPTED_BY_RESTAURANT: 'Accepted by restaurant'
};

export const language = (type) => {
  if (type === 'fin') {
    return finnish;
  }
  return english;
};
