export const finnishNumber = (number) => {
  number = number + '';
  return number.replace('.', ',');
};

export const ifStringContainsLetters = (string) => {
  const regExp = /[a-zA-Z]/g;
  if (regExp.test(string)) {
    return true;
  }
  return false;
};
