import { reducerConstants } from '../constants';

export function setProfile(data) {
  return (dispatch) => {
    dispatch({
      type: reducerConstants.SET_PROFILE,
      payload: data,
    });
  };
}

export function resetProfile() {
  return (dispatch) => {
    dispatch({
      type: reducerConstants.RESET_PROFILE,
    });
  };
}

export function openLogin() {
  return (dispatch) => {
    dispatch({
      type: reducerConstants.OPEN_LOGIN,
    });
  };
}

export function closeLogin() {
  return (dispatch) => {
    dispatch({
      type: reducerConstants.CLOSE_LOGIN,
    });
  };
}

export function setCreditPoint(data) {
  return (dispatch) => {
    dispatch({
      type: reducerConstants.SET_CREDIT_POINT,
      payload: data,
    });
  };
}
