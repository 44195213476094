import React from 'react';
import queryString from 'query-string';
import Swal from 'sweetalert2';
import { connect } from 'react-redux';

import MainLayout from './shared/MainLayout';
import { language } from '../constants';
import { changePassword } from '../services';

class ChangePassword extends React.Component {
  state = {
    data: {
      password: '',
      conf_password: '',
    },
    error: {
      password: '',
      conf_password: '',
    },
    isProcessing: false,
  };

  componentDidMount() {
    const parsed = queryString.parse(this.props.location.search);
    if (parsed.token) {
      let data = { ...this.state.data };
      data.token = parsed.token;
      this.setState({
        data,
      });
    } else {
      this.props.history.push('/');
    }
  }

  onChange = (e) => {
    const { name, value } = e.target;
    let { data, error } = this.state;
    data[name] = value;
    error[name] = '';
    this.setState({ data, error });
  };

  onSubmit = () => {
    let data = { ...this.state.data };
    data['_method'] = 'patch';
    changePassword(data)
      .then((res) => {
        Swal.fire({
          text: res.data.message,
          icon: 'success',
          showCancelButton: false,
          confirmButtonColor: '#ef692a',
          confirmButtonText: 'Ok',
        }).then(() => {
          this.setState({ isProcessing: false });
          this.props.history.push('/');
        });
      })
      .catch((err) => {
        if (err.response.status === 422) {
          if (
            err.response.data &&
            err.response.data.message &&
            err.response.data.message.token
          ) {
            Swal.fire({
              title: err.response.data.message.token[0],
              icon: 'error',
              showCancelButton: false,
              confirmButtonColor: '#ef692a',
              confirmButtonText: 'Go to Home',
            }).then((result) => {
              if (result.value) {
                this.props.history.push('/');
              }
            });
          } else {
            this.setState({
              error: err.response.data.message,
              isProcessing: false,
            });
          }
        }
      });
  };

  render() {
    const { lang } = this.props;
    const { data, error, isProcessing } = this.state;
    return (
      <MainLayout>
        <section className='page-header'>
          <div className='container'>
            <h1>{language(lang).CHANGE_PASSWORD}</h1>
          </div>
        </section>
        <section className='main-content' style={{ minHeight: '55vh' }}>
          <div className='container'>
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                flexDirection: 'column',
                padding: '20px 0',
              }}
            >
              <div style={{ maxWidth: '500px' }}>
                <div className='row'>
                  <span>{language(lang).PASSWORD}</span>
                  <input
                    className={`form-control ${
                      error.password ? 'is-invalid' : ''
                    }`}
                    value={data.password}
                    name='password'
                    onChange={this.onChange}
                    type='password'
                  />
                  {error.password && (
                    <div className='invalid-feedback'>{error.password}</div>
                  )}
                </div>
                <div className='row' style={{ marginTop: '5px' }}>
                  <span>{language(lang).CONFIRM_PASSWORD}</span>
                  <input
                    className={`form-control ${
                      error.conf_password ? 'is-invalid' : ''
                    }`}
                    value={data.conf_password}
                    name='conf_password'
                    onChange={this.onChange}
                    type='password'
                  />
                  {error.conf_password && (
                    <div className='invalid-feedback'>
                      {error.conf_password}
                    </div>
                  )}
                </div>
                <div className='row'>
                  <button
                    className='login-button'
                    onClick={this.onSubmit}
                    style={{ width: '100%', marginTop: '20px' }}
                  >
                    {language(lang).CHANGE_PASSWORD}
                    {isProcessing && (
                      <span
                        className='spinner-border spinner-border-sm'
                        role='status'
                        aria-hidden='true'
                      ></span>
                    )}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </section>
      </MainLayout>
    );
  }
}

const mapStateToProps = (state) => ({
  lang: state.language.language,
});
const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(ChangePassword);
