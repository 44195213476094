import React from "react";

import style from "./Card.module.css";

const Card = (props) => {
  const classNames = [style.card, props.className ? props.className : ""];

  return <div className={classNames.join(" ")}>{props.children}</div>;
};

export default Card;
