import React, { Component } from 'react';

import OldCartItem from './cartItems/old';
import NewCartItem from './cartItems/new';

class CartItems extends Component {
  state = {
    orders: {},
  };

  componentDidMount() {
    this.fetchNewOrder();
  }

  componentDidUpdate(prevProps) {
    if (this.props.data !== prevProps.data) {
      this.fetchNewOrder();
    }
  }

  fetchNewOrder = () => {
    const newOrder = localStorage.getItem('FULL_NEW_ORDER');
    if (newOrder) {
      this.setState({
        orders: JSON.parse(newOrder),
      });
    }
  };

  render() {
    const { orders } = this.state;

    if (Object.keys(orders).length > 0) {
      return <NewCartItem {...this.props} />;
    }
    return <OldCartItem {...this.props} />;
  }
}

export default CartItems;
