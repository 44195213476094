import React, { Component } from 'react';
import { Modal } from 'react-bootstrap';
import Swal from 'sweetalert2';
import { connect } from 'react-redux';

import { changePassword } from '../../services';
import { language } from '../../constants';

class Forgot extends Component {
  state = {
    data: {
      email: '',
    },
    error: {
      email: '',
    },
    isProcessing: false,
  };

  onChange = (e) => {
    const { name, value } = e.target;
    let { data, error } = this.state;
    data[name] = value;
    error[name] = '';
    this.setState({ data, error });
  };

  onClose = () => {
    this.setState({
      data: {
        email: '',
      },
      error: {
        email: '',
      },
    });
    this.props.closeModal();
  };

  onSubmit = () => {
    if (!this.state.isProcessing) {
      this.setState({
        isProcessing: true,
      });
      changePassword(this.state.data)
        .then((res) => {
          Swal.fire({
            text: res.data.message,
            icon: 'success',
            showCancelButton: false,
            confirmButtonColor: '#ef692a',
            confirmButtonText: 'Ok',
          }).then(() => {
            this.setState({ isProcessing: false });
            this.onClose();
          });
        })
        .catch((err) => {
          if (err.response.status === 422) {
            this.setState({
              error: err.response.data.message,
              isProcessing: false,
            });
          } else {
            Swal.fire({
              text: err.response.data.message,
              icon: 'error',
              showCancelButton: false,
              confirmButtonColor: '#ef692a',
              confirmButtonText: 'Ok',
            });
            this.setState({
              isProcessing: false,
            });
          }
        });
    }
  };

  render() {
    const { show, lang } = this.props;
    const { data, error, isProcessing } = this.state;
    return (
      <Modal show={show} onHide={this.onClose}>
        <Modal.Header closeButton>
          <Modal.Title>{language(lang).FORGOT_YOUR_PASSWORD}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className='row'>
            <div className='col'>
              <input
                className={`form-control auth-input ${
                  error.email ? 'is-invalid' : ''
                }`}
                placeholder={language(lang).ENTER_YOUR_EMAIL_ADDRESS}
                value={data.email}
                onChange={this.onChange}
                name='email'
              />
              {error.email && (
                <div className='invalid-feedback'>{error.email}</div>
              )}
            </div>
          </div>
          <button className='signin-button' onClick={this.onSubmit}>
            {language(lang).SEND_RESET_LINK}{' '}
            {isProcessing && (
              <span
                className='spinner-border spinner-border-sm'
                role='status'
                aria-hidden='true'
              ></span>
            )}
          </button>
        </Modal.Body>
      </Modal>
    );
  }
}

const mapStateToProps = (state) => ({
  lang: state.language.language,
});
const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(Forgot);
