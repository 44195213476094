import { reducerConstants } from '../constants';

export function addToCart(item, restaurantInfo) {
  return (dispatch) => {
    dispatch({
      type: reducerConstants.ADD_TO_CART,
      payload: item,
      restaurantInfo,
    });
  };
}

export function replaceCart(index, item, deliveryType) {
  return (dispatch) => {
    dispatch({
      type: reducerConstants.REPLACE_CART,
      payload: item,
      index,
      deliveryType
    });
  };
}

export function removeFromCart(index) {
  return (dispatch) => {
    dispatch({
      type: reducerConstants.REMOVE_FROM_CART,
      index,
    });
  };
}

export function setCart(items, calculated, restaurantInfo) {
  return (dispatch) => {
    dispatch({
      type: reducerConstants.SET_CART,
      items,
      calculated,
      restaurantInfo,
    });
  };
}

export function resetCart() {
  return (dispatch) => {
    dispatch({
      type: reducerConstants.RESET_CART,
    });
  };
}

export function changeDeliveryType(deliveryType) {
  return (dispatch) => {
    dispatch({
      type: reducerConstants.DELIVERY_TYPE,
      deliveryType,
    });
  };
}
