import { connect } from 'react-redux';
import React, { Component } from 'react';

import MainLayout from '../shared/MainLayout';
import EmptyCart from '../shared/EmptyCart';
import CartItems from '../shared/CartItems';
import { language } from '../../constants';

class OldOrderBasket extends Component {
  render() {
    const { lang, cartData } = this.props;
    return (
      <MainLayout>
        <section className='page-header'>
          <div className='container'>
            <h1>{language(lang).MY_BASKET}</h1>
          </div>
        </section>
        <section className='page-content' style={{borderBottom: 'none'}}>
          <div className='container'>
            {cartData.items.length > 0 ? (
              <React.Fragment>
                <div className='row'>
                  <div className='col-sm-12 text-center'>
                    <span className='cart-title'>
                      {cartData.restaurantInfo.name || '-'}
                    </span>
                  </div>
                </div>
                <CartItems data={cartData} />
              </React.Fragment>
            ) : (
              <EmptyCart />
            )}
          </div>
        </section>
      </MainLayout>
    );
  }
}

const mapStateToProps = (state) => ({
  lang: state.language.language,
  cartData: state.cartData,
});
const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(OldOrderBasket);
