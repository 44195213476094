export const calculations = (items, res, deliveryType = '') => {
  let tax = res.tax ? res.tax : 0;
  let sub_total = 0;
  items.forEach((item) => {
    sub_total = sub_total + item.price * item.qty;
    item.add_on.forEach((o) => {
      sub_total += o.price * o.qty;
    });
  });

  const isDiscount = res.discount_type
    ? res.discount_type.findIndex((disc) => disc.name.toLowerCase() === deliveryType.toLowerCase())
    : -1;

  let tax_amount = ((sub_total * tax) / 100).toFixed(2) * 1;
  tax_amount = 0;
  sub_total = sub_total.toFixed(2) * 1;
  let discount = isDiscount >= 0
    ? ((res.discount_rate * sub_total) / 100).toFixed(2) * 1
    : 0;
  let total = (sub_total - discount).toFixed(2) * 1;
  return { sub_total, tax_amount, total, discount };
};

export const calculateItems = (items) => {
  let updatedList = [];
  items.forEach((single) => {
    const {
      id,
      name,
      qty,
      add_on,
      price,
      product_size,
      product_size_id,
    } = single;
    const foodIdx = updatedList.findIndex((food) => food.id === id);
    if (foodIdx + 1 > 0) {
      let temp = {
        id,
        name,
        price,
        qty: qty + updatedList[foodIdx].qty,
        add_on: calculateAddons(updatedList[foodIdx].add_on, add_on),
      };
      if (product_size) {
        temp.product_size = product_size;
        temp.product_size_id = product_size_id;
      }
      updatedList[foodIdx] = temp;
    } else {
      updatedList.push(single);
    }
  });
  return updatedList;
};

const calculateAddons = (addons, addon) => {
  let newAddons = [...addons];
  addon.forEach((singleAddon) => {
    if (addons.length > 0) {
      const addonIdx = addons.findIndex(
        (single) => single.id === singleAddon.id
      );
      if (addonIdx + 1 > 0) {
        let temp = { ...addons[addonIdx] };
        temp.qty += singleAddon.qty;
        newAddons[addonIdx] = temp;
      } else {
        newAddons.push(singleAddon);
      }
    } else {
      newAddons.push(singleAddon);
    }
  });
  return newAddons;
};
