import React, { Component } from 'react';

import OldBasket from './orderBaskets/old';
import NewBasket from './orderBaskets/new';

class OrderBasket extends Component {
  state = {
    orders: {},
  };

  componentDidMount() {
    const fullNewOrder = localStorage.getItem('FULL_NEW_ORDER');
    if (fullNewOrder) {
      this.setState({
        orders: JSON.parse(fullNewOrder),
      });
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.cartData !== prevProps.cartData) {
      const fullNewOrder = localStorage.getItem('FULL_NEW_ORDER');
      if (fullNewOrder) {
        this.setState({
          orders: JSON.parse(fullNewOrder),
        });
      }
    }
  }

  render() {
    const { orders } = this.state;

    if (Object.keys(orders).length > 0) {
      return <NewBasket {...this.props} />;
    }
    return <OldBasket {...this.props} />;
  }
}

export default OrderBasket;
