import React, { useRef } from "react";
import { Fragment } from "react";

import Button from "../UI/Button";

const DeliveryReview = (props) => {
  const messageRef = useRef();

  const formSubmitHandler = (event) => {
    event.preventDefault();

    props.onSubmit(messageRef.current.value);
  };

  return (
    <Fragment>
      <h2>
        Thanks!!!
        <br />
        What can we do better next time?
      </h2>

      <h5>(Your feedback for Mulas)</h5>

      <form onSubmit={formSubmitHandler}>
        <div>
          <textarea ref={messageRef}></textarea>
        </div>

        <Button isProcessing={props.isProcessing}>Send</Button>
      </form>
    </Fragment>
  );
};

export default DeliveryReview;
