import { reducerConstants } from '../constants';

export default function language(
  state = {
    language: 'fin',
  },
  action
) {
  switch (action.type) {
    case reducerConstants.SET_LANGUAGE:
      return { ...state, language: action.payload };
    default:
      return state;
  }
}
