import React from 'react';
import { Pagination } from 'react-bootstrap';

const CustomPagination = (props) => {
  let {
    from,
    prevPageUrl,
    nextPageUrl,
    currentPage,
    paginationButton,
    lastPage,
  } = props;

  let pages = [];
  if (from) {
    let pageFront = currentPage;
    let pageLast = lastPage;
    let end = pageFront + 2;
    let start = pageFront - 2;
    if (pageLast - pageFront === 0) start -= 2;
    else if (pageLast - pageFront === 1) start -= 1;
    for (let i = start; i <= end; i++) {
      if (i < 1) {
        end++;
        continue;
      }
      if (i > pageLast) break;
      pages.push(i);
    }
  }

  let pagination = pages.map((page) => (
    <Pagination.Item
      active={page === currentPage}
      key={page}
      onClick={() => paginationButton(page)}
    >
      {page}
    </Pagination.Item>
  ));

  return (
    <div className='row'>
      <div className='col-sm-12'>
        <Pagination>
          {prevPageUrl && (
            <Pagination.Prev
              onClick={() => paginationButton(currentPage - 1)}
            />
          )}
          {pagination}
          {nextPageUrl && (
            <Pagination.Next
              onClick={() => paginationButton(currentPage + 1)}
            />
          )}
        </Pagination>
      </div>
    </div>
  );
};

export default CustomPagination;
