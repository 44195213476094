import React from 'react';
import loader from '../../assets/images/loader.gif';

const Loader = () => (
  <div
    className='d-flex align-items-center justify-content-center'
    style={{ height: '100vh' }}
  >
    <img src={loader} alt='loader' />
  </div>
);

export default Loader;
