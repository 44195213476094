import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';
import { connect } from 'react-redux';
import AsyncSelect from 'react-select/async';

import MainLayout from './shared/MainLayout';
import { withDashboard } from '../hoc/Dashboard';
import Loader from './shared/Loader';
import DriverRegister from './modals/DriverRegister';
import RestaurantRegister from './modals/RestaurantRegister';
import logo_image from '../assets/images/logo.png';
import delivery_cart from '../assets/images/delivery.svg';
import { finnishNumber } from '../utils/common';
import { language } from '../constants';
import { fetchRestaurants } from '../services';

const customStyles = {
  option: (provided, state) => ({
    ...provided,
    color: state.isSelected ? 'white' : 'black',
    backgroundColor: state.isSelected ? '#ef692a' : 'white',
  }),
};

class Dashboard extends Component {
  state = {
    data: {
      name: '',
      address: '',
    },
    isDriver: false,
    isRestaurant: false,
  };
  componentDidMount() {
    this.fetchData();
  }

  fetchData = () => {
    this.props.getNearbyRestaurants();
    this.props.getTopRestaurants();
    this.props.getSpecialOffers();
  };

  onChange = (e) => {
    const { name, value } = e.target;
    let { data } = this.state;
    data[name] = value;
    this.setState({
      data,
    });
  };

  onSearch = () => {
    const { data } = this.state;
    this.props.history.push(
      `/restaurants?name=${data.name}&address=${data.address}`
    );
  };

  toggleDriver = () => {
    this.setState({
      isDriver: !this.state.isDriver,
    });
  };

  toggleRestaurant = () => {
    this.setState({
      isRestaurant: !this.state.isRestaurant,
    });
  };

  onkeyPress = (e) => {
    if (e.keyCode === 13) {
      this.onSearch();
    }
  };

  getRestaurants = (inputValue, callback) => {
    return fetchRestaurants([inputValue], ['name']).then((response) => {
      return callback(
        response.data
          ? response.data.data.map((single) => ({
              label: single.name,
              value: single.name,
            }))
          : []
      );
    });
  };

  onSelectRestaurant = (e) => {
    let { data } = this.state;
    data.name = e.value;
    this.setState({
      data,
    });
  };

  render() {
    const {
      isFetchingNearbyRestaurants,
      isFetchingTopRestaurants,
      nearbyRestaurants,
      topRestaurants,
      lang,
      isFetchingSpecialOffers,
      specialOffers,
    } = this.props;

    const { data, isDriver, isRestaurant } = this.state;

    const nearbyRestaurantsList = nearbyRestaurants.map((restaurant) => {
      const {
        id,
        name,
        isOpen,
        cover_image,
        minimum_order_amount,
        currency_symbol,
        address,
        delivery_order,
        distance,
        discount_rate,
        is_delivery_free,
      } = restaurant;
      return (
        <div className='col-lg-3 col-md-6 com-sm-12' key={id}>
          <div className='card restaurant-card'>
            <div className='card-body'>
              {discount_rate ? (
                <div className='discount-wrapper'>
                  <span className='vibes-text'>
                    {`${discount_rate} % ${language(lang).DISCOUNT}`}
                  </span>
                </div>
              ) : null}
              <NavLink to={`/restaurants/${id}`}>
                <React.Fragment>
                  <div className='restaurant-image'>
                    {delivery_order === '1' && (
                      <div className='delivery-wrapper'>
                        <img
                          src={delivery_cart}
                          alt='delivery'
                          style={{ width: '100%', height: '100%' }}
                          title='Delivery Available'
                        />
                      </div>
                    )}

                    {is_delivery_free ? (
                      <div className='delivery-free-wrapper'>
                        <span style={{ color: 'white' }}>
                          {language(lang).FREE_DELIVERY}
                        </span>
                      </div>
                    ) : null}

                    {!isOpen ? (
                      <div className='ribbon-wrapper'>
                        <div className={`ribbon ${isOpen ? 'green' : 'red'}`}>
                          {isOpen ? 'Open' : 'Closed'}
                        </div>
                      </div>
                    ) : null}

                    <img
                      src={cover_image || logo_image}
                      alt={name}
                      className='dashboard-image'
                    />
                  </div>
                  <span>{name}</span>
                </React.Fragment>
              </NavLink>
              <p className='text-muted'>
                <small>{finnishNumber(distance + ' KM')}</small>
              </p>
              <p className='text-muted'>
                <small>{address}</small>
              </p>

              {minimum_order_amount !== 0 && (
                <p className='text-muted'>
                  <small>
                    {`${finnishNumber(
                      minimum_order_amount
                    )}${currency_symbol} ${language(lang).MINIMUM_ORDER}`}
                  </small>
                </p>
              )}
            </div>
          </div>
        </div>
      );
    });

    const topRestaurantsList = topRestaurants.map((restaurant) => {
      const {
        id,
        name,
        isOpen,
        cover_image,
        minimum_order_amount,
        currency,
        address,
        delivery_order,
        discount_rate,
        is_delivery_free,
      } = restaurant;
      return (
        <div className='col-lg-3 col-md-6 com-sm-12' key={id}>
          <div className='card restaurant-card'>
            <div className='card-body'>
              {discount_rate ? (
                <div className='discount-wrapper'>
                  <span className='vibes-text'>
                    {`${discount_rate} % ${language(lang).DISCOUNT}`}
                  </span>
                </div>
              ) : null}
              <NavLink to={`/restaurants/${id}`}>
                <React.Fragment>
                  <div className='restaurant-image'>
                    {delivery_order === '1' && (
                      <div className='delivery-wrapper'>
                        <img
                          src={delivery_cart}
                          alt='delivery'
                          style={{ width: '100%', height: '100%' }}
                          title='Delivery Available'
                        />
                      </div>
                    )}

                    {is_delivery_free ? (
                      <div className='delivery-free-wrapper'>
                        <span style={{ color: 'white' }}>
                          {language(lang).FREE_DELIVERY}
                        </span>
                      </div>
                    ) : null}
                    {!isOpen ? (
                      <div className='ribbon-wrapper'>
                        <div className={`ribbon ${isOpen ? 'green' : 'red'}`}>
                          {isOpen ? 'Open' : 'Closed'}
                        </div>
                      </div>
                    ) : null}
                    <img
                      src={cover_image || logo_image}
                      alt={name}
                      className='dashboard-image'
                    />
                  </div>
                  <span>{name}</span>
                </React.Fragment>
              </NavLink>

              <p className='text-muted'>
                <small>{address}</small>
              </p>

              {minimum_order_amount !== 0 && (
                <p className='text-muted'>
                  <small>
                    {`${finnishNumber(minimum_order_amount)}${
                      currency ? currency.currency_symbol : ''
                    } ${language(lang).MINIMUM_ORDER}`}
                  </small>
                </p>
              )}
            </div>
          </div>
        </div>
      );
    });

    const specialOfferList = specialOffers.map((restaurant) => {
      const {
        id,
        name,
        isOpen,
        cover_image,
        minimum_order_amount,
        currency,
        address,
        delivery_order,
        discount_rate,
        is_delivery_free,
      } = restaurant;
      return (
        <div className='col-lg-3 col-md-6 com-sm-12' key={id}>
          <div className='card restaurant-card'>
            <div className='card-body'>
              {discount_rate ? (
                <div className='discount-wrapper'>
                  <span className='vibes-text'>
                    {`${discount_rate} % ${language(lang).DISCOUNT}`}
                  </span>
                </div>
              ) : null}
              <NavLink to={`/restaurants/${id}`}>
                <React.Fragment>
                  <div className='restaurant-image'>
                    {delivery_order === '1' && (
                      <div className='delivery-wrapper'>
                        <img
                          src={delivery_cart}
                          alt='delivery'
                          style={{ width: '100%', height: '100%' }}
                          title='Delivery Available'
                        />
                      </div>
                    )}

                    {is_delivery_free ? (
                      <div className='delivery-free-wrapper'>
                        <span style={{ color: 'white' }}>
                          {language(lang).FREE_DELIVERY}
                        </span>
                      </div>
                    ) : null}
                    {!isOpen ? (
                      <div className='ribbon-wrapper'>
                        <div className={`ribbon ${isOpen ? 'green' : 'red'}`}>
                          {isOpen ? 'Open' : 'Closed'}
                        </div>
                      </div>
                    ) : null}
                    <img
                      src={cover_image || logo_image}
                      alt={name}
                      className='dashboard-image'
                    />
                  </div>
                  <span>{name}</span>
                </React.Fragment>
              </NavLink>

              <p className='text-muted'>
                <small>{address}</small>
              </p>

              {minimum_order_amount !== 0 && (
                <p className='text-muted'>
                  <small>
                    {`${finnishNumber(minimum_order_amount)}${
                      currency ? currency.currency_symbol : ''
                    } ${language(lang).MINIMUM_ORDER}`}
                  </small>
                </p>
              )}
            </div>
          </div>
        </div>
      );
    });

    if (
      isFetchingTopRestaurants ||
      isFetchingNearbyRestaurants ||
      isFetchingSpecialOffers
    ) {
      return <Loader />;
    }
    return (
      <MainLayout isDashboard={true}>
        <DriverRegister show={isDriver} closeModal={this.toggleDriver} />
        <RestaurantRegister
          show={isRestaurant}
          closeModal={this.toggleRestaurant}
        />
        <section className='banner banner-large d-flex flex-row-reverse align-items-center'>
          <div
            style={{
              marginRight: '20px',
              maxWidth: '35%',
              textAlign: 'center',
            }}
          >
            <div>
              <span style={{ fontWeight: 'bold', fontSize: '60px' }}>
                {language(lang).ORDER_DELIVERY_AND_TAKE_OUT}
              </span>
            </div>
            <div>
              <div className='row'>
                <div className='col-sm-12 col-md-6'>
                  <AsyncSelect
                    styles={customStyles}
                    noOptionsMessage={() => 'Type to search ..'}
                    loadOptions={this.getRestaurants}
                    onChange={this.onSelectRestaurant}
                    closeMenuOnSelect={true}
                    placeholder={language(lang).RESTAURANT_NAME}
                    onKeyDown={this.onkeyPress}
                  />
                </div>
                <div className='col-sm-12 col-md-6'>
                  <input
                    className='form-control'
                    placeholder={language(lang).YOUR_ADDRESS}
                    value={data.address}
                    name='address'
                    onChange={this.onChange}
                    onKeyDown={this.onkeyPress}
                  />
                </div>
              </div>

              <div className='row'>
                <div className='col text-center mt-2'>
                  <button className='search-button' onClick={this.onSearch}>
                    {language(lang).SEARCH}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className='banner banner-small d-flex flex-column-reverse'>
          <div
            style={{
              textAlign: 'center',
              height: '37%',
            }}
          >
            <div>
              <span style={{ fontWeight: 'bold', fontSize: '22px' }}>
                {language(lang).ORDER_DELIVERY_AND_TAKE_OUT}
              </span>
            </div>
            <div className='row'>
              <div className='col-sm-12'></div>
            </div>

            <div className='row'>
              <div className='col-sm-12' style={{ padding: '5px 20px' }}>
                <AsyncSelect
                  styles={customStyles}
                  noOptionsMessage={() => 'Type to search ..'}
                  loadOptions={this.getRestaurants}
                  onChange={this.onSelectRestaurant}
                  closeMenuOnSelect={true}
                  placeholder={language(lang).RESTAURANT_NAME}
                  onKeyDown={this.onkeyPress}
                />
              </div>
            </div>

            <div className='row'>
              <div className='col-sm-12' style={{ padding: '5px 20px' }}>
                <input
                  className='form-control'
                  placeholder={language(lang).YOUR_ADDRESS}
                  value={data.address}
                  name='address'
                  onChange={this.onChange}
                  onKeyDown={this.onkeyPress}
                />
              </div>
            </div>

            <div className='row'>
              <div className='col-sm-12' style={{ padding: '5px 20px' }}>
                <button className='search-button' onClick={this.onSearch}>
                  {language(lang).SEARCH}
                </button>
              </div>
            </div>
          </div>
        </section>

        {specialOffers.length > 0 ? (
          <section className='food-section'>
            <div className='container'>
              <div className='row'>
                <div className='col text-center'>
                  <span className='title'>{language(lang).SPECIAL_OFFER}</span>
                </div>
              </div>
              <div className='row'>{specialOfferList}</div>
            </div>
          </section>
        ) : null}

        <section className='food-section'>
          <div className='container'>
            <div className='row'>
              <div className='col text-center'>
                <span className='title'>
                  {language(lang).NEARBY_RESTAURANTS}
                </span>
              </div>
            </div>
            <div className='row'>{nearbyRestaurantsList}</div>
          </div>
        </section>

        <section className='food-section'>
          <div className='container'>
            <div className='row'>
              <div className='col text-center'>
                <span className='title'>
                  {language(lang).POPULAR_RESTAURANTS}
                </span>
              </div>
            </div>
            <div className='row'>{topRestaurantsList}</div>
          </div>
        </section>

        <section className='food-section'>
          <div className='container'>
            <div className='row'>
              <div className='col-md-6 col-sm-12 text-center'>
                <span>{language(lang).LIST_YOUR_RESTAURANT_AT_MULAS}</span>
                <div>
                  <button
                    className='login-button'
                    onClick={this.toggleRestaurant}
                  >
                    {language(lang).SEND_REQUEST}
                  </button>
                </div>
              </div>
              <div className='col-md-6 col-sm-12 text-center'>
                <span>{language(lang).BE_PART_OF_MULAS_AS_A_DELIVERY_BOY}</span>
                <div>
                  <button className='login-button' onClick={this.toggleDriver}>
                    {language(lang).SEND_REQUEST}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </section>
      </MainLayout>
    );
  }
}

const mapStateToProps = (state) => ({
  lang: state.language.language,
});
const mapDispatchToProps = (dispatch) => ({});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withDashboard(Dashboard));
