import React, { Component } from 'react';
import { fetchRestaurantTypes, fetchRestaurants } from '../services';

export function withRestaurants(WrappedComponent) {
  return class Restaurants extends Component {
    state = {
      isRestaurantTypeLoading: false,
      isRestaurantListLoading: false,
      restaurantList: [],
      restaurantTypes: [],
    };

    getRestaurants = (value, key) => {
      this.setState({
        isRestaurantListLoading: true,
      });
      fetchRestaurants(value, key)
        .then((res) => {
          this.setState({
            isRestaurantListLoading: false,
          });
          if (res.status === 200) {
            this.setState({
              restaurantList: res.data.data,
            });
          }
        })
        .catch((err) => {
          this.setState({
            isRestaurantListLoading: false,
          });
        });
    };

    getRestaurantTypes = () => {
      this.setState({
        isRestaurantTypeLoading: true,
      });
      fetchRestaurantTypes()
        .then((res) => {
          this.setState({
            isRestaurantTypeLoading: false,
          });
          if (res.status === 200) {
            this.setState({
              restaurantTypes: res.data,
            });
          }
        })
        .catch((err) => {
          this.setState({
            isRestaurantTypeLoading: false,
          });
        });
    };

    render() {
      const {
        isRestaurantListLoading,
        isRestaurantTypeLoading,
        restaurantList,
        restaurantTypes,
      } = this.state;
      return (
        <WrappedComponent
          isRestaurantListLoading={isRestaurantListLoading}
          isRestaurantTypeLoading={isRestaurantTypeLoading}
          restaurantList={restaurantList}
          restaurantTypes={restaurantTypes}
          getRestaurantTypes={this.getRestaurantTypes}
          getRestaurants={this.getRestaurants}
          {...this.props}
        />
      );
    }
  };
}
