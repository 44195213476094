import Swal from 'sweetalert2';
import Rating from 'react-rating';
import { connect } from 'react-redux';
import React, { Component } from 'react';
import Expand from 'react-expand-animated';
import { Link, Element } from 'react-scroll';
import { createBrowserHistory } from 'history';

import NewMenu from './NewMenu';
import Loader from '../shared/Loader';
import { language } from '../../constants';
import AddToCart from '../modals/AddToCart';
import MainLayout from '../shared/MainLayout';
import { storage } from '../../utils/storage';
import { saveOrder } from '../../actions/orders';
import { openLogin } from '../../actions/profile';
import { finnishNumber } from '../../utils/common';
import { generateCart } from '../../services/orders';
import { fetchThreeLevelMenu } from '../../services/restaurants';
import { withRestaurantDetails } from '../../hoc/RestaurantDetails';
import { addToCart, replaceCart, resetCart } from '../../actions/carts';

import spice from '../../assets/images/spice.png';

const PARSED_RESTAURANT_ORDER = 'PARSED_RESTAURANT_ORDER';
const FULL_RESTAURANT_ORDER = 'FULL_RESTAURANT_ORDER';
const FULL_NEW_ORDER = 'FULL_NEW_ORDER';
const PARSED_NEW_ORDER = 'PARSED_NEW_ORDER';

class NewRestaurantDetail extends Component {
  state = {
    menuIndex: 1,
    showAddModal: false,
    cartDataState: {},
    isLoggedIn: false,
    openProduct: -1,
    selectedProducts: [],
    selectedDetails: {},
    data: [],
    orders: {},
    isDisabled: false,
    fullOrder: {},
    isNew: true,
    parsedRestaurantOrder: {},
    fullRestaurantOrder: {},
    restaurantId: this.props.match.params.id,
    fullNewOrder: {},
    parsedNewOrder: {},
    detailedOrder: {},
    error: {},
    isCategoryLoading: false,
    tempError: {},
  };
  componentDidMount() {
    if (this.props.location.state && this.props.location.state.error) {
      this.setState({ tempError: this.props.location.state.error });
      const history = createBrowserHistory();
      if (history.location.state && history.location.state.error) {
        let state = { ...history.location.state };
        delete state.error;
        history.replace({ ...history.location, state });
      }
    }
    this.fetchData();
    this.setState({
      isLoggedIn: Object.keys(this.props.profile).length > 0,
    });

    const fullNewOrder = JSON.parse(localStorage.getItem(FULL_NEW_ORDER));
    if (fullNewOrder) {
      this.setState({
        fullNewOrder,
      });
    }

    const parsedNewOrder = JSON.parse(localStorage.getItem(PARSED_NEW_ORDER));
    if (parsedNewOrder) {
      this.setState({
        parsedNewOrder,
      });
    }

    const restaurantId = fullNewOrder?.restaurant?.id;

    if (restaurantId === parseInt(this.state.restaurantId)) {
      this.setState({
        fullRestaurantOrder: { [parseInt(restaurantId)]: fullNewOrder },
        parsedRestaurantOrder: { [parseInt(restaurantId)]: parsedNewOrder },
      });
    }

    const fullOrder = localStorage.getItem('full_order');
    if (fullOrder) {
      this.setState({
        fullOrder: JSON.parse(fullOrder),
      });
    }
  }

  parseOrder = (orderDetail) => {
    const { order_detail } = orderDetail;
    let temp = {};
    order_detail.forEach((single) => {
      const tempp = {};
      if (!single.product_detail) {
        temp[single.product_id] = {
          id: single.product_id,
          name: single.name,
          notes: single.notes,
          qty: single.qty,
          order: {},
        };
      } else {
        const { total, ...restDetail } = single.product_detail;

        Object.keys(restDetail).forEach((one) => {
          restDetail[one].forEach((hmmm) => {
            if (Object.keys(tempp).includes(hmmm.product_property_id + '')) {
              let ttttttt = [...tempp[hmmm.product_property_id]];
              ttttttt.push(hmmm.product_property_detail_id);
              tempp[hmmm.product_property_id] = ttttttt;
            } else {
              tempp[hmmm.product_property_id] = [
                hmmm.product_property_detail_id,
              ];
            }
          });
        });

        const finalOrder = {};

        Object.keys(tempp).forEach((tttt) => {
          finalOrder[tttt] = tempp[tttt];
        });
        temp[single.product_id] = {
          id: single.product_id,
          name: single.name,
          notes: single.notes,
          qty: single.qty,
          order: finalOrder,
        };
      }
    });

    return temp;
  };

  componentDidUpdate(prevProps) {
    if (this.props.restaurantDetail !== prevProps.restaurantDetail) {
      if (this.props.restaurantDetail.menu_type === 'default') {
        this.setState({ isNew: false });
      }
    }

    if (this.props.profile !== prevProps.profile) {
      this.setState({
        isLoggedIn: Object.keys(this.props.profile).length > 0,
      });
    }

    if (this.props.reduxOrder !== prevProps.reduxOrder) {
      const temp = this.parseOrder(this.props.reduxOrder);

      this.setState({
        orders: temp,
        isDisabled: false,
        fullOrder: this.props.reduxOrder,
      });

      localStorage.setItem('new_order', JSON.stringify(temp));
      localStorage.setItem('full_order', JSON.stringify(this.props.reduxOrder));
    }
  }

  fetchData = () => {
    this.setState({ isCategoryLoading: true });
    fetchThreeLevelMenu(this.props.match.params.id)
      .then((res) => {
        this.setState(
          {
            data: res.data,
            isCategoryLoading: false,
            error: { ...this.state.tempError },
            tempError: {},
          },
          () => {
            const {
              error,
              data,
              parsedRestaurantOrder,
              restaurantId,
            } = this.state;
            let tempDetailedOrder = {};
            const tempParsedRestaurantOrder =
              { ...parsedRestaurantOrder[restaurantId] } || {};

            if (Object.keys(error).length > 0) {
              Object.keys(parsedRestaurantOrder[restaurantId]).forEach(
                (single) => {
                  data.forEach((unitProduct) => {
                    unitProduct.product.forEach((product) => {
                      const meal = {};
                      const meals = [];
                      if (product.id == single) {
                        if (!tempDetailedOrder[product.id]) {
                          tempDetailedOrder[product.id] = {};
                          product.product_property.forEach((property) => {
                            if (property.name === 'Ateria') {
                              meal.id = property.id;
                              property.product_property_detail.forEach(
                                (unitDetail) => {
                                  if (unitDetail.name === 'Ateria') {
                                    meal.mealId = unitDetail.id;
                                  }
                                }
                              );
                            }

                            if (property.type === 'meal') {
                              meals.push(property.id);
                            }

                            const parsedProperty = tempParsedRestaurantOrder[
                              product.id
                            ]
                              ? tempParsedRestaurantOrder[product.id].order
                              : {};
                            tempDetailedOrder[product.id][property.id] = {
                              min: property.min,
                              max: property.max,
                              selected: parsedProperty[property.id]
                                ? parsedProperty[property.id].length
                                : 0,
                              type: property.type,
                            };

                            meal.meals = meals;
                            tempDetailedOrder[product.id].meal = meal;
                          });
                        }
                      }
                    });
                  });
                }
              );

              const firstProduct = Object.keys(error)[0];

              this.setState(
                {
                  openProduct: firstProduct + '',
                  detailedOrder: tempDetailedOrder,
                },
                () => {
                  const elmnt = document.getElementById(
                    `product-${error[firstProduct][0]}`
                  );
                  if (elmnt) {
                    elmnt.scrollIntoView();
                  }
                }
              );
            }
          }
        );
      })
      .catch((err) => {
        this.setState({ isCategoryLoading: false });
      });
  };
  onChangeCategory = (e) => {
    let element = document.getElementById(e.target.value);
    element.scrollIntoView({ behavior: 'smooth' });
  };
  onChangeMenu = (menuIndex) => {
    this.setState({ menuIndex });
  };
  parseTime = (time) => {
    return time.slice(0, -3);
  };
  showAddToCartModal = (cartDataState) => {
    if (this.state.isLoggedIn) {
      if (cartDataState.addon_count) {
        this.props.getAddons(cartDataState.id);
      } else {
        this.props.resetAddons();
      }
      this.setState({
        cartDataState,
        showAddModal: true,
      });
    } else {
      this.props.openLogin();
    }
  };
  closeAddToCartModal = () => {
    this.setState({
      cartDataState: {},
      showAddModal: false,
    });
  };

  openProductModal = (id, product) => {
    let { openProduct, restaurantId } = this.state;
    let detailedOrder = { ...this.state.detailedOrder };
    const parsedRestaurantOrder =
      { ...this.state.parsedRestaurantOrder[restaurantId] } || {};
    if (!detailedOrder[id]) {
      detailedOrder[id] = {};
      const meal = {};
      const meals = [];
      product.product_property.forEach((property) => {
        if (property.name === 'Ateria') {
          meal.id = property.id;
          property.product_property_detail.forEach((unitDetail) => {
            if (unitDetail.name === 'Ateria') {
              meal.mealId = unitDetail.id;
            }
          });
        }

        if (property.type === 'meal') {
          meals.push(property.id);
        }

        const parsedProperty = parsedRestaurantOrder[id]
          ? parsedRestaurantOrder[id].order
          : {};
        detailedOrder[id][property.id] = {
          min: property.min,
          max: property.max,
          selected: parsedProperty[property.id]
            ? parsedProperty[property.id].length
            : 0,
          type: property.type,
        };
      });
      meal.meals = meals;

      detailedOrder[product.id].meal = meal;
    }

    let productId;

    if (openProduct === id + '') {
      productId = -1;
    } else {
      productId = id;
    }

    this.setState({
      detailedOrder,
      openProduct: productId + '',
    });
  };

  changeOrder = async (orderDetail, reset = false) => {
    const { restaurantId } = this.state;
    let parsedRestaurantOrder = { ...this.state.parsedRestaurantOrder };
    let fullRestaurantOrder = { ...this.state.fullRestaurantOrder };
    let detailedOrder = { ...(this.state.detailedOrder[orderDetail.id] || {}) };
    let error = { ...this.state.error };
    this.setState({ isDisabled: true });
    let tempProducts;
    if (reset) {
      tempProducts = {};
    } else {
      tempProducts = this.state.parsedRestaurantOrder[restaurantId] || {};
    }

    tempProducts[orderDetail.id] = orderDetail;

    let products = [];
    Object.keys(tempProducts).forEach((single) => {
      if (tempProducts[single].qty) {
        let order = { ...tempProducts[single] };
        let tempCount = {};
        if (order.order) {
          if (!order.product_property) {
            order.product_property = Object.keys(order.order).map(
              (oneOrder) => {
                tempCount[parseInt(oneOrder)] = order.order[oneOrder].length;
                return {
                  product_property_id: parseInt(oneOrder),
                  product_property_detail_ids: order.order[oneOrder],
                };
              }
            );
          }
          delete order.order;
        }

        if (order.product_property && !order.product_property.length) {
          if (order.id === orderDetail.id) {
            Object.keys(detailedOrder).forEach((singleOrder) => {
              detailedOrder[singleOrder].selected = 0;
            });
            delete order.product_property;
          }
        }

        const meal = detailedOrder.meal;

        let productError = error[order.id] || [];
        let check = false;
        let positions = [];
        if (order.product_property) {
          order.product_property.forEach((singleOrder, o) => {
            if (singleOrder.product_property_id === meal.id) {
              if (
                singleOrder.product_property_detail_ids.includes(meal.mealId)
              ) {
                check = true;
              }
            }
            if (meal.meals.includes(singleOrder.product_property_id)) {
              positions.push(o);
            }
            const propertyLength =
              singleOrder.product_property_detail_ids.length;
            if (productError.includes(singleOrder.product_property_id)) {
              if (
                detailedOrder[singleOrder.product_property_id].min <=
                propertyLength
              ) {
                const index = productError.indexOf(
                  singleOrder.product_property_id
                );
                if (index > -1) {
                  productError.splice(index, 1);
                }
              }
            }
            if (detailedOrder[singleOrder.product_property_id]) {
              detailedOrder[
                singleOrder.product_property_id
              ].selected = propertyLength;
            }
          });
        }
        positions.sort();
        positions.reverse();
        const product_property = order.product_property
          ? [...order.product_property]
          : [];
        if (!check) {
          positions.forEach((position) => {
            product_property.splice(position, 1);
          });

          meal.meals.forEach((mealId) => {
            detailedOrder[mealId].selected = 0;
          });
        }

        order.product_property = product_property;
        products.push(order);
        if (productError.length > 0) {
          error[order.id] = productError;
        } else {
          delete error[order.id];
        }
      }
    });

    const errorKeys = Object.keys(error);
    this.setState({
      detailedOrder: {
        ...this.state.detailedOrder,
        [orderDetail.id]: detailedOrder,
      },
      error,
      openProduct:
        errorKeys.length > 0 ? errorKeys[0] + '' : this.state.openProduct,
    });

    if (!products.length) {
      delete parsedRestaurantOrder[restaurantId];
      delete fullRestaurantOrder[restaurantId];

      localStorage.removeItem(PARSED_RESTAURANT_ORDER);
      localStorage.removeItem(FULL_RESTAURANT_ORDER);
      localStorage.removeItem(FULL_NEW_ORDER);
      localStorage.removeItem(PARSED_NEW_ORDER);
      localStorage.removeItem('DETAILED_ORDER');
      localStorage.removeItem('RESTAURANT_ID');

      this.resetCart();
      await this.setState({ isDisabled: false, parsedRestaurantOrder });
      return;
    }

    let temp = {};
    temp.products = products;
    temp.contact_no = '0401234567';
    temp.delivery_type = 'pick';
    temp.pre_order = 1;
    temp.scheduled_date = '';
    generateCart(temp)
      .then((res) => {
        const temp = this.parseOrder(res.data);
        parsedRestaurantOrder[restaurantId] = temp;
        fullRestaurantOrder[restaurantId] = res.data;
        localStorage.setItem(
          PARSED_RESTAURANT_ORDER,
          JSON.stringify(parsedRestaurantOrder)
        );
        localStorage.setItem(
          FULL_RESTAURANT_ORDER,
          JSON.stringify(fullRestaurantOrder)
        );

        localStorage.setItem(
          'DETAILED_ORDER',
          JSON.stringify(this.state.detailedOrder)
        );

        localStorage.setItem('RESTAURANT_ID', this.state.restaurantId);
        localStorage.setItem(PARSED_NEW_ORDER, JSON.stringify(temp));
        localStorage.setItem(FULL_NEW_ORDER, JSON.stringify(res.data));
        this.props.saveOrder(res.data);
        this.setState({
          isDisabled: false,
          parsedRestaurantOrder,
          fullRestaurantOrder,
        });
      })
      .catch((err) => {
        this.setState({ isDisabled: false });
      });
  };

  onConfirmReplaceCart = () => {
    this.resetCart();
    const { restaurantId } = this.state;
    const fullNewOrder = {
      ...this.state.fullRestaurantOrder[restaurantId],
    };
    const parsedNewOrder = {
      ...this.state.parsedRestaurantOrder[restaurantId],
    };
    const fullRestaurantOrder = {
      [restaurantId]: fullNewOrder,
    };
    const parsedRestaurantOrder = {
      [restaurantId]: parsedNewOrder,
    };
    localStorage.setItem(FULL_NEW_ORDER, JSON.stringify(fullNewOrder));
    localStorage.setItem(PARSED_NEW_ORDER, JSON.stringify(parsedNewOrder));
    localStorage.setItem(
      FULL_RESTAURANT_ORDER,
      JSON.stringify(fullRestaurantOrder)
    );
    localStorage.setItem(
      PARSED_RESTAURANT_ORDER,
      JSON.stringify(parsedRestaurantOrder)
    );
    this.setState({
      fullNewOrder,
      parsedNewOrder,
      fullRestaurantOrder,
      parsedRestaurantOrder,
    });
    localStorage.removeItem('cart');
    this.props.saveOrder(fullNewOrder);
    // this.props.history.push('/my-basket');
  };

  onConfirm = () => {
    const { fullOrder, restaurantId, isLoggedIn } = this.state;
    const oldRestaurantId = fullOrder.restaurant_id;

    if (!isLoggedIn) {
      this.props.openLogin();
      return;
    }

    const parsedOldOrder = JSON.parse(localStorage.getItem('cart'));
    const fullNewOrder = JSON.parse(localStorage.getItem('FULL_NEW_ORDER'));
    if (
      (oldRestaurantId && oldRestaurantId != parseInt(restaurantId)) ||
      (parsedOldOrder && parsedOldOrder.items) ||
      (fullNewOrder &&
        fullNewOrder.restaurant &&
        fullNewOrder.restaurant.id !== parseInt(restaurantId))
    ) {
      Swal.fire({
        title: 'Replace cart?',
        text: 'You can only order items from one restaurant at a time.',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#ef692a',
        cancelButtonColor: '#6c757d',
        confirmButtonText: 'Yes, replace it!',
      }).then((result) => {
        if (result.value) {
          this.onConfirmReplaceCart();
          this.props.history.push('/my-basket');
        }
      });
    } else {
      this.onConfirmReplaceCart();
      this.props.history.push('/my-basket');
    }
  };

  goToCart = () => {
    if (this.state.isLoggedIn) {
      this.props.history.push('/my-basket');
    } else {
      this.openLogin();
    }
  };

  openLogin = () => {
    this.props.openLogin();
  };

  resetCart = () => {
    storage.emptyCart();
    this.props.resetCart();
  };

  render() {
    const {
      isRestaurantDetailLoading,
      isRestaurantProductLoading,
      restaurantDetail,
      cartData,
      addons,
      isAddonLoading,
      currencySymbol,
      lang,
    } = this.props;

    const {
      cartDataState,
      showAddModal,
      openProduct,
      data,
      isDisabled,
      parsedRestaurantOrder,
      fullRestaurantOrder,
      restaurantId,
      parsedNewOrder,
      detailedOrder,
      error,
      isCategoryLoading,
    } = this.state;

    const fullOrder = fullRestaurantOrder[restaurantId] || {};
    const rTypes = restaurantDetail.restaurant_type || [];
    const orders = parsedRestaurantOrder[restaurantId] || {};

    let isDirty = false;

    if (
      !parsedNewOrder &&
      Object.keys(parsedRestaurantOrder[restaurantId]).length === 0
    ) {
      isDirty = false;
    } else if (
      !parsedNewOrder[restaurantId] &&
      !parsedRestaurantOrder[restaurantId]
    ) {
      isDirty = false;
    } else if (
      JSON.stringify(parsedNewOrder) !==
      JSON.stringify(parsedRestaurantOrder[[restaurantId]])
    ) {
      isDirty = true;
    }

    const productsList = (products = []) => {
      return products.map((product, o) => {
        const { id, name, descriptions, price, spicy, offer_price } = product;
        let index = cartData.items.findIndex((x) => x.id === id);
        let spiceImage = [];
        for (let i = 0; i < spicy || 0; i++) {
          spiceImage.push(i);
        }

        let productPrice = price;
        let isActiveProduct = false;

        if (fullOrder.order_detail) {
          fullOrder.order_detail.forEach((single) => {
            if (id === single.product_id) {
              productPrice = single.total;
              isActiveProduct = true;
            }
          });
        }

        const qty = orders[id] ? orders[id].qty : 0;

        const isActive = !!qty;
        return (
          <li
            key={id}
            className={`${
              openProduct === id + ''
                ? 'active-product'
                : products.length === o + 1 || isActive
                ? ''
                : 'border-bottom'
            } ${isActive ? 'selected-product' : ''}`}
          >
            <div
              className='d-flex'
              style={{ cursor: 'pointer' }}
              onClick={() => {
                this.openProductModal(id, product);
              }}
            >
              <div>
                <div>
                  <button className='new-add-btn'>{`${
                    openProduct === id + '' ? '-' : '+'
                  }`}</button>
                </div>
                <div>
                  <span className='menu-quantity'>
                    {qty ? qty + 'x' || '' : ''}
                  </span>
                </div>
              </div>
              <div style={{ flex: 1, marginLeft: '8px' }}>
                <div className='d-flex'>
                  <span className='text-bold'>{name}</span>
                  <div style={{ marginLeft: '2px' }}>
                    {spiceImage.map((single, i) => (
                      <img
                        src={spice}
                        style={{
                          height: '14px',
                          width: '14px',
                          marginLeft: '2px',
                        }}
                        alt={name}
                        key={i}
                      />
                    ))}
                  </div>
                </div>
                <div>
                  <span
                    className='text-muted'
                    style={{ wordSpacing: '-1px', lineHeight: '1' }}
                  >
                    {descriptions}
                  </span>
                </div>

                <div>
                  {isActiveProduct ? (
                    <span
                      className={`text-bold ${
                        isActiveProduct ? 'active-text' : ''
                      }`}
                    >
                      {finnishNumber(productPrice.toFixed(2) + currencySymbol)}
                    </span>
                  ) : (
                    <React.Fragment>
                      <span
                        className={`text-bold`}
                        style={
                          offer_price
                            ? {
                                textDecoration: 'line-through',
                                textDecorationColor: '#ef692a',
                              }
                            : {}
                        }
                      >
                        {finnishNumber(
                          productPrice.toFixed(2) + currencySymbol
                        )}
                      </span>
                      {offer_price ? (
                        <span
                          className={`text-bold`}
                          style={{ marginLeft: '4px' }}
                        >
                          {finnishNumber(
                            offer_price.toFixed(2) + currencySymbol
                          )}
                        </span>
                      ) : null}
                    </React.Fragment>
                  )}
                </div>
              </div>
            </div>
            <Expand open={openProduct === id + ''}>
              <NewMenu
                isDisabled={isDisabled}
                data={product}
                index={index}
                changeOrder={this.changeOrder}
                fullOrder={fullOrder}
                restaurantId={restaurantId}
                orderDetail={orders[product.id] || {}}
                detailedOrder={detailedOrder}
                error={error}
                isOpen={restaurantDetail.isOpen}
              />
            </Expand>
          </li>
        );
      });
    };

    const restaurantTypes = rTypes.map((restaurant) => {
      const { id, name } = restaurant;
      return <span key={id}>{name}</span>;
    });

    const categoriesList = data.map((product) => {
      const { id, name } = product;
      return (
        <li key={id} className='category-name' style={{ width: '100%' }}>
          <Link
            activeClass='active'
            to={`${name}${id}`}
            spy={true}
            smooth={true}
            duration={500}
            style={{ cursor: 'pointer' }}
          >
            {name}
          </Link>
        </li>
      );
    });

    const categoriesChips = data.map((product) => {
      const { id, name } = product;
      return (
        <Link
          activeClass='active'
          to={`${name}${id}`}
          spy={true}
          smooth={true}
          duration={500}
          key={id}
        >
          <p>
            <span>{name}</span>
          </p>
        </Link>
      );
    });

    const productCards = data.map((product) => {
      const { id, name } = product;
      return (
        <Element name={id + ''} key={id}>
          <div className='' id={`${name}${id}`}>
            <div>
              <span className='new-card-header'>{name}</span>
            </div>
            <div
              className='card-body'
              style={{
                padding: '0.5rem 0',
              }}
            >
              <ul className='products-list'>{productsList(product.product)}</ul>
            </div>
          </div>
        </Element>
      );
    });

    const workingHours = restaurantDetail.day || [];

    const workingHoursList = workingHours.map((workingDay) => {
      const { id, name, pivot } = workingDay;
      return (
        <li key={id}>
          <span className='time-label text-muted'>{name}</span>
          <span>{`${this.parseTime(pivot.opening_time)} - ${this.parseTime(
            pivot.closing_time
          )}`}</span>
        </li>
      );
    });

    if (
      isRestaurantDetailLoading ||
      isRestaurantProductLoading ||
      isCategoryLoading
    ) {
      return <Loader />;
    }

    return (
      <MainLayout showSearchRestaurant={true}>
        {isDirty ? (
          <div className='sticky-warn' onClick={this.onConfirm}>
            <span>{`${language(lang).TOTAL_AMOUNT_OF_ORDER} ${
              fullOrder ? `${finnishNumber(fullOrder.total)}€` || '' : ''
            }`}</span>
            <div className='text-center'>
              <button className='sticky-content-button'>
                {language(lang).CONFIRM}
              </button>
            </div>
          </div>
        ) : null}

        <AddToCart
          show={showAddModal}
          data={cartDataState}
          restaurantDetail={restaurantDetail}
          closeModal={this.closeAddToCartModal}
          addons={addons}
          isAddonLoading={isAddonLoading}
          currencySymbol={currencySymbol}
        />
        <section
          className='restaurant-banner'
          style={{
            backgroundImage: `url(${restaurantDetail.cover_image})`,
            padding: '14rem 0 1rem 0',
          }}
        >
          <div className='container'>
            {restaurantDetail.discount_rate ||
            restaurantDetail.is_delivery_free ? (
              <p className='discount-text'>
                {`${
                  restaurantDetail.discount_rate
                    ? `${restaurantDetail.discount_rate} % ${
                        language(lang).DISCOUNT
                      }`
                    : ''
                }${
                  restaurantDetail.is_delivery_free
                    ? `${
                        restaurantDetail.discount_rate
                          ? ` ${language(lang).OR}`
                          : ''
                      } ${language(lang).FREE_DELIVERY}`
                    : ''
                }`}
              </p>
            ) : null}
            <div className='row'>
              <div className='col d-flex'>
                <img
                  src={restaurantDetail.logo}
                  className='img img-thumbnail'
                  style={{ height: '100px' }}
                  alt={restaurantDetail.name}
                />
                <div style={{ marginLeft: '10px' }}>
                  <p>
                    <span style={{ marginRight: '10px' }}>
                      {restaurantDetail.name}
                    </span>
                    <Rating
                      readonly={true}
                      initialRating={
                        restaurantDetail.google_info
                          ? restaurantDetail.google_info.rating || 0
                          : 0
                      }
                      emptySymbol='fa fa-star-o'
                      fullSymbol='fa fa-star'
                      fractions={2}
                      style={{ color: 'yellow', fontSize: '16px' }}
                    />
                  </p>
                  <p>{restaurantDetail.address}</p>
                  {restaurantTypes}
                  {restaurantDetail.minimum_order_amount ? (
                    <p>{`${restaurantDetail.minimum_order_amount}${
                      restaurantDetail.currency
                        ? restaurantDetail.currency.currency_symbol
                        : ''
                    } minimum`}</p>
                  ) : null}
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className='main-content' style={{ minHeight: '55vh' }}>
          <div className='product-div'>
            <div className='container'>
              <div className='row'>
                <div className='col-lg-2 col-md-4 categories-list'>
                  <div className='restaurant-category-list'>
                    <ul className='nav flex-column'>{categoriesList}</ul>
                  </div>
                </div>
                <div className='col-lg-2 col-md-4 categories-options categories-options-new'>
                  {categoriesChips}
                </div>
                <div className='col-lg-6 col-md-8 col-sm-12'>
                  {productCards}
                </div>
                <div className='col-md-4'>
                  <h3 className='theme-color'>Restaurant Info</h3>
                  <h4>Location</h4>
                  <div>
                    <div>{restaurantDetail.address}</div>
                    <a
                      target='_blank'
                      href={`https://maps.google.com/?q=${restaurantDetail.latitude},${restaurantDetail.longitude}`}
                      rel='noopener noreferrer'
                    >
                      Get directions
                    </a>
                  </div>
                  <h4 style={{ marginTop: '12px' }}>Opening hours</h4>
                  <ul className='nav flex-column'>{workingHoursList}</ul>

                  <div style={{ marginTop: '12px' }}>
                    <span>For more info. please contact</span>
                    <a href={`tel:${restaurantDetail.phone || ''}`}>
                      {restaurantDetail.phone || ''}
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </MainLayout>
    );
  }
}

const mapStateToProps = (state) => ({
  cartData: state.cartData,
  profile: state.profile.info,
  lang: state.language.language,
  reduxOrder: state.orders.reduxOrder,
});
const mapDispatchToProps = (dispatch) => ({
  replaceCart: (index, food) => dispatch(replaceCart(index, food)),
  saveOrder: (data) => dispatch(saveOrder(data)),
  openLogin: () => dispatch(openLogin()),
  addToCart: (food, restaurant) => dispatch(addToCart(food, restaurant)),
  resetCart: () => dispatch(resetCart()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRestaurantDetails(NewRestaurantDetail));
