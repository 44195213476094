import React, { Component } from 'react';
import MainLayout from './shared/MainLayout';
import Stripe from './Stripe';
import { connect } from 'react-redux';

import { resetCart } from '../actions/carts';
import { storage } from '../utils/storage';
import { finnishNumber } from '../utils/common';
import { language } from '../constants';
import { getAvailableCreditPoints } from '../services';
import { setCreditPoint } from '../actions/profile';

class Checkout extends Component {
  state = {
    data: {},
    billingDetails: {
      email: '',
      phone: '',
      name: '',
      address: { line1: '', line2: '', city: '' },
    },
  };
  componentDidMount() {
    const { profile, orderDetails } = this.props;
    if (Object.keys(orderDetails).length <= 0) {
      this.props.history.push('/');
    } else {
      const queryState = this.props.location.state;
      let address = {
        line1: profile.address || '',
        line2: '',
        city: profile.city || '',
      };
      if (queryState.type === 'delivery') {
        address.line1 = queryState.street;
        address.city = queryState.city;
      }
      this.setState({
        data: this.props.orderDetails,
        billingDetails: {
          email: profile.email || '',
          phone: queryState.phone || '',
          name: `${profile.first_name || ''} ${profile.last_name || ''}`,
          address,
        },
      });
    }
  }

  componentDidUpdate(prevProps) {
    const { profile, orderDetails } = this.props;
    if (orderDetails !== prevProps.orderDetails) {
      this.setState({
        data: orderDetails,
      });
    }

    if (profile !== prevProps.profile) {
      this.setState({
        billingDetails: {
          email: profile.email || '',
          phone: profile.phone_no || '',
          name: `${profile.first_name || ''} ${profile.last_name || ''}`,
          address: {
            line1: profile.address || '',
            line2: '',
            city: profile.city,
          },
        },
      });
    }
  }

  onReset = () => {
    storage.emptyCart();
    this.props.resetCart();
    getAvailableCreditPoints().then((res) => {
      this.props.setCreditPoint(res.data);
    });
  };

  render() {
    const { data, billingDetails } = this.state;
    const { lang } = this.props;

    const currency = data.restaurant
      ? data.restaurant.currency
        ? data.restaurant.currency.currency_symbol
        : '€'
      : '€';

    let addOnsList;

    if (data.restaurant?.menu_type === 'advance') {
      addOnsList = (addons) => {
        addons = addons || {};
        const { total, ...payload } = addons;
        if (payload) {
          return Object.keys(payload).map((single, o) => {
            return payload[single].map((product, index) => {
              return (
                <div key={'' + o + index} style={{ paddingLeft: '8px' }}>
                  <span className='product-category-title'>{single}</span>
                  <span className='product-category-addon'>{product.name}</span>
                </div>
              );
            });
          });
        }
        return null;
      };
    } else {
      addOnsList = (addons) => {
        return addons.map((oo, ii) => {
          return (
            <tr key={ii}>
              <td style={{ paddingLeft: '4rem' }}>{oo.name}</td>
              <td className='text-right'>{oo.qty}</td>
              <td className='text-right'>{`${currency}${oo.amount}`}</td>
              <td className='text-right'>{`${currency}${oo.total}`}</td>
            </tr>
          );
        });
      };
    }

    return (
      <MainLayout showSearchRestaurant={true}>
        <section className='page-header'>
          <div className='container'>
            <h1>{language(lang).CHECKOUT_ORDER}</h1>
          </div>
        </section>
        <section className='page-header'>
          <div className='container'>
            <div className='checkout-title-header'>
              <div className='row'>
                <div className='col-sm-12'>
                  <span className='checkout-reataurant-title'>
                    {data.restaurant ? data.restaurant.name : ''}
                  </span>
                </div>
              </div>
              <div className='row'>
                <div className='col-sm-12'>
                  <span>{data.restaurant ? data.restaurant.address : ''}</span>
                </div>
              </div>
            </div>
            <table className='table table-bordered'>
              <thead>
                <tr>
                  <th
                    className='text-center text-bold'
                    style={{ width: '60%' }}
                  >
                    {language(lang).ITEM}
                  </th>
                  <th
                    className='text-center text-bold'
                    style={{ width: '10%' }}
                  >
                    {language(lang).QTY}
                  </th>
                  <th
                    className='text-center text-bold'
                    style={{ width: '15%' }}
                  >
                    {language(lang).PRICE}
                  </th>
                  <th
                    className='text-center text-bold'
                    style={{ width: '15%' }}
                  >
                    {language(lang).TOTAL}
                  </th>
                </tr>
              </thead>
              <tbody>
                {data.order_detail &&
                  data.order_detail.map((o, i) => {
                    return (
                      <React.Fragment key={i}>
                        <tr>
                          <td>
                            {o.name}
                            {o.product_detail ? (
                              <React.Fragment>
                                {addOnsList(o.product_detail)}
                              </React.Fragment>
                            ) : null}
                          </td>
                          <td className='text-right'>{o.qty}</td>
                          <td className='text-right'>
                            {`${currency}${finnishNumber(o.amount)}`}
                          </td>
                          <td className='text-right'>
                            {`${currency}${finnishNumber(o.total)}`}
                          </td>
                        </tr>
                        {o.order_detail_addon?.length > 0
                          ? addOnsList(o.order_detail_addon)
                          : null}
                      </React.Fragment>
                    );
                  })}
                <tr>
                  <td colSpan='3' className='text-right'>
                    {language(lang).SUB_TOTAL}
                  </td>
                  <td className='text-right'>{`${currency}${finnishNumber(
                    data.amount
                  )}`}</td>
                </tr>
                <tr>
                  <td colSpan='3' className='text-right'>
                    {language(lang).DELIVERY_CHARGE}
                  </td>
                  <td className='text-right'>{`${currency}${finnishNumber(
                    data.delivery_charge
                  )}`}</td>
                </tr>
                <tr>
                  <td colSpan='3' className='text-right'>
                    {language(lang).DISCOUNT_AMOUNT}
                  </td>
                  <td className='text-right'>{`${currency}${finnishNumber(
                    data.discount
                  )}`}</td>
                </tr>
                <tr>
                  <td colSpan='3' className='text-right'>
                    Credit Point
                  </td>
                  <td className='text-right'>{`${currency}${finnishNumber(
                    data.credit_point || 0
                  )}`}</td>
                </tr>
                <tr>
                  <td colSpan='3' className='text-right'>
                    {language(lang).TOTAL}
                  </td>
                  <td className='text-right'>{`${currency}${finnishNumber(
                    data.total
                  )}`}</td>
                </tr>
              </tbody>
            </table>
            <div className='row'>
              <div className='col-sm-12'>
                <Stripe
                  client_key={data.stripe_client_secret}
                  data={data}
                  billingDetails={billingDetails}
                  onResetCart={this.onReset}
                  currency={currency}
                  paytrail={data.paytrail}
                />
              </div>
            </div>
          </div>
        </section>
      </MainLayout>
    );
  }
}

const mapStateToProps = (state) => ({
  orderDetails: state.orders.detail,
  profile: state.profile.info,
  lang: state.language.language,
});
const mapDispatchToProps = (dispatch) => ({
  resetCart: () => dispatch(resetCart()),
  setCreditPoint: (data) => dispatch(setCreditPoint(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Checkout);
