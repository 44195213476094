import React from 'react';
import queryString from 'query-string';
import Swal from 'sweetalert2';
import { connect } from 'react-redux';

import MainLayout from './shared/MainLayout';
import { language } from '../constants';
import { confirmUserRegistration } from '../services';

class ConfirmRegistration extends React.Component {
  state = {
    id: this.props.match.params.id,
    token: '',
    isProcessing: false,
  };

  componentDidMount() {
    const parsed = queryString.parse(this.props.location.search);
    if (parsed.token) {
      this.setState({
        token: parsed.token,
      });
    } else {
      this.props.history.push('/');
    }
  }

  onSubmit = () => {
    if (!this.state.isProcessing) {
      this.setState({
        isProcessing: true,
      });
      confirmUserRegistration(this.state.id, this.state.token)
        .then(() => {
          Swal.fire({
            text: 'Registration successful.Please login',
            icon: 'success',
            showCancelButton: false,
            confirmButtonColor: '#ef692a',
            confirmButtonText: 'Ok',
          }).then(() => {
            this.setState({ isProcessing: false });
            this.props.history.push('/');
          });
        })
        .catch(() => {
          Swal.fire({
            title: 'Something went wrong',
            icon: 'error',
            showCancelButton: false,
            confirmButtonColor: '#ef692a',
            confirmButtonText: 'Go to Home',
          }).then((result) => {
            if (result.value) {
              this.setState({ isProcessing: false });
              this.props.history.push('/');
            }
          });
        });
    }
  };

  render() {
    const { lang } = this.props;
    const { isProcessing } = this.state;
    return (
      <MainLayout>
        <section className='page-header'>
          <div className='container'>
            <h1>{language(lang).CONFIRM_REGISTRATION}</h1>
          </div>
        </section>
        <section className='main-content' style={{ minHeight: '55vh' }}>
          <div className='container'>
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                flexDirection: 'column',
                padding: '20px 0',
              }}
            >
              <div style={{ maxWidth: '500px' }}>
                <div className='row'>
                  <span>{language(lang).CLICK_CONFIRM_BUTTON}</span>
                </div>
                <div className='row'>
                  <button
                    className='login-button'
                    onClick={this.onSubmit}
                    style={{ width: '100%', marginTop: '20px' }}
                  >
                    {language(lang).CONFIRM}
                    {isProcessing && (
                      <span
                        className='spinner-border spinner-border-sm'
                        role='status'
                        aria-hidden='true'
                      ></span>
                    )}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </section>
      </MainLayout>
    );
  }
}

const mapStateToProps = (state) => ({
  lang: state.language.language,
});
const mapDispatchToProps = (dispatch) => ({});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ConfirmRegistration);
