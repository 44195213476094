import React from "react";
import AnimatedCheckmark from "../UI/AnimatedCheckmark";

import classes from "./PostReview.module.css";

const PostReview = (props) => {
  return (
    <div className={classes["post-review"]}>
      <AnimatedCheckmark />

      <h2>Thank you for your feedback</h2>

      <button onClick={props.onClick}>Done</button>
    </div>
  );
};

export default PostReview;
