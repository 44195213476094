import Swal from 'sweetalert2';
import moment from 'moment';
import { connect } from 'react-redux';
import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { MdAdd, MdRemove, MdClose } from 'react-icons/md';
import { DatePicker, TimePicker } from 'antd';

import { language } from '../../../constants';
import { saveOrder } from '../../../actions/orders';
import { finnishNumber } from '../../../utils/common';
import { generateCart } from '../../../services/orders';
import { initiateOrder } from '../../../services/orders';
import { initiateOrderCheckout } from '../../../actions/orders';
import {
  replaceCart,
  removeFromCart,
  changeDeliveryType,
  resetCart,
} from '../../../actions/carts';

class NewCartItem extends Component {
  constructor(props) {
    super(props);
    this.inputRefs = {
      delivery_type: React.createRef(),
      phone: React.createRef(),
      street: React.createRef(),
      city: React.createRef(),
      delivery_address_details: React.createRef(),
    };
    this.state = {
      delivery_type: '',
      city: '',
      street: '',
      delivery_address_details: '',
      error: {
        delivery_type: false,
        city: false,
        street: false,
        delivery_asap_date: false,
        delivery_asap_time: false,
      },
      isProcessing: false,
      phone: '',
      orders: {},
      data: {},
      restaurantId: null,
      deliveryDate: null,
      deliverAsap: true,
      creditPoint: false,
      isTimePicker: false,
      selectedDate: null,
      selectedTime: null,
    };
  }

  componentDidMount() {
    const phone = this.props.profile.phone_no || '';
    const restaurantId = localStorage.getItem('RESTAURANT_ID');
    this.setState({
      street: this.props.profile.address,
      city: this.props.profile.city,
      phone: phone.includes('+') ? '' : phone,
      data: this.props.data,
      restaurantId: parseInt(restaurantId),
    });
    if (this.props.data.restaurant?.delivery_order !== '1') {
      this.setState({
        delivery_type: 'pick',
      });
    }

    const as = localStorage.getItem('PARSED_NEW_ORDER');
    if (as) {
      this.setState({
        orders: JSON.parse(as),
      });
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.profile !== prevProps.profile) {
      const phone = this.props.profile.phone_no || '';
      this.setState({
        street: this.props.profile.address,
        city: this.props.profile.city,
        phone: phone.includes('+') ? '' : phone,
      });
    }
  }

  onChangeDeliveryType = (e) => {
    let { error } = this.state;
    error.delivery_type = false;
    this.props.changeDeliveryType(e.target.value || '');
    this.setState({
      delivery_type: e.target.value,
      error,
    });
  };

  onChangeCity = (e) => {
    let { error } = this.state;
    error.city = false;
    this.setState({
      city: e.target.value,
      error,
    });
  };

  onChangeStreet = (e) => {
    let { error } = this.state;
    error.street = false;
    this.setState({
      street: e.target.value,
      error,
    });
  };

  onChangePhone = (e) => {
    let { error } = this.state;
    error.phone = false;
    this.setState({
      phone: e.target.value,
      error,
    });
  };

  onChangeDeliveryInfo = (e) => {
    let { error } = this.state;
    error.delivery_address_details = false;
    this.setState({
      delivery_address_details: e.target.value,
      error,
    });
  };

  parseOrder = (orderDetail) => {
    const { order_detail } = orderDetail;
    let temp = {};
    order_detail.forEach((single) => {
      const tempp = {};
      if (!single.product_detail) {
        temp[single.product_id] = {
          id: single.product_id,
          name: single.name,
          notes: single.notes,
          qty: single.qty,
          order: {},
        };
      } else {
        const { total, ...restDetail } = single.product_detail;

        Object.keys(restDetail).forEach((one) => {
          restDetail[one].forEach((hmmm) => {
            if (Object.keys(tempp).includes(hmmm.product_property_id + '')) {
              let ttttttt = [...tempp[hmmm.product_property_id]];
              ttttttt.push(hmmm.product_property_detail_id);
              tempp[hmmm.product_property_id] = ttttttt;
            } else {
              tempp[hmmm.product_property_id] = [
                hmmm.product_property_detail_id,
              ];
            }
          });
        });

        const finalOrder = {};

        Object.keys(tempp).forEach((tttt) => {
          finalOrder[tttt] = tempp[tttt];
        });
        temp[single.product_id] = {
          id: single.product_id,
          name: single.name,
          notes: single.notes,
          qty: single.qty,
          order: finalOrder,
        };
      }
    });

    return temp;
  };

  changeOrder = () => {
    const { restaurantId, data } = this.state;

    let products = [];

    data.order_detail.forEach((single) => {
      let product_property = [];
      if (single.product_detail) {
        const { total, ...properties } = single.product_detail;

        Object.keys(properties).forEach((property) => {
          const detail = properties[property];
          let payload = { product_property_id: detail[0].product_property_id };
          let product_property_detail_ids = [];
          detail.forEach((single) => {
            product_property_detail_ids.push(single.product_property_detail_id);
          });
          payload.product_property_detail_ids = product_property_detail_ids;
          product_property.push(payload);
        });
      }
      products.push({
        id: single.product_id,
        name: single.name,
        notes: single.notes,
        product_property,
        qty: single.qty,
      });
    });

    if (products.length > 0) {
      generateCart({
        contact_no: '0401234567',
        delivery_type: 'pick',
        products,
      })
        .then((res) => {
          const temp = this.parseOrder(res.data);
          const parsedRestaurantOrder = {
            [restaurantId]: temp,
          };
          const fullRestaurantOrder = {
            [restaurantId]: res.data,
          };
          localStorage.setItem(
            'PARSED_RESTAURANT_ORDER',
            JSON.stringify(parsedRestaurantOrder)
          );
          localStorage.setItem(
            'FULL_RESTAURANT_ORDER',
            JSON.stringify(fullRestaurantOrder)
          );

          localStorage.setItem('PARSED_NEW_ORDER', JSON.stringify(temp));
          localStorage.setItem('FULL_NEW_ORDER', JSON.stringify(res.data));
          this.props.saveOrder(res.data);
          this.setState({ data: res.data, orders: temp });
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      localStorage.removeItem('PARSED_RESTAURANT_ORDER');
      localStorage.removeItem('FULL_RESTAURANT_ORDER');
      localStorage.removeItem('FULL_NEW_ORDER');
      localStorage.removeItem('PARSED_NEW_ORDER');
      localStorage.removeItem('DETAILED_ORDER');
      localStorage.removeItem('RESTAURANT_ID');

      this.props.resetCart();
      this.props.emptyCart();
      this.setState({ idata: {} });
    }
  };

  onConfirm = () => {
    let {
      city,
      street,
      delivery_type,
      error,
      phone,
      deliveryDate,
      deliverAsap,
      selectedDate,
      selectedTime,
    } = this.state;

    const parsedRestaurantOrder = JSON.parse(
      localStorage.getItem('PARSED_RESTAURANT_ORDER')
    );

    const restaurantId = localStorage.getItem('RESTAURANT_ID');
    const detailedOrder = JSON.parse(localStorage.getItem('DETAILED_ORDER'));
    const tempParsedRestaurantOrder =
      parsedRestaurantOrder[parseInt(restaurantId)];
    const selectedOrders = Object.keys(tempParsedRestaurantOrder);
    let tempError = {};
    let isError = false;

    let detailKeys = Object.keys(detailedOrder);
    let tempDetailedOrder = { ...detailedOrder };

    detailKeys = Object.keys(tempDetailedOrder);

    detailKeys.forEach((tempSingleOrder) => {
      const singleOrder = parseInt(tempSingleOrder);
      let orderError = [...(tempError[singleOrder] || [])];
      tempError[singleOrder] = [...(tempError[singleOrder] || [])];
      if (selectedOrders.includes(singleOrder + '')) {
        Object.keys(tempDetailedOrder[singleOrder]).forEach((singleDetail) => {
          const { min, selected, type } = tempDetailedOrder[singleOrder][
            singleDetail
          ];
          const meal = tempDetailedOrder[singleOrder].meal;

          if (type !== 'meal') {
            if (selected < min) {
              isError = true;
              if (!orderError.includes(parseInt(singleDetail))) {
                orderError.push(parseInt(singleDetail));
              }
              tempError[singleOrder] = orderError;
            }
          } else {
            if (meal) {
              const { id, mealId } = meal;
              const mealOrder = tempParsedRestaurantOrder[singleOrder].order;
              if (mealOrder[id] && mealOrder[id].includes(mealId)) {
                if (selected < min) {
                  isError = true;
                  if (!orderError.includes(parseInt(singleDetail))) {
                    orderError.push(parseInt(singleDetail));
                  }
                  tempError[singleOrder] = orderError;
                }
              }
            }
          }
        });
      }
    });

    Object.keys(tempError).forEach((key) => {
      if (!(tempError[key] && tempError[key].length > 0)) {
        delete tempError[key];
      }
    });

    if (isError) {
      this.props.history.push({
        pathname: `/restaurants/${restaurantId}`,
        state: { error: tempError },
      });
      return;
    }

    if (!deliverAsap) {
      error.delivery_asap_date = !selectedDate;
      error.delivery_asap_time = !selectedTime;
      this.setState({ error });

      if (!selectedDate || !selectedTime) return;
    }

    if (delivery_type) {
      if (delivery_type === 'delivery') {
        if (city && street && phone) {
          Swal.fire({
            title: 'Confirm Address',
            text: `City: ${city} & Street: ${street}`,
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#ef692a',
            confirmButtonText: 'Confirm',
          }).then((result) => {
            if (result.value) {
              this.submitAction();
            }
          });
        } else {
          if (!city) {
            this.inputRefs.city.current.focus();
          } else if (!street) {
            this.inputRefs.street.current.focus();
          } else if (!phone || phone.length !== 10) {
            this.inputRefs.phone.current.focus();
          }
          error.city = !city;
          error.street = !street;
          error.phone = !phone || phone.length !== 10;
          this.setState({ error });
        }
      } else if (!phone || phone.length !== 10) {
        this.inputRefs.phone.current.focus();
        error.phone = true;
        this.setState({ error });
      } else {
        this.submitAction();
      }
    } else {
      error.delivery_type = true;
      error.phone = !phone || phone.length !== 10;
      if (!phone || phone.length !== 10) {
        this.inputRefs.phone.current.focus();
      } else {
        this.inputRefs.delivery_type.current.focus();
      }
      this.setState({ error });
    }
  };

  submitAction = () => {
    this.setState({
      isProcessing: true,
    });

    let tempProducts = { ...this.state.orders };

    let products = [];

    Object.keys(tempProducts).forEach((single) => {
      if (tempProducts[single].qty) {
        let order = { ...tempProducts[single] };
        if (order.order) {
          if (!order.product_property) {
            order.product_property = Object.keys(order.order).map(
              (oneOrder) => ({
                product_property_id: parseInt(oneOrder),
                product_property_detail_ids: order.order[oneOrder],
              })
            );
          }
          delete order.order;
        }
        if (order.product_property && !order.product_property.length) {
          delete order.product_property;
        }
        products.push(order);
      }
    });

    let temp = {
      delivery_type: this.state.delivery_type,
      products,
      contact_no: this.state.phone,
      use_credit_point: this.state.creditPoint ? 1 : 0,
    };

    if (this.state.delivery_type === 'delivery') {
      temp.delivery_details = [
        {
          delivery_address: this.state.street,
          city: this.state.city,
          delivery_address_details: this.state.delivery_address_details,
        },
      ];
    }

    if (!this.state.deliverAsap) {
      temp.pre_order = 1;
      moment(this.state.selectedDate).format('YYYY-MM-DD');

      temp.scheduled_date = `${moment(this.state.selectedDate).format(
        'YYYY-MM-DD'
      )} ${moment(this.state.selectedTime).format('HH:mm')}`;
    } else {
      temp.pre_order = 0;
    }
    initiateOrder(temp)
      .then((res) => {
        this.setState({
          isProcessing: false,
        });
        this.props.initiateOrderCheckout(res.data);
        this.props.history.push({
          pathname: '/checkout',
          state: {
            city:
              this.state.delivery_type === 'delivery' ? this.state.city : '',
            street:
              this.state.delivery_type === 'delivery' ? this.state.street : '',
            type: this.state.delivery_type,
            phone: this.state.phone,
            delivery_address_details:
              this.state.delivery_type === 'delivery'
                ? this.state.delivery_address_details
                : '',
          },
        });
      })
      .catch((err) => {
        this.setState({
          isProcessing: false,
        });
        if (err.response && err.response.status === 400) {
          if (err.response.data.message.includes('close')) {
            Swal.fire({
              text: 'Restaurant is closed at the moment. You can shcedule your order for later.',
              icon: 'warning',
              showCancelButton: false,
              confirmButtonColor: '#ef692a',
              confirmButtonText: 'Okay',
            });
          } else {
            Swal.fire({
              text: err.response.data.message,
              icon: 'error',
              showCancelButton: false,
              confirmButtonColor: '#ef692a',
              confirmButtonText: 'Okay',
            });
          }
        } else if (err.response && err.response.status === 422) {
          let msgKeys = Object.keys(err.response.data.message);
          Swal.fire({
            text: err.response.data.message[msgKeys[0]],
            icon: 'error',
            showCancelButton: false,
            confirmButtonColor: '#ef692a',
            confirmButtonText: 'Okay',
          });
        } else {
          Swal.fire({
            text: 'Something went wrong',
            icon: 'error',
            showCancelButton: false,
            confirmButtonColor: '#ef692a',
            confirmButtonText: 'Okay',
          });
        }
      });
  };

  increaseQty = (i, o) => {
    let data = { ...this.state.data };
    let order_detail = [...data.order_detail];
    let qty = i.qty;
    qty = qty + 1;
    let item = { ...i };
    item.qty = qty;
    order_detail[o] = item;
    data.order_detail = order_detail;
    this.setState({ data }, () => {
      this.changeOrder();
    });
  };

  decreaseQty = (i, o) => {
    let data = { ...this.state.data };
    let order_detail = [...data.order_detail];
    let qty = i.qty;
    qty = qty - 1;
    let item = { ...i };
    if (qty > 0) {
      item.qty = qty;
      order_detail[o] = item;
      data.order_detail = order_detail;
    } else {
      order_detail.splice(o, 1);
      data.order_detail = order_detail;
    }
    this.setState({ data }, () => {
      this.changeOrder();
    });
  };

  removeItem = (index) => {
    let data = { ...this.state.data };
    let order_detail = [...data.order_detail];
    order_detail.splice(index, 1);
    data.order_detail = order_detail;

    this.setState({ data }, () => {
      this.changeOrder();
    });
  };

  goToRestaurant = () => {
    this.props.history.push(`/restaurants/${this.props.data.restaurant.id}`);
  };

  changeDeliveryDate = (newDate) => {
    this.setState({
      deliveryDate: newDate,
      error: { ...this.state.error, delivery_asap: false },
    });
  };

  changeDeliverAsap = () => {
    this.setState({
      deliverAsap: !this.state.deliverAsap,
      error: { ...this.state.error, delivery_asap: false },
      deliveryDate: null,
    });
  };

  changeCreditPoint = () => {
    this.setState({
      creditPoint: !this.state.creditPoint,
    });
  };

  onDateChange = (e) => {
    this.setState({ selectedDate: e, isTimePicker: true });
  };

  onTimeChange = (e) => {
    this.setState({ selectedTime: e, isTimePicker: false });
  };

  onOpenChange = (status) => {
    this.setState({ isTimePicker: status });
  };

  render() {
    const { lang } = this.props;
    const {
      data,
      city,
      delivery_type,
      error,
      street,
      isProcessing,
      phone,
      delivery_address_details,
      deliveryDate,
      deliverAsap,
      creditPoint,
    } = this.state;

    const currency = data.restaurant
      ? data.restaurant.currency
        ? data.restaurant.currency.currency_symbol
        : '€'
      : '€';

    const subMenuItem = (items) => {
      return items.map((single) => {
        return (
          <div
            className='row'
            key={single.product_property_detail_id + single.name}
          >
            <div className='col-sm-8'>
              <div className='product-category-addon'>{single.name}</div>
            </div>
            <div
              className='col-sm-4 product-category-addon text-right'
              style={{ paddingRight: '10px' }}
            ></div>
          </div>
        );
      });
    };

    const addOnsList = (addons) => {
      addons = addons || {};
      const { total, ...payload } = addons;
      if (payload) {
        return Object.keys(payload).map((single) => {
          return (
            <div key={single} style={{ marginLeft: '8px' }}>
              <div className='product-category-title'>{single}</div>
              {subMenuItem(payload[single])}
            </div>
          );
        });
      }
      return null;
    };

    const showItems = data.order_detail?.map((item, o) => {
      const { id, name, price, qty, total, product_detail } = item;
      return (
        <div
          className={`cart-item-row ${
            o + 1 === data.order_detail.length ? '' : 'dotted-border-bottom'
          }`}
          key={id + name}
        >
          <div className='row' key={id}>
            <div className='col-10'>
              <div className='d-flex'>
                <div style={{ flex: 1 }}>
                  <span className='cart-item'>{`${name}`}</span>
                  <span className='cart-qty'>{`${qty}x`}</span>
                </div>
                <div className='btn-group'>
                  <button className='btn mulas-btn'>
                    <MdRemove
                      className='mulas-btn-text'
                      title={`Remove 1 ${name} from cart.`}
                      onClick={() => {
                        this.decreaseQty(item, o);
                      }}
                    />
                  </button>
                  <button className='btn mulas-secondary-btn'>
                    <MdClose
                      className='mulas-secondary-btn-text'
                      title={`Remove ${name} from cart.`}
                      onClick={() => {
                        this.removeItem(o);
                      }}
                    />
                  </button>
                  <button className='btn mulas-btn'>
                    <MdAdd
                      className='mulas-btn-text'
                      title={`Add 1 ${name} in cart.`}
                      onClick={() => {
                        this.increaseQty(item, o);
                      }}
                    />
                  </button>
                </div>
              </div>
            </div>
            <div
              className={`col-2 text-right ow`}
              title={`Item Price (${price}) * Item Qty (${qty})`}
            >
              {finnishNumber(currency + total.toFixed(2))}
            </div>
          </div>
          {addOnsList(product_detail)}
        </div>
      );
    });

    return (
      <React.Fragment>
        <div className='row mt-3'>
          <div className='col-sm-12 col-md-6' style={{ marginTop: '4px' }}>
            <span className='cart-label'>{language(lang).DELIVERY_TYPE}</span>
            <select
              className={`form-control ${
                error.delivery_type ? 'is-invalid' : ''
              }`}
              value={delivery_type}
              onChange={this.onChangeDeliveryType}
              ref={this.inputRefs.delivery_type}
            >
              <option value='' className='new-custom-select'></option>
              <option value='pick' className='new-custom-select'>
                {language(lang).PICKUP}
              </option>
              {data.restaurant?.delivery_order === '1' && (
                <option value='delivery' className='new-custom-select'>
                  {language(lang).RESTAURANT_DELIVERY}
                </option>
              )}
            </select>
            {error.delivery_type && (
              <div className='invalid-feedback'>
                {language(lang).PLEASE_SELECT_DELIVERY_TYPE}
              </div>
            )}
          </div>
          <div className='col-sm-12 col-md-6' style={{ marginTop: '8px' }}>
            <span className='cart-label'>{language(lang).PHONE_NUMBER}</span>
            <input
              className={`form-control ${error.phone ? 'is-invalid' : ''}`}
              value={phone}
              onChange={this.onChangePhone}
              type='number'
              min={0}
              placeholder='eg. 0401234567'
              ref={this.inputRefs.phone}
            />
            {error.phone && (
              <div className='invalid-feedback'>
                Phone number must be 10 digits
              </div>
            )}
          </div>
          <div className='col-sm-12 col-md-6' style={{ marginTop: '8px' }}>
            <span className='cart-label'>Schedule Order</span>
            <br />
            <label className='switch'>
              <input
                type='checkbox'
                checked={!deliverAsap}
                onChange={this.changeDeliverAsap}
              />
              <span className='slider round'></span>
            </label>
          </div>
          <div className='col-sm-12 col-md-6' style={{ marginTop: '8px' }}>
            <div className='cart-label'>Wished Time</div>
            <div className='row'>
              <div className='col-6'>
                <DatePicker onChange={this.onDateChange} disabled={!!deliverAsap}/>
                {error.delivery_asap_date && (
                  <div
                    className='invalid-feedback'
                    style={{ display: 'block' }}
                  >
                    Please select valid date
                  </div>
                )}
              </div>
              <div className='col-6'>
                <TimePicker
                  open={this.state.isTimePicker}
                  onChange={this.onTimeChange}
                  onOpenChange={this.onOpenChange}
                  disabled={!!deliverAsap}
                />
                {error.delivery_asap_time && (
                  <div
                    className='invalid-feedback'
                    style={{ display: 'block' }}
                  >
                    Please select valid time
                  </div>
                )}
              </div>
            </div>
          </div>

          <div className='col-sm-12 col-md-6' style={{ marginTop: '8px' }}>
            <span className='cart-label'>Use Credit Point</span>
            <br />
            <label className='switch'>
              <input
                type='checkbox'
                checked={creditPoint}
                onChange={this.changeCreditPoint}
              />
              <span className='slider round'></span>
            </label>
          </div>
          {delivery_type === 'delivery' && (
            <React.Fragment>
              <div className='col-sm-12 col-md-6' style={{ marginTop: '8px' }}>
                <span className='cart-label'>{language(lang).STREET}</span>
                <input
                  className={`form-control ${error.street ? 'is-invalid' : ''}`}
                  value={street}
                  onChange={this.onChangeStreet}
                  ref={this.inputRefs.street}
                />
                {error.street && (
                  <div className='invalid-feedback'>
                    {language(lang).STREET_REQUIRED}
                  </div>
                )}
              </div>
              <div className='col-sm-12 col-md-6' style={{ marginTop: '8px' }}>
                <span className='cart-label'>{language(lang).CITY}</span>
                <input
                  className={`form-control ${error.city ? 'is-invalid' : ''}`}
                  value={city}
                  onChange={this.onChangeCity}
                  ref={this.inputRefs.city}
                />
                {error.city && (
                  <div className='invalid-feedback'>
                    {language(lang).CITY_REQUIRED}
                  </div>
                )}
              </div>
              <div className='col-sm-12 col-md-6' style={{ marginTop: '8px' }}>
                <span className='cart-label'>
                  {language(lang).DELIVERY_DETAILS}
                </span>
                <input
                  className={`form-control ${
                    error.delivery_address_details ? 'is-invalid' : ''
                  }`}
                  value={delivery_address_details}
                  onChange={this.onChangeDeliveryInfo}
                  placeholder={language(lang).FLOOR_ROOM}
                  ref={this.inputRefs.delivery_address_details}
                />
              </div>
            </React.Fragment>
          )}
        </div>

        <div className='item-block'>{showItems}</div>
        <div className='row'>
          <div className='col-sm-9'>
            <span className='text-muted'>{language(lang).SUB_TOTAL}</span>
          </div>
          <div className='col-sm-3 text-right'>
            {finnishNumber(currency + data.amount)}
          </div>
        </div>

        <div className='row'>
          <div className='col-sm-9'>
            <span className='text-muted'>{language(lang).DELIVERY_CHARGE}</span>
          </div>
          <div className='col-sm-3 text-right' title='To Be Decided'>
            TBD
          </div>
        </div>

        <div className='row'>
          <div className='col-sm-9'>
            <span className='text-muted'>{language(lang).DISCOUNT_AMOUNT}</span>
          </div>
          <div className='col-sm-3 text-right' title='Discount Amount'>
            {finnishNumber(currency + data.discount)}
          </div>
        </div>

        <div className='row mb-3'>
          <div className='col-sm-9'>
            <span className='text-muted'>{language(lang).TOTAL}</span>
          </div>
          <div className='col-sm-3 text-right'>
            {finnishNumber(currency + data.total)}
          </div>
        </div>

        <div className='row'>
          <div className='col-sm-12 text-center'>
            <button
              className='secondary-button auto-button'
              onClick={this.goToRestaurant}
            >
              {language(lang).CHANGE_ORDER}
            </button>
            <button
              className='checkout-button auto-button'
              onClick={this.onConfirm}
            >
              {language(lang).CONFIRM_CHECKOUT}
              {isProcessing && (
                <span
                  className='spinner-border spinner-border-sm'
                  role='status'
                  aria-hidden='true'
                ></span>
              )}
            </button>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  cartData: state.cartData,
  profile: state.profile.info,
  lang: state.language.language,
});
const mapDispatchToProps = (dispatch) => ({
  replaceCart: (index, food) => dispatch(replaceCart(index, food)),
  removeFromCart: (index) => dispatch(removeFromCart(index)),
  initiateOrderCheckout: (data) => dispatch(initiateOrderCheckout(data)),
  changeDeliveryType: (data) => dispatch(changeDeliveryType(data)),
  saveOrder: (data) => dispatch(saveOrder(data)),
  resetCart: () => dispatch(resetCart()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(NewCartItem));
