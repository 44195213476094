import React, { useRef } from "react";
import { Fragment } from "react";

import Button from "../UI/Button";

const RestaurantReview = (props) => {
  const messageRef = useRef();

  const formSubmitHandler = (event) => {
    event.preventDefault();

    props.onSubmit(messageRef.current.value);
  };

  return (
    <Fragment>
      <h2>Awesome!!!</h2>

      <h5>(Your feedback for {props.restaurant})</h5>

      <form onSubmit={formSubmitHandler}>
        <div>
          <textarea ref={messageRef}></textarea>
        </div>
        <Button onClick={props.onNext} isProcessing={props.isProcessing}>
          Send
        </Button>
      </form>
    </Fragment>
  );
};

export default RestaurantReview;
