import React, { Component } from 'react';
import { fetchTopRestaurants, fetchNearbyRestaurants, fetchSpecialOffers } from '../services';

export function withDashboard(WrappedComponent) {
  return class Dashboard extends Component {
    state = {
      isFetchingTopRestaurants: false,
      isFetchingNearbyRestaurants: false,
      isFetchingSpecialOffers: false,
      topRestaurants: [],
      nearbyRestaurants: [],
      specialOffers: []
    };

    getTopRestaurants = () => {
      this.setState({
        isFetchingTopRestaurants: true,
      });

      return fetchTopRestaurants()
        .then((res) => {
          this.setState({
            isFetchingTopRestaurants: false,
          });
          if (res.status === 200) {
            this.setState({
              topRestaurants: res.data.data,
            });
          }
        })
        .catch((err) => {
          this.setState({
            isFetchingTopRestaurants: false,
          });
        });
    };

    getNearbyRestaurants = () => {
      this.setState({
        isFetchingNearbyRestaurants: true,
      });

      return fetchNearbyRestaurants()
        .then((res) => {
          this.setState({
            isFetchingNearbyRestaurants: false,
          });
          if (res.status === 200) {
            this.setState({
              nearbyRestaurants: res.data,
            });
          }
        })
        .catch((err) => {
          this.setState({
            isFetchingNearbyRestaurants: false,
          });
        });
    };

    getSpecialOffers = () => {
      this.setState({
        isFetchingSpecialOffers: true,
      });

      return fetchSpecialOffers()
        .then((res) => {
          this.setState({
            isFetchingSpecialOffers: false,
          });
          if (res.status === 200) {
            this.setState({
              specialOffers: res.data.data,
            });
          }
        })
        .catch((err) => {
          this.setState({
            isFetchingSpecialOffers: false,
          });
        });
    };

    render() {
      const {
        isFetchingNearbyRestaurants,
        isFetchingTopRestaurants,
        nearbyRestaurants,
        topRestaurants,
        specialOffers,
        isFetchingSpecialOffers
      } = this.state;
      return (
        <WrappedComponent
          getNearbyRestaurants={this.getNearbyRestaurants}
          getTopRestaurants={this.getTopRestaurants}
          getSpecialOffers={this.getSpecialOffers}
          isFetchingNearbyRestaurants={isFetchingNearbyRestaurants}
          isFetchingTopRestaurants={isFetchingTopRestaurants}
          nearbyRestaurants={nearbyRestaurants}
          topRestaurants={topRestaurants}
          specialOffers={specialOffers}
          isFetchingSpecialOffers={isFetchingSpecialOffers}
          {...this.props}
        />
      );
    }
  };
}
