import { reducerConstants } from '../constants';

export function initiateOrderCheckout(data) {
  return (dispatch) => {
    dispatch({
      type: reducerConstants.INITIATE_ORDER,
      payload: data,
    });
  };
}

export function resetOrder() {
  return (dispatch) => {
    dispatch({
      type: reducerConstants.RESET_ORDER,
    });
  };
}

export function saveOrder(data) {
  return (dispatch) => {
    dispatch({
      type: reducerConstants.SAVE_ORDER,
      payload: data,
    });
  };
}

export function setCurrentOrder(data) {
  return (dispatch) => {
    dispatch({
      type: reducerConstants.CURRENT_ORDER,
      payload: data,
    });
  };
}
