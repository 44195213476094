import { storageConstants } from '../constants/storage';

const getToken = () => {
  return localStorage.getItem(storageConstants.TOKEN);
};

const setToken = (key, value) => {
  return localStorage.setItem(key, value);
};

const logout = () => {
  return localStorage.clear();
};

const emptyCart = () => {
  localStorage.removeItem('cart');
};

const getLanguage = () => {
  return localStorage.getItem(storageConstants.LANGUAGE);
};

const setLanguage = (value) => {
  return localStorage.setItem(storageConstants.LANGUAGE, value);
};

export const storage = {
  getToken,
  setToken,
  logout,
  emptyCart,
  getLanguage,
  setLanguage,
};
