import { combineReducers } from 'redux';

import cartData from './carts';
import profile from './profile';
import orders from './orders';
import language from './language';
import creditPoint from './creditPoint';

const allReducers = combineReducers({
  cartData,
  profile,
  orders,
  language,
  creditPoint
});

export default allReducers;
