import React, { Component } from 'react';
import { Modal } from 'react-bootstrap';
import ImageUploader from 'react-images-upload';
import Swal from 'sweetalert2';
import PhoneInput from 'react-phone-number-input';
import { connect } from 'react-redux';

import { createRestaurant } from '../../services/auth';
import { getCurrencies } from '../../services/common';
import { language } from '../../constants';

class RestaurantRegister extends Component {
  constructor(props) {
    super(props);
    this.state = { pictures: [] };
    this.onDrop = this.onDrop.bind(this);
    this.state = {
      data: { ...this.data },
      error: { ...this.data },
      currencies: [],
      isProcessig: false,
      pictures: [],
    };
  }

  componentDidMount() {
    getCurrencies().then((res) => {
      if (res.status === 200) {
        this.setState({
          currencies: res.data.data,
        });
      }
    });
  }

  data = {
    name: '',
    contact_person: '',
    email: '',
    address: '',
    currency_id: '',
    logo: '',
    phone_no: '',
    mobile_no: '',
  };

  onDrop(picture) {
    this.setState({
      pictures: this.state.pictures.concat(picture),
    });
  }

  onChange = (e) => {
    const { name, value } = e.target;
    let { data, error } = this.state;
    data[name] = value;
    error[name] = '';
    this.setState({ data, error });
  };

  onSubmit = () => {
    if (!this.state.isProcessig) {
      const { data, pictures } = this.state;
      this.setState({
        isProcessig: true,
      });
      let formData = new FormData();
      Object.keys(data).forEach((single) => {
        formData.append(single, data[single]);
      });
      if (pictures.length > 0) {
        formData.append('logo', pictures[0]);
      }
      createRestaurant(formData)
        .then((res) => {
          Swal.fire({
            text: res.data.message,
            icon: 'success',
            showCancelButton: false,
            confirmButtonColor: '#ef692a',
            confirmButtonText: 'Ok',
          }).then(() => {
            this.setState({
              isProcessig: false,
              data: { ...this.data },
              error: { ...this.data },
            });
            this.props.closeModal();
          });
        })
        .catch((err) => {
          if (err.response.status === 422) {
            this.setState({
              error: err.response.data.message,
              isProcessig: false,
            });
          }
        });
    }
  };

  onMobileNumberChange = (value) => {
    let { data, error } = this.state;
    error['mobile_no'] = '';
    data['mobile_no'] = value;
    this.setState({
      data,
      error,
    });
  };

  render() {
    const { show, closeModal, lang } = this.props;
    const { data, error, currencies, isProcessig } = this.state;

    const currenciesList = currencies.map((single) => {
      const { id, currency_code, currency_symbol } = single;
      return (
        <option key={id} value={id}>
          {`${currency_code} (${currency_symbol})`}
        </option>
      );
    });
    return (
      <Modal show={show} onHide={closeModal}>
        <Modal.Header closeButton>
          <Modal.Title>{language(lang).REGISTER_RESTAURANT}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <input
            name='name'
            className={`form-control ${
              error.name ? 'is-invalid' : 'auth-input'
            }`}
            placeholder={language(lang).ENTER_RESTAURANT_NAME}
            value={data.name}
            onChange={this.onChange}
          />
          {error.name && (
            <div className='invalid-feedback auth-input'>{error.name}</div>
          )}
          <input
            name='contact_person'
            className={`form-control ${
              error.contact_person ? 'is-invalid' : 'auth-input'
            }`}
            placeholder={language(lang).ENTER_CONTACT_NAME}
            value={data.contact_person}
            onChange={this.onChange}
          />
          {error.contact_person && (
            <div className='invalid-feedback auth-input'>
              {error.contact_person}
            </div>
          )}
          <input
            name='email'
            className={`form-control ${
              error.email ? 'is-invalid' : 'auth-input'
            }`}
            placeholder={language(lang).ENTER_YOUR_EMAIL_ADDRESS}
            value={data.email}
            onChange={this.onChange}
          />
          {error.email && (
            <div className='invalid-feedback auth-input'>{error.email}</div>
          )}
          <input
            name='address'
            className={`form-control ${
              error.address ? 'is-invalid' : 'auth-input'
            }`}
            placeholder={language(lang).ENTER_YOUR_ADDRESS}
            value={data.address}
            onChange={this.onChange}
          />
          {error.address && (
            <div className='invalid-feedback auth-input'>{error.address}</div>
          )}
          <input
            name='phone_no'
            className={`form-control ${
              error.phone_no ? 'is-invalid' : 'auth-input'
            }`}
            placeholder={language(lang).ENTER_YOUR_PHONE_NUMBER}
            value={data.phone_no}
            onChange={this.onChange}
            type='number'
            min={0}
          />
          {error.phone_no && (
            <div className='invalid-feedback auth-input'>{error.phone_no}</div>
          )}
          <PhoneInput
            name='mobile_no'
            onChange={this.onMobileNumberChange}
            placeholder={language(lang).ENTER_YOUR_PHONE_NUMBER}
            defaultCountry='FI'
            value={data.mobile_no}
          />
          {error.mobile_no && (
            <div className='invalid-feedback auth-input'>{error.mobile_no}</div>
          )}
          <select
            className={`form-control ${
              error.currency_id ? 'is-invalid' : 'auth-input'
            }`}
            name='currency_id'
            value={data.currency_id}
            onChange={this.onChange}
          >
            <option value=''>{language(lang).SELECT_CURRENCY}</option>
            {currenciesList}
          </select>
          {error.currency_id && (
            <div className='invalid-feedback auth-input'>
              {error.currency_id}
            </div>
          )}
          <ImageUploader
            withIcon={true}
            buttonText={language(lang).CHOOSE_IMAGE}
            onChange={this.onDrop}
            imgExtension={['.jpg', '.png', '.jpeg']}
            maxFileSize={5242880}
            withPreview={true}
            label={language(lang).SELECT_RESTAURANT_LOGO}
          />
          <button className='signin-button' onClick={this.onSubmit}>
            {language(lang).REGISTER_IN} <strong>Mulas</strong>
            {isProcessig && (
              <span
                className='spinner-border spinner-border-sm'
                role='status'
                aria-hidden='true'
              ></span>
            )}
          </button>
        </Modal.Body>
      </Modal>
    );
  }
}

const mapStateToProps = (state) => ({
  lang: state.language.language,
});
const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(RestaurantRegister);
