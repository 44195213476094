import Swal from 'sweetalert2';
import { connect } from 'react-redux';
import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { MdAdd, MdRemove, MdClose } from 'react-icons/md';

import { language } from '../../../constants';
import { finnishNumber } from '../../../utils/common';
import { initiateOrder } from '../../../services/orders';
import { initiateOrderCheckout } from '../../../actions/orders';
import {
  replaceCart,
  removeFromCart,
  changeDeliveryType,
} from '../../../actions/carts';

class CartItems extends Component {
  state = {
    delivery_type: '',
    city: '',
    street: '',
    delivery_address_details: '',
    error: {
      delivery_type: false,
      city: false,
      street: false,
    },
    isProcessing: false,
    phone: '',
  };

  componentDidMount() {
    const phone = this.props.profile.phone_no || '';
    this.setState({
      street: this.props.profile.address,
      city: this.props.profile.city,
      phone: phone.includes('+') ? '' : phone,
    });
    if (this.props.data.restaurantInfo?.delivery_order !== '1') {
      this.setState({
        delivery_type: 'pick',
      });
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.profile !== prevProps.profile) {
      const phone = this.props.profile.phone_no || '';
      this.setState({
        street: this.props.profile.address,
        city: this.props.profile.city,
        phone: phone.includes('+') ? '' : phone,
      });
    }
  }

  onChangeDeliveryType = (e) => {
    let { error } = this.state;
    error.delivery_type = false;
    this.props.changeDeliveryType(e.target.value || '');
    this.setState({
      delivery_type: e.target.value,
      error,
    });
  };

  onChangeCity = (e) => {
    let { error } = this.state;
    error.city = false;
    this.setState({
      city: e.target.value,
      error,
    });
  };

  onChangeStreet = (e) => {
    let { error } = this.state;
    error.street = false;
    this.setState({
      street: e.target.value,
      error,
    });
  };

  onChangePhone = (e) => {
    let { error } = this.state;
    error.phone = false;
    this.setState({
      phone: e.target.value,
      error,
    });
  };

  onChangeDeliveryInfo = (e) => {
    let { error } = this.state;
    error.delivery_address_details = false;
    this.setState({
      delivery_address_details: e.target.value,
      error,
    });
  };

  onConfirm = () => {
    let { city, street, delivery_type, error, phone } = this.state;
    if (delivery_type) {
      if (delivery_type === 'delivery') {
        if (city && street && phone) {
          Swal.fire({
            title: 'Confirm Address',
            text: `City: ${city} & Street: ${street}`,
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#ef692a',
            confirmButtonText: 'Confirm',
          }).then((result) => {
            if (result.value) {
              this.submitAction();
            }
          });
        } else {
          error.city = !city;
          error.street = !street;
          error.phone = !phone;
          this.setState({ error });
        }
      } else if (!phone) {
        error.phone = true;
        this.setState({ error });
      } else {
        this.submitAction();
      }
    } else {
      error.delivery_type = true;
      error.phone = !phone;
      this.setState({ error });
    }
  };

  submitAction = () => {
    this.setState({
      isProcessing: true,
    });
    let temp = {
      delivery_type: this.state.delivery_type,
      products: this.props.data.items,
      contact_no: this.state.phone,
    };
    if (this.state.delivery_type === 'delivery') {
      temp.delivery_details = [
        {
          delivery_address: this.state.street,
          city: this.state.city,
          delivery_address_details: this.state.delivery_address_details,
        },
      ];
    }
    initiateOrder(temp)
      .then((res) => {
        this.setState({
          isProcessing: false,
        });
        this.props.initiateOrderCheckout(res.data);
        this.props.history.push({
          pathname: '/checkout',
          state: {
            city:
              this.state.delivery_type === 'delivery' ? this.state.city : '',
            street:
              this.state.delivery_type === 'delivery' ? this.state.street : '',
            type: this.state.delivery_type,
            phone: this.state.phone,
            delivery_address_details:
              this.state.delivery_type === 'delivery'
                ? this.state.delivery_address_details
                : '',
          },
        });
      })
      .catch((err) => {
        this.setState({
          isProcessing: false,
        });
        if (err.response.status === 400) {
          Swal.fire({
            text: err.response.data.message,
            icon: 'error',
            showCancelButton: false,
            confirmButtonColor: '#ef692a',
            confirmButtonText: 'Okay',
          });
        } else if (err.response.status === 422) {
          let msgKeys = Object.keys(err.response.data.message);
          Swal.fire({
            text: err.response.data.message[msgKeys[0]],
            icon: 'error',
            showCancelButton: false,
            confirmButtonColor: '#ef692a',
            confirmButtonText: 'Okay',
          });
        }
      });
  };

  increaseQty = (i, o) => {
    let qty = o.qty + 1;
    o['qty'] = qty;
    this.props.replaceCart(i, o);
  };

  decreaseQty = (i, o) => {
    let qty = o.qty - 1;
    if (qty < 1) qty = 1;
    o['qty'] = qty;
    this.props.replaceCart(i, o);
  };

  removeItem = (index) => {
    this.props.removeFromCart(index);
  };

  removeAddon = (itemIndex, addonIndex, item) => {
    let add_on = [...item.add_on];
    add_on.splice(addonIndex, 1);
    item.add_on = add_on;
    this.props.replaceCart(itemIndex, item);
  };

  increaseAddon = (index, item, itemIndex) => {
    let temp = { ...item };
    let tempAddon = [...temp.add_on];
    tempAddon[index].qty += 1;
    temp.add_on = tempAddon;
    this.props.replaceCart(itemIndex, temp);
  };

  decreaseAddon = (index, item, itemIndex) => {
    let temp = { ...item };
    let tempAddon = [...temp.add_on];
    tempAddon[index].qty -= 1;
    if (!tempAddon[index].qty) {
      tempAddon.splice(index, 1);
    }
    temp.add_on = tempAddon;
    this.props.replaceCart(itemIndex, temp);
  };

  render() {
    const { data, lang } = this.props;
    const {
      city,
      delivery_type,
      error,
      street,
      isProcessing,
      phone,
      delivery_address_details,
    } = this.state;

    const currency = data.restaurantInfo?.currency || '';

    const addOnsList = (addOns, quantity, index, item) => {
      return addOns.map((addOn, o) => {
        const { id, name, price, qty } = addOn;
        return (
          <div className='row' key={id}>
            <div className='col-sm-6 cart-addon-row big-screen'>
              <div className='d-flex' style={{ paddingLeft: '10px' }}>
                <div style={{ flex: 1 }}>
                  <span className='cart-item'>{name}</span>
                </div>

                <MdClose
                  className='cart-remove-action'
                  title={`Remove ${name} from cart.`}
                  onClick={() => {
                    this.removeAddon(index, o, item);
                  }}
                />
              </div>
            </div>
            <div
              className='col-sm-5 cart-addon-row small-screen'
              style={{ paddingRight: '10px' }}
            >
              <div className='d-flex' style={{ paddingLeft: '10px' }}>
                <div style={{ flex: 1 }}>
                  <span className='cart-item'>{name}</span>
                </div>

                <MdClose
                  className='cart-remove-action'
                  title={`Remove ${name} from cart.`}
                  onClick={() => {
                    this.removeAddon(index, o, item);
                  }}
                />
              </div>
            </div>
            <div
              className={`col-sm-3 ${
                window.orientation !== undefined ? 'text-center' : 'text-left'
              }`}
            >
              <MdRemove
                className='cart-action'
                title={`Remove 1 ${name} from cart.`}
                onClick={() => this.decreaseAddon(o, item, index)}
              />
              <span className='cart-qty cart-qty-old'>{`${qty}x`}</span>
              <MdAdd
                className='cart-action'
                title={`Add 1 ${name} in cart.`}
                onClick={() => this.increaseAddon(o, item, index)}
              />
            </div>
            <div
              className={`col-sm-3 ${
                window.orientation !== undefined ? 'text-center' : 'text-right'
              }`}
              title={`Addon price(${price}) * Addon qty(${qty}) * Item Qty(${quantity})`}
            >
              {finnishNumber(currency + (price * qty).toFixed(2))}
            </div>
          </div>
        );
      });
    };

    const showItems = data.items?.map((item, o) => {
      const { id, name, price, qty, add_on, product_size } = item;
      return (
        <div
          className={`cart-item-row ${
            o + 1 === data.items.length ? '' : 'dotted-border-bottom'
          }`}
          key={id}
        >
          <div className='row' key={id}>
            <div className='col-sm-6 big-screen'>
              <div className='d-flex'>
                <div style={{ flex: 1 }}>
                  <span className='cart-item'>{`${name} ${
                    product_size ? `(${product_size})` : ''
                  }`}</span>
                </div>

                <MdClose
                  className='cart-remove-action'
                  title={`Remove ${name} from cart.`}
                  onClick={() => this.removeItem(o)}
                />
              </div>
            </div>
            <div
              className='col-sm-5 small-screen'
              style={{ paddingRight: '10px' }}
            >
              <div className='d-flex'>
                <div style={{ flex: 1 }}>
                  <span className='cart-item'>{`${name} ${
                    product_size ? `(${product_size})` : ''
                  }`}</span>
                </div>

                <MdClose
                  className='cart-remove-action'
                  title={`Remove ${name} from cart.`}
                  onClick={() => this.removeItem(o)}
                />
              </div>
            </div>
            <div
              className={`col-sm-3 ${
                window.orientation !== undefined ? 'text-center' : 'text-left'
              }`}
            >
              <MdRemove
                className='cart-action'
                title={`Remove 1 ${name} from cart.`}
                onClick={() => this.decreaseQty(o, item)}
              />
              <span className='cart-qty cart-qty-old'>{`${qty}x`}</span>
              <MdAdd
                className='cart-action'
                title={`Add 1 ${name} in cart.`}
                onClick={() => this.increaseQty(o, item)}
              />
            </div>
            <div
              className={`col-sm-3 ${
                window.orientation !== undefined ? 'text-center' : 'text-right'
              }`}
              title={`Item Price (${price}) * Item Qty (${qty})`}
            >
              {finnishNumber(currency + (price * qty).toFixed(2))}
            </div>
          </div>
          {addOnsList(add_on, qty, o, item)}
        </div>
      );
    });

    return (
      <React.Fragment>
        <div className='row mt-3'>
          <div className='col-sm-12 col-md-6 text-center'>
            <span className='cart-label'>{language(lang).DELIVERY_TYPE}</span>
            <select
              className={`form-control ${
                error.delivery_type ? 'is-invalid' : ''
              }`}
              value={delivery_type}
              onChange={this.onChangeDeliveryType}
            >
              <option value='' className='new-custom-select'></option>
              <option value='pick' className='new-custom-select'>
                {language(lang).PICKUP}
              </option>
              {data.restaurantInfo?.delivery_order === '1' && (
                <option value='delivery' className='new-custom-select'>
                  {language(lang).RESTAURANT_DELIVERY}
                </option>
              )}
            </select>
            {error.delivery_type && (
              <div className='invalid-feedback'>
                {language(lang).PLEASE_SELECT_DELIVERY_TYPE}
              </div>
            )}
          </div>
          <div className='col-sm-12 col-md-6 text-center'>
            <span className='cart-label'>{language(lang).PHONE_NUMBER}</span>
            <input
              className={`form-control ${error.phone ? 'is-invalid' : ''}`}
              value={phone}
              onChange={this.onChangePhone}
              type='number'
              min={0}
              placeholder='eg. 0401234567'
            />
            {error.phone && <div className='invalid-feedback'>Required</div>}
          </div>
          {delivery_type === 'delivery' && (
            <React.Fragment>
              <div className='col-sm-12 col-md-6 text-center'>
                <span className='cart-label'>{language(lang).STREET}</span>
                <input
                  className={`form-control ${error.street ? 'is-invalid' : ''}`}
                  value={street}
                  onChange={this.onChangeStreet}
                />
                {error.street && (
                  <div className='invalid-feedback'>
                    {language(lang).STREET_REQUIRED}
                  </div>
                )}
              </div>
              <div className='col-sm-12 col-md-6 text-center'>
                <span className='cart-label'>{language(lang).CITY}</span>
                <input
                  className={`form-control ${error.city ? 'is-invalid' : ''}`}
                  value={city}
                  onChange={this.onChangeCity}
                />
                {error.city && (
                  <div className='invalid-feedback'>
                    {language(lang).CITY_REQUIRED}
                  </div>
                )}
              </div>
              <div className='col-sm-12 col-md-6 text-center'>
                <span className='cart-label'>
                  {language(lang).DELIVERY_DETAILS}
                </span>
                <input
                  className={`form-control ${
                    error.delivery_address_details ? 'is-invalid' : ''
                  }`}
                  value={delivery_address_details}
                  onChange={this.onChangeDeliveryInfo}
                  placeholder={language(lang).FLOOR_ROOM}
                />
              </div>
            </React.Fragment>
          )}
        </div>

        <div className='item-block'>{showItems}</div>
        <div className='row'>
          <div className='col-sm-9'>
            <span className='text-muted'>{language(lang).SUB_TOTAL}</span>
          </div>
          <div className='col-sm-3 text-right'>
            {finnishNumber(
              currency + (data.calculated ? data.calculated.sub_total : '')
            )}
          </div>
        </div>

        <div className='row'>
          <div className='col-sm-9'>
            <span className='text-muted'>{language(lang).DELIVERY_CHARGE}</span>
          </div>
          <div className='col-sm-3 text-right' title='To Be Decided'>
            TBD
          </div>
        </div>

        <div className='row'>
          <div className='col-sm-9'>
            <span className='text-muted'>{language(lang).DISCOUNT_AMOUNT}</span>
          </div>
          <div className='col-sm-3 text-right' title='Discount Amount'>
            {finnishNumber(
              currency + (data.calculated ? data.calculated.discount : '')
            )}
          </div>
        </div>

        <div className='row mb-3'>
          <div className='col-sm-9'>
            <span className='text-muted'>{language(lang).TOTAL}</span>
          </div>
          <div className='col-sm-3 text-right'>
            {finnishNumber(
              currency + (data.calculated ? data.calculated.total : '')
            )}
          </div>
        </div>

        <div className='row'>
          <div className='col-sm-12 text-center'>
            <button
              className='checkout-button auto-button'
              onClick={this.onConfirm}
            >
              {language(lang).CONFIRM_CHECKOUT}
              {isProcessing && (
                <span
                  className='spinner-border spinner-border-sm'
                  role='status'
                  aria-hidden='true'
                ></span>
              )}
            </button>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  cartData: state.cartData,
  profile: state.profile.info,
  lang: state.language.language,
});
const mapDispatchToProps = (dispatch) => ({
  replaceCart: (index, food) => dispatch(replaceCart(index, food)),
  removeFromCart: (index) => dispatch(removeFromCart(index)),
  initiateOrderCheckout: (data) => dispatch(initiateOrderCheckout(data)),
  changeDeliveryType: (data) => dispatch(changeDeliveryType(data)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(CartItems));
