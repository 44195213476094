import React from 'react';
import { connect } from 'react-redux';

import empty_cart from '../../assets/images/empty_cart.svg';
import { language } from '../../constants';

const EmptyCart = (props) => (
  <div className='text-center'>
    <img src={empty_cart} alt='Empty Cart' style={{maxWidth: '100%'}} />
    <div className='text-center'>
      <span className='text-muted'>
        {language(props.lang).YOUR_CART_IS_EMPTY}
      </span>
    </div>
  </div>
);

const mapStateToProps = (state) => ({
  lang: state.language.language,
});
const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(EmptyCart);
