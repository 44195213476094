import React, { useState, useEffect } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import moment from 'moment';

import { saveOrderReview, skipReview } from '../../services/orders';

import Card from '../UI/Card';
import DeliveryRating from './DeliveryRating';
import DeliveryReview from './DeliveryReview';
import RestaurantRating from './RestaurantRating';
import RestaurantReview from './RestaurantReview';

import classes from './OrderRating.module.css';
import PostReview from './PostReview';

const ReviewType = { delivery: 'delivery', restaurant: 'restaurant' };

const OrderRating = (props) => {
  const params = useParams();

  const history = useHistory();

  const orderId = params.id;

  const [step, setStep] = useState(1);

  const [errorMessage, setErrorMessage] = useState(null);

  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (props.deliveryType !== 'driver') {
      setStep(3);
    }
  }, [props.deliveryType]);

  const postDeliveryRatingHandler = (count) => {
    setErrorMessage(null);

    if (count > 0) {
      setIsLoading(true);

      saveOrderReview({ ratings: count, type: ReviewType.delivery }, orderId)
        .then(() => {
          setIsLoading(false);

          setStep(2);
        })
        .catch((error) => {
          setIsLoading(false);

          setErrorMessage('Could not save your review. Please try again');
        });
    } else {
      setErrorMessage('Please select valid rating.');
    }
  };

  const postDeliveryReviewHandler = (message) => {
    setErrorMessage(null);

    if (message.trim().length > 0) {
      setIsLoading(true);

      saveOrderReview({ remarks: message, type: ReviewType.delivery }, orderId)
        .then(() => {
          setIsLoading(false);

          setStep(3);
        })
        .catch(() => {
          setIsLoading(false);

          setErrorMessage('Could not save your review. Please try again');
        });
    } else {
      setErrorMessage('Please provide valid review message.');
    }
  };

  const postRestaurantRatingHandler = (count) => {
    setErrorMessage(null);

    if (count > 0) {
      setIsLoading(true);

      saveOrderReview({ ratings: count, type: ReviewType.restaurant }, orderId)
        .then(() => {
          setIsLoading(false);

          setStep(4);
        })
        .catch(() => {
          setIsLoading(false);

          setErrorMessage('Could not save your review. Please try again');
        });
    } else {
      setErrorMessage('Please select valid rating.');
    }
  };

  const postRestaurantReviewHandler = (message) => {
    setErrorMessage(null);

    if (message.trim().length > 0) {
      setIsLoading(true);

      saveOrderReview(
        { remarks: message, type: ReviewType.restaurant },
        orderId
      )
        .then(() => {
          setIsLoading(false);

          setStep(5);
        })
        .catch(() => {
          setIsLoading(false);

          setErrorMessage('Could not save your review. Please try again');
        });
    } else {
      setErrorMessage('Please provide valid review message.');
    }
  };

  const doneHandler = () => {
    props.resetCurrentOrder({});
    history.push('/order-history');
  };

  const skipHandler = () => {
    skipReview(orderId)
      .then(() => {
        props.resetCurrentOrder({});
        history.push('/order-history');
      })
      .catch(() => {
        setErrorMessage('Could not save your review. Please try again');
      });
  };

  return (
    <div className={classes['rating-container']}>
      <Card className={classes['flex-content']}>
        <div className={classes['restaurant-name']}>
          {props.restaurant} / {moment().format('MMM D, YYYY')}
          <button onClick={skipHandler} className={classes['btn-skip']}>
            SKIP
          </button>
        </div>

        {step === 1 && (
          <DeliveryRating
            onNext={postDeliveryRatingHandler}
            isProcessing={isLoading}
          />
        )}

        {step === 2 && (
          <DeliveryReview
            onSubmit={postDeliveryReviewHandler}
            isProcessing={isLoading}
          />
        )}

        {step === 3 && (
          <RestaurantRating
            onNext={postRestaurantRatingHandler}
            isProcessing={isLoading}
          />
        )}

        {step === 4 && (
          <RestaurantReview
            onSubmit={postRestaurantReviewHandler}
            restaurant={props.restaurant}
            isProcessing={isLoading}
          />
        )}

        {step === 5 && <PostReview onClick={doneHandler} />}

        {errorMessage && (
          <span
            className='invalid-feedback'
            style={{ display: 'block', marginTop: '0.8em' }}
          >
            {errorMessage}
          </span>
        )}
      </Card>
    </div>
  );
};

export default OrderRating;
