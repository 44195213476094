import { connect } from 'react-redux';
import React, { Component } from 'react';

import MainLayout from '../shared/MainLayout';
import EmptyCart from '../shared/EmptyCart';
import CartItems from '../shared/CartItems';
import { language } from '../../constants';

class NewOrderBasket extends Component {
  state = {
    order: {},
  };

  componentDidMount() {
    const as = localStorage.getItem('FULL_NEW_ORDER');
    if (as) {
      this.setState({
        order: JSON.parse(as),
      });
    }
  }

  emptyCart = () => {
    this.setState({ order: {} });
  };

  render() {
    const { lang } = this.props;
    const { order } = this.state;

    return (
      <MainLayout>
        <section className='page-header'>
          <div className='container'>
            <h1>{language(lang).MY_BASKET}</h1>
          </div>
        </section>
        <section className='page-content' style={{ borderBottom: 'none' }}>
          <div className='container'>
            {Object.keys(order).length > 0 ? (
              <React.Fragment>
                <div className='row'>
                  <div className='col-sm-12 text-center'>
                    <span className='cart-title'>
                      {order.restaurant ? order.restaurant.name : '-'}
                    </span>
                  </div>
                </div>
                <CartItems data={order} emptyCart={this.emptyCart} />
              </React.Fragment>
            ) : (
              <EmptyCart />
            )}
          </div>
        </section>
      </MainLayout>
    );
  }
}

const mapStateToProps = (state) => ({
  lang: state.language.language,
  cartData: state.cartData,
});
const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(NewOrderBasket);
