import React, { Component } from 'react';
import {
  fetchRestaurantDetails,
  fetchRestaurantProducts,
  fetchAddons,
} from '../services';

export function withRestaurantDetails(WrappedComponent) {
  return class RestaurantDetails extends Component {
    state = {
      isRestaurantDetailLoading: false,
      isRestaurantProductLoading: false,
      isAddonLoading: false,
      restaurantDetail: {},
      restaurantProducts: [],
      addons: [],
      currencySymbol: '',
    };

    getRestaurantDetails = (id) => {
      this.setState({
        isRestaurantDetailLoading: true,
      });
      fetchRestaurantDetails(id)
        .then((res) => {
          this.setState({
            isRestaurantDetailLoading: false,
          });
          if (res.status === 200) {
            this.setState({
              restaurantDetail: res.data,
              currencySymbol: res.data.currency.currency_symbol,
            });
          }
        })
        .catch((err) => {
          this.setState({
            isRestaurantDetailLoading: false,
          });
        });
    };

    getRestaurantProducts = (id) => {
      this.setState({
        isRestaurantProductLoading: true,
      });
      fetchRestaurantProducts(id)
        .then((res) => {
          this.setState({
            isRestaurantProductLoading: false,
          });
          if (res.status === 200) {
            this.setState({
              restaurantProducts: res.data,
            });
          }
        })
        .catch((err) => {
          this.setState({
            isRestaurantProductLoading: false,
          });
        });
    };

    getAddons = (id) => {
      this.setState({
        isAddonLoading: true,
      });
      fetchAddons(id)
        .then((res) => {
          if (res.status === 200) {
            this.setState({
              addons: res.data,
            });
          }
          this.setState({
            isAddonLoading: false,
          });
        })
        .catch((err) => {
          this.setState({
            isAddonLoading: false,
          });
        });
    };

    resetAddons = () => {
      this.setState({
        addons: [],
      });
    };

    render() {
      const {
        isRestaurantDetailLoading,
        isRestaurantProductLoading,
        restaurantDetail,
        restaurantProducts,
        addons,
        isAddonLoading,
        currencySymbol,
      } = this.state;
      return (
        <WrappedComponent
          isRestaurantDetailLoading={isRestaurantDetailLoading}
          isRestaurantProductLoading={isRestaurantProductLoading}
          restaurantDetail={restaurantDetail}
          restaurantProducts={restaurantProducts}
          getRestaurantDetails={this.getRestaurantDetails}
          getRestaurantProducts={this.getRestaurantProducts}
          getAddons={this.getAddons}
          addons={addons}
          isAddonLoading={isAddonLoading}
          resetAddons={this.resetAddons}
          currencySymbol={currencySymbol}
          {...this.props}
        />
      );
    }
  };
}
