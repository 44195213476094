import http from '../utils/http';

export function fetchOrders(filter, page, limit) {
  let url = `orders?page=${page || 1}`;
  if (limit) {
    url += `&limit=${limit}`;
  }
  filter.forEach((single) => {
    url += `&filter[${single.key}]=${single.value}`;
  });
  return http.get(url);
}

export function fetchOrderById(id) {
  const url = `orders/${id}`;
  return http.get(url);
}

export function initiateOrder(data) {
  return http.post('orders', data);
}

export function generateCart(data) {
  return http.post('cart', data);
}

export const saveOrderReview = (review, orderId) => {
  const url = `orders/${orderId}/reviews`;

  return http.post(url, review);
};

export const skipReview = (orderId) => {
  const url = `orders/${orderId}/skip-reviews`;

  return http.post(url, {});
};

export const getReview = (orderId) => {
  const url = `orders/${orderId}/reviews`;

  return http.get(url);
};
