import React, { Component } from 'react';
import { connect } from 'react-redux';
import Swal from 'sweetalert2';

import MainLayout from './shared/MainLayout';
import Loader from './shared/ComponentLoader';
import { updateInfo, getInfo } from '../services/auth';
import { setProfile } from '../actions/profile';
import { language } from '../constants';

class EditProfile extends Component {
  state = {
    isLoading: true,
    data: {
      first_name: '',
      last_name: '',
      email: '',
      address: '',
      zip_code: '',
      phone_no: '',
      city: '',
      delivery_details: '',
    },
    error: {
      first_name: '',
      last_name: '',
      email: '',
      address: '',
      zip_code: '',
      phone_no: '',
      city: '',
    },
    isProcessing: false,
  };
  componentDidMount() {
    if (Object.keys(this.props.profile.info).length > 0) {
      this.setState({
        isLoading: false,
        data: { ...this.props.profile.info },
      });
    }
  }
  componentDidUpdate(prevProps) {
    if (this.props.profile !== prevProps.profile) {
      this.setState({
        isLoading: false,
        data: { ...this.props.profile.info },
      });
    }
  }

  onChange = (e) => {
    const { name, value } = e.target;
    let { data, error } = this.state;
    data[name] = value;
    error[name] = '';
    this.setState({ data, error });
  };

  onChangeBuildingInfo = (e) => {
    const { name, value } = e.target;
    let { data, error } = this.state;
    let tempData = data.building_info || {};
    let tempError = error.building_info || {};
    tempData[name] = value;
    tempError[name] = '';
    data.building_info = tempData;
    error.building_info = tempError;
    this.setState({ data, error });
  };

  onSubmit = () => {
    if (!this.state.isProcessing) {
      this.setState({
        isProcessing: true,
      });
      const { data } = this.state;
      updateInfo(data.id, data)
        .then((res) => {
          Swal.fire({
            text: res.data.message,
            icon: 'success',
            showCancelButton: false,
            confirmButtonColor: '#ef692a',
            confirmButtonText: 'Ok',
          }).then(() => {
            getInfo(data.id).then((response) => {
              this.props.setProfile(response.data);
            });
          });
        })
        .catch((err) => {
          if (err.response.status === 422) {
            this.setState({
              error: err.response.data.message,
            });
          } else {
            Swal.fire({
              text: 'Something went wrong',
              icon: 'error',
              showCancelButton: false,
              confirmButtonColor: '#ef692a',
              confirmButtonText: 'Go to Home',
            });
          }
        })
        .finally(() => {
          this.setState({ isProcessing: false });
        });
    }
  };

  render() {
    const { data, isLoading, error, isProcessing } = this.state;
    const { lang } = this.props;
    return (
      <MainLayout showSearchRestaurant={true}>
        <section className='page-header'>
          <div className='container'>
            <h1>{language(lang).EDIT_PROFILE}</h1>
          </div>
        </section>
        {isLoading ? (
          <Loader />
        ) : (
          <section className='page-content'>
            <div className='container'>
              <div className='row'>
                <div className='col-md-6 col-sm-12 mb-2'>
                  <span>{language(lang).FIRST_NAME}</span>
                  <input
                    className={`form-control ${
                      error.first_name ? 'is-invalid' : ''
                    }`}
                    value={data.first_name}
                    name='first_name'
                    onChange={this.onChange}
                  />
                  {error.first_name && (
                    <div className='invalid-feedback'>{error.first_name}</div>
                  )}
                </div>
                <div className='col-md-6 col-sm-12 mb-2'>
                  <span>{language(lang).LAST_NAME}</span>
                  <input
                    className={`form-control ${
                      error.last_name ? 'is-invalid' : ''
                    }`}
                    value={data.last_name}
                    name='last_name'
                    onChange={this.onChange}
                  />
                  {error.last_name && (
                    <div className='invalid-feedback'>{error.last_name}</div>
                  )}
                </div>
                <div className='col-md-6 col-sm-12 mb-2'>
                  <span>{language(lang).EMAIL}</span>
                  <input
                    className={`form-control ${
                      error.email ? 'is-invalid' : ''
                    }`}
                    value={data.email}
                    name='email'
                    onChange={this.onChange}
                  />
                  {error.email && (
                    <div className='invalid-feedback'>{error.email}</div>
                  )}
                </div>
                <div className='col-md-6 col-sm-12 mb-2'>
                  <span>{language(lang).ADDRESS}</span>
                  <input
                    className={`form-control ${
                      error.address ? 'is-invalid' : ''
                    }`}
                    value={data.address}
                    name='address'
                    onChange={this.onChange}
                  />
                  {error.address && (
                    <div className='invalid-feedback'>{error.address}</div>
                  )}
                </div>
                <div className='col-md-6 col-sm-12 mb-2'>
                  <span>{language(lang).CITY}</span>
                  <input
                    className={`form-control ${error.city ? 'is-invalid' : ''}`}
                    value={data.city}
                    name='city'
                    onChange={this.onChange}
                  />
                  {error.address && (
                    <div className='invalid-feedback'>{error.city}</div>
                  )}
                </div>
                <div className='col-md-6 col-sm-12 mb-2'>
                  <span>{language(lang).ZIP_CODE}</span>
                  <input
                    className={`form-control ${
                      error.zip_code ? 'is-invalid' : ''
                    }`}
                    value={data.zip_code}
                    name='zip_code'
                    onChange={this.onChange}
                  />
                  {error.zip_code && (
                    <div className='invalid-feedback'>{error.zip_code}</div>
                  )}
                </div>
                <div className='col-md-6 col-sm-12 mb-3'>
                  <span>{language(lang).PHONE_NUMBER}</span>
                  <input
                    className={`form-control ${
                      error.phone_no ? 'is-invalid' : ''
                    }`}
                    value={data.phone_no}
                    name='phone_no'
                    onChange={this.onChange}
                  />
                  {error.phone_no && (
                    <div className='invalid-feedback'>{error.phone_no}</div>
                  )}
                </div>
                <div className='col-md-6 col-sm-12 mb-3'>
                  <span>{language(lang).DELIVERY_DETAILS}</span>
                  <input
                    className={`form-control ${
                      error.delivery_details ? 'is-invalid' : ''
                    }`}
                    value={data.delivery_details}
                    name='delivery_details'
                    onChange={this.onChange}
                  />
                  {error.delivery_details && (
                    <div className='invalid-feedback'>
                      {error.delivery_details}
                    </div>
                  )}
                </div>
              </div>
              <div className='row'>
                <div className='col-12 text-center'>
                  <button className='login-button' onClick={this.onSubmit}>
                    {language(lang).UPDATE_PROFILE}
                    {isProcessing && (
                      <span
                        className='spinner-border spinner-border-sm'
                        role='status'
                        aria-hidden='true'
                      ></span>
                    )}
                  </button>
                </div>
              </div>
            </div>
          </section>
        )}
      </MainLayout>
    );
  }
}

const mapStateToProps = (state) => ({
  profile: state.profile,
  lang: state.language.language,
});
const mapDispatchToProps = (dispatch) => ({
  setProfile: (data) => dispatch(setProfile(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(EditProfile);
