import { reducerConstants } from '../constants';

export function setLanguage(value) {
  return (dispatch) => {
    dispatch({
      type: reducerConstants.SET_LANGUAGE,
      payload: value,
    });
  };
}
