import React from 'react';
import { Modal } from 'react-bootstrap';

const OrderDetail = (props) => {
  const { data, closeModal, isShow } = props;
  const currency = data.restaurant
    ? data.restaurant.currency
      ? data.restaurant.currency.currency_symbol
      : '€'
    : '€';
  return (
    <Modal show={isShow} onHide={closeModal} size='lg'>
      <Modal.Header closeButton>
        <Modal.Title>{`Order #${data.id}`}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <table className='table table-bordered'>
          <thead>
            <tr>
              <th className='text-center text-bold' style={{ width: '60%' }}>
                Item
              </th>
              <th className='text-center text-bold' style={{ width: '10%' }}>
                Qty
              </th>
              <th className='text-center text-bold' style={{ width: '15%' }}>
                Price
              </th>
              <th className='text-center text-bold' style={{ width: '15%' }}>
                Total
              </th>
            </tr>
          </thead>
          <tbody>
            {data.order_detail &&
              data.order_detail.map((o, i) => {
                return (
                  <React.Fragment key={i}>
                    <tr>
                      <td>{o.name}</td>
                      <td className='text-right'>{o.qty}</td>
                      <td className='text-right'>{`${currency}${o.amount}`}</td>
                      <td className='text-right'>{`${currency}${o.total}`}</td>
                    </tr>
                    {o.order_detail_addon.map((oo, ii) => {
                      return (
                        <tr key={ii}>
                          <td style={{ paddingLeft: '4rem' }}>{oo.name}</td>
                          <td className='text-right'>{oo.qty}</td>
                          <td className='text-right'>
                            {`${currency}${oo.amount}`}
                          </td>
                          <td className='text-right'>
                            {`${currency}${oo.total}`}
                          </td>
                        </tr>
                      );
                    })}
                  </React.Fragment>
                );
              })}
            <tr>
              <td colSpan='3' className='text-right'>
                Subtotal
              </td>
              <td className='text-right'>{`${currency}${data.amount}`}</td>
            </tr>
            <tr>
              <td colSpan='3' className='text-right'>
                Total
              </td>
              <td className='text-right'>{`${currency}${data.total}`}</td>
            </tr>
          </tbody>
        </table>
      </Modal.Body>
    </Modal>
  );
};

export default OrderDetail;
