import React from 'react';
import { connect } from 'react-redux';
import { Route, Switch, BrowserRouter } from 'react-router-dom';

import AuthRoutes from './AuthRoutes';

import Checkout from './Checkout';
import Dashboard from './Dashboard';
import TrackOrder from './TrackOrder';
import OrderBasket from './OrderBasket';
import EditProfile from './EditProfile';
import Restaurants from './Restaurants';
import OrderHistory from './OrderHistory';
import PrivacyPolicy from './PrivacyPolicy';
import ChangePassword from './ChangePassword';
import { fetchOrders, getReview } from '../services/orders';
import RestaurantDetails from './RestaurantDetails';
import ConfirmRegistration from './ConfirmRegistration';
import { setCurrentOrder } from '../actions/orders';

class Routes extends React.Component {
  componentDidMount() {
    window.$crisp = [];
    window.CRISP_WEBSITE_ID = '7cc21524-69f4-429c-8f7a-8fb75faeb55d';
    (function () {
      let d = document;
      let s = d.createElement('script');
      s.src = 'https://client.crisp.chat/l.js';
      s.async = 1;
      d.getElementsByTagName('head')[0].appendChild(s);
    })();
    this.fetchData();
  }

  fetchData = () => {
    fetchOrders([{ key : 'is_paid', value: 'paid' }], 1, 1)
      .then((res) => {
        if (res.data) {
          const order = res.data.data[0];
          if (order && !order.skipped_review) {
            getReview(order.id).then((response) => {
              if (response.data.length === 0) {
                this.props.setCurrentOrder(order);
              }
            });
          }
        }
      })
      .catch(() => {});
  };

  render() {
    return (
      <BrowserRouter>
        <Switch>
          <Route exact path='/' component={Dashboard}></Route>
          <Route exact path='/privacy-policy' component={PrivacyPolicy}></Route>
          <Route
            exact
            path='/restaurants/:id'
            component={RestaurantDetails}
          ></Route>
          <Route exact path='/restaurants' component={Restaurants}></Route>
          <AuthRoutes exact path='/checkout' component={Checkout} />
          <AuthRoutes exact path='/order-history' component={OrderHistory} />
          <AuthRoutes exact path='/profile' component={EditProfile} />
          <Route exact path='/reset-password' component={ChangePassword} />
          <AuthRoutes exact path='/track-order/:id' component={TrackOrder} />
          <AuthRoutes exact path='/my-basket' component={OrderBasket} />
          <Route
            exact
            path='/api/customers/:id/verify'
            component={ConfirmRegistration}
          />
        </Switch>
      </BrowserRouter>
    );
  }
}

const mapStateToProps = (state) => ({});
const mapDispatchToProps = (dispatch) => ({
  setCurrentOrder: (data) => dispatch(setCurrentOrder(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Routes);
