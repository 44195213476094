import React, { Component } from 'react';
import { Modal } from 'react-bootstrap';
import Swal from 'sweetalert2';
import { connect } from 'react-redux';

import { createDriver } from '../../services';
import { language } from '../../constants';

class DriverRegister extends Component {
  data = {
    first_name: '',
    last_name: '',
    email: '',
    address: '',
    zip_code: '',
  };

  state = {
    data: { ...this.data },
    error: { ...this.data },
    isProcessing: false,
  };

  onChange = (e) => {
    let { name, value } = e.target;
    let { data, error } = this.state;
    error[name] = '';
    data[name] = value;
    this.setState({
      data,
      error,
    });
  };

  onSubmit = () => {
    if (!this.state.isProcessing) {
      this.setState({
        isProcessing: true,
      });
      createDriver(this.state.data)
        .then((res) => {
          Swal.fire({
            text: res.data.message,
            icon: 'success',
            showCancelButton: false,
            confirmButtonColor: '#ef692a',
            confirmButtonText: 'Ok',
          }).then(() => {
            this.setState({
              isProcessing: false,
              data: { ...this.data },
              error: { ...this.data },
            });
            this.props.closeModal();
          });
        })
        .catch((err) => {
          if (err.response.status === 422) {
            this.setState({
              error: err.response.data.message,
              isProcessing: false,
            });
          }
        });
    }
  };

  render() {
    const { show, closeModal, lang } = this.props;
    const { data, error, isProcessing } = this.state;
    return (
      <Modal show={show} onHide={closeModal}>
        <Modal.Header closeButton>
          <Modal.Title>{language(lang).REGISTER_AS_DRIVER}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <FormRow
            name='first_name'
            value={data.first_name}
            placeholder={language(lang).ENTER_YOUR_FIRST_NAME}
            error={error.first_name}
            onChange={this.onChange}
          />
          <FormRow
            name='last_name'
            value={data.last_name}
            placeholder={language(lang).ENTER_YOUR_LAST_NAME}
            error={error.last_name}
            onChange={this.onChange}
          />
          <FormRow
            name='email'
            value={data.email}
            placeholder={language(lang).ENTER_YOUR_EMAIL_ADDRESS}
            error={error.email}
            onChange={this.onChange}
          />
          <FormRow
            name='address'
            value={data.address}
            placeholder={language(lang).ENTER_YOUR_ADDRESS}
            error={error.address}
            onChange={this.onChange}
          />
          <FormRow
            name='zip_code'
            value={data.zip_code}
            placeholder={language(lang).ENTER_YOUR_ZIP_CODE}
            error={error.zip_code}
            onChange={this.onChange}
            type='number'
            min={0}
          />
          <button className='signin-button' onClick={this.onSubmit}>
            {language(lang).REGISTER_IN} <strong>Mulas</strong>
            {isProcessing && (
              <span
                className='spinner-border spinner-border-sm'
                role='status'
                aria-hidden='true'
              ></span>
            )}
          </button>
        </Modal.Body>
      </Modal>
    );
  }
}

const FormRow = ({ name, type, onChange, error, placeholder, min, value }) => (
  <React.Fragment>
    <input
      name={name}
      className={`form-control ${error ? 'is-invalid' : 'auth-input'}`}
      type={type || 'text'}
      onChange={onChange}
      placeholder={placeholder}
      min={min}
      value={value}
    />
    {error && <div className='invalid-feedback auth-input'>{error}</div>}
  </React.Fragment>
);

const mapStateToProps = (state) => ({
  lang: state.language.language,
});
const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(DriverRegister);
