import React from "react";

import classes from "./Button.module.css";

const Button = (props) => {
  const classNames = [
    classes.button,
    props.classNames ? props.classNames : "",
    props.isProcessing ? classes.disabled : "",
  ];

  return (
    <button
      className={classNames.join(" ")}
      onClick={props.onClick}
      disabled={props.isProcessing ? "disabled" : null}
    >
      {props.children}
      {props.isProcessing && (
        <span
          className="spinner-border spinner-border-sm"
          role="status"
          aria-hidden="true"
        ></span>
      )}
    </button>
  );
};

export default Button;
