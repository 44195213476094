import Rating from 'react-rating';
import { connect } from 'react-redux';
import React, { Component } from 'react';
import { Link, Element } from 'react-scroll';

import Map from '../../utils/map';
import Loader from '../shared/Loader';
import { language } from '../../constants';
import EmptyCart from '../shared/EmptyCart';
import CartItems from '../shared/CartItems';
import AddToCart from '../modals/AddToCart';
import MainLayout from '../shared/MainLayout';
import { openLogin } from '../../actions/profile';
import spice from '../../assets/images/spice.png';
import { finnishNumber } from '../../utils/common';
import { withRestaurantDetails } from '../../hoc/RestaurantDetails';

class OldRestaurantDetail extends Component {
  state = {
    menuIndex: 1,
    showAddModal: false,
    cartDataState: {},
    isLoggedIn: false,
  };
  componentDidMount() {
    this.fetchData();
    this.setState({
      isLoggedIn: Object.keys(this.props.profile).length > 0,
    });
  }
  componentDidUpdate(prevProps) {
    if (this.props.profile !== prevProps.profile) {
      this.setState({
        isLoggedIn: Object.keys(this.props.profile).length > 0,
      });
    }
  }
  fetchData = () => {
    this.props.getRestaurantProducts(this.props.match.params.id);
  };
  onChangeCategory = (e) => {
    let element = document.getElementById(e.target.value);
    element.scrollIntoView({ behavior: 'smooth' });
  };
  onChangeMenu = (menuIndex) => {
    this.setState({ menuIndex });
  };
  parseTime = (time) => {
    let timeInt = parseInt(time);
    let minutes = time.substring(3, 5);

    if (time > '12:00') {
      return `${timeInt - 12}:${minutes} PM`;
    } else {
      return `${timeInt}:${minutes} AM`;
    }
  };
  showAddToCartModal = (cartDataState) => {
    if (this.state.isLoggedIn) {
      if (cartDataState.addon_count) {
        this.props.getAddons(cartDataState.id);
      } else {
        this.props.resetAddons();
      }
      this.setState({
        cartDataState,
        showAddModal: true,
      });
    } else {
      this.props.openLogin();
    }
  };
  closeAddToCartModal = () => {
    this.setState({
      cartDataState: {},
      showAddModal: false,
    });
  };

  render() {
    const {
      isRestaurantDetailLoading,
      isRestaurantProductLoading,
      restaurantDetail,
      restaurantProducts,
      cartData,
      addons,
      isAddonLoading,
      currencySymbol,
      lang,
    } = this.props;

    const { menuIndex, cartDataState, showAddModal, isLoggedIn } = this.state;

    const rTypes = restaurantDetail.restaurant_type || [];

    const productsList = (products) => {
      return products.map((product, o) => {
        const { id, name, descriptions, price, spicy } = product;
        let spiceImage = [];
        for (let i = 0; i < spicy || 0; i++) {
          spiceImage.push(i);
        }
        return (
          <li
            key={id}
            className={`d-flex justify-content-between ${
              products.length === o + 1 ? 'no-border' : ''
            }`}
          >
            <div style={{maxWidth: '90%'}}>
              <div className='d-flex flex-column descriptions'>
                <div className='d-flex'>
                  <span className='text-bold' style={{textOverflow: 'elipsis', maxWidth: '80%'}}>{name}</span>
                  <div style={{ marginLeft: '2px' }}>
                    {spiceImage.map((single, i) => (
                      <img
                        src={spice}
                        style={{
                          height: '14px',
                          width: '14px',
                          marginLeft: '2px',
                        }}
                        alt={name}
                        key={i}
                      />
                    ))}
                  </div>
                </div>
                <span
                  className='text-muted'
                  style={{ wordSpacing: '-1px', lineHeight: '1' }}
                >
                  <small>{descriptions}</small>
                </span>
              </div>
            </div>
            <div className='d-flex flex-column'>
              <span>{finnishNumber(currencySymbol + price.toFixed(2))}</span>
              <button
                className='add-btn'
                title={language(lang).ADD_TO_CART}
                onClick={() => {
                  this.showAddToCartModal(product);
                }}
              >
                +
              </button>
            </div>
          </li>
        );
      });
    };

    const restaurantTypes = rTypes.map((restaurant) => {
      const { id, name } = restaurant;
      return <span key={id}>{name}</span>;
    });

    const categoriesList = restaurantProducts.map((product) => {
      const { id, name } = product;
      return (
        <li key={id} className='category-name'>
          <Link
            activeClass='active'
            to={`${name}${id}`}
            spy={true}
            smooth={true}
            duration={500}
          >
            {name}
          </Link>
        </li>
      );
    });

    const categoriesChips = restaurantProducts.map((product) => {
      const { id, name } = product;
      return (
        <Link
          activeClass='active'
          to={`${name}${id}`}
          spy={true}
          smooth={true}
          duration={500}
          key={id}
        >
          <p>
            <span>{name}</span>
          </p>
        </Link>
      );
    });

    const productCards = restaurantProducts.map((product) => {
      const { id, name, products } = product;
      return (
        <Element name={`${name}${id}`} key={id}>
          <div className='card product-card' id={`${name}${id}`}>
            <div className='card-header'>{name}</div>
            <div
              className='card-body'
              style={{
                padding: '0.5rem 1.25rem',
              }}
            >
              <ul className='products-list'>
                {productsList(products, id, name)}
              </ul>
            </div>
          </div>
        </Element>
      );
    });

    const workingHours = restaurantDetail.day || [];

    const workingHoursList = workingHours.map((workingDay) => {
      const { id, name, pivot } = workingDay;
      return (
        <li key={id}>
          <span className='time-label text-muted'>{name}</span>
          <span>{`${this.parseTime(pivot.opening_time)} - ${this.parseTime(
            pivot.closing_time
          )}`}</span>
        </li>
      );
    });

    if (isRestaurantDetailLoading || isRestaurantProductLoading) {
      return <Loader />;
    }

    return (
      <MainLayout showSearchRestaurant={true}>
        <AddToCart
          show={showAddModal}
          data={cartDataState}
          restaurantDetail={restaurantDetail}
          closeModal={this.closeAddToCartModal}
          addons={addons}
          isAddonLoading={isAddonLoading}
          currencySymbol={currencySymbol}
        />
        <section
          className='restaurant-banner'
          style={{ backgroundImage: `url(${restaurantDetail.cover_image})` }}
        >
          <div className='container'>
            {restaurantDetail.discount_rate ||
            restaurantDetail.is_delivery_free ? (
              <p className='discount-text'>
                {`${
                  restaurantDetail.discount_rate
                    ? `${restaurantDetail.discount_rate} % ${
                        language(lang).DISCOUNT
                      }`
                    : ''
                }${
                  restaurantDetail.is_delivery_free
                    ? `${
                        restaurantDetail.discount_rate
                          ? ` ${language(lang).OR}`
                          : ''
                      } ${language(lang).FREE_DELIVERY}`
                    : ''
                }`}
              </p>
            ) : null}
            <div className='row'>
              <div className='col d-flex'>
                <img
                  src={restaurantDetail.logo}
                  className='img img-thumbnail'
                  style={{ height: '100px' }}
                  alt={restaurantDetail.name}
                />
                <div style={{ marginLeft: '10px' }}>
                  <p>
                    <span style={{ marginRight: '10px' }}>
                      {restaurantDetail.name}
                    </span>
                    <Rating
                      readonly={true}
                      initialRating={
                        restaurantDetail.google_info
                          ? restaurantDetail.google_info.rating || 0
                          : 0
                      }
                      emptySymbol='fa fa-star-o'
                      fullSymbol='fa fa-star'
                      fractions={2}
                      style={{ color: 'yellow', fontSize: '16px' }}
                    />
                  </p>
                  <p>{restaurantDetail.address}</p>
                  {restaurantTypes}
                  {restaurantDetail.minimum_order_amount ? (
                    <p>{`${restaurantDetail.minimum_order_amount}${
                      restaurantDetail.currency
                        ? restaurantDetail.currency.currency_symbol
                        : ''
                    } minimum`}</p>
                  ) : null}
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className='main-content' style={{ minHeight: '55vh' }}>
          <div className='restaurant-nav-div'>
            <div className='container'>
              <div className='row'>
                <div className='col'>
                  <ul className='restaurant-nav'>
                    <li
                      className={`${menuIndex === 1 ? 'active' : ''}`}
                      onClick={() => this.onChangeMenu(1)}
                    >
                      {language(lang).MENU}
                    </li>
                    <li
                      className={`${menuIndex === 2 ? 'active' : ''}`}
                      onClick={() => this.onChangeMenu(2)}
                    >
                      {language(lang).INFO}
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          {menuIndex === 1 ? (
            <div className='product-div'>
              <div className='container'>
                <div className='row'>
                  <div className='col-lg-2 col-md-4 categories-list'>
                    <div className='restaurant-category-list'>
                      <ul className='nav flex-column'>
                        <li className='restaurant-title text-center'>
                          {language(lang).CATEGORIES}
                        </li>
                        {categoriesList}
                      </ul>
                    </div>
                  </div>
                  <div className='col-lg-2 col-md-4 categories-options'>
                    {categoriesChips}
                  </div>
                  <div className='col-lg-6 col-md-8 col-sm-12'>
                    {productCards}
                  </div>
                  {isLoggedIn && (
                    <div className='col-lg-4 col-sm-12'>
                      <div className='restaurant-category-list'>
                        <div className='card'>
                          <div className='card-header'>
                            {language(lang).CART}
                          </div>
                          <div className='card-body'>
                            {cartData.items.length > 0 ? (
                              cartData.restaurantInfo.id ===
                              restaurantDetail.id ? (
                                <CartItems data={cartData} />
                              ) : (
                                <EmptyCart />
                              )
                            ) : (
                              <EmptyCart />
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          ) : (
            <div className='info-div'>
              <div className='container'>
                <div className='row'>
                  <div className='col-md-4'>
                    <div className='card'>
                      <div className='card-header'>
                        {language(lang).OPENING_HOURS}
                      </div>
                      <div className='card-body'>
                        <ul className='nav flex-column'>{workingHoursList}</ul>
                      </div>
                    </div>
                  </div>
                  <div className='col-md-8'>
                    <Map
                      id='restaurant-map'
                      options={{
                        center: {
                          lat: parseFloat(restaurantDetail.latitude),
                          lng: parseFloat(restaurantDetail.longitude),
                        },
                        zoom: 17,
                      }}
                      onMapLoad={(map) => {
                        new window.google.maps.Marker({
                          position: {
                            lat: parseFloat(restaurantDetail.latitude),
                            lng: parseFloat(restaurantDetail.longitude),
                          },
                          map: map,
                          title: restaurantDetail.name,
                        });
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
          )}
        </section>
      </MainLayout>
    );
  }
}

const mapStateToProps = (state) => ({
  cartData: state.cartData,
  profile: state.profile.info,
  lang: state.language.language,
});
const mapDispatchToProps = (dispatch) => ({
  openLogin: () => dispatch(openLogin()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRestaurantDetails(OldRestaurantDetail));
