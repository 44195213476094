import { reducerConstants } from '../constants';

export default function profile(
  state = {
    info: {},
    isLogin: false,
  },
  action
) {
  switch (action.type) {
    case reducerConstants.SET_PROFILE:
      return { ...state, info: action.payload };
    case reducerConstants.RESET_PROFILE:
      return { ...state, info: {} };
    case reducerConstants.OPEN_LOGIN:
      return { ...state, isLogin: true };
    case reducerConstants.CLOSE_LOGIN:
      return { ...state, isLogin: false };
    default:
      return state;
  }
}
