import React, { Component } from 'react';
import queryString from 'query-string';
import { NavLink } from 'react-router-dom';
import { connect } from 'react-redux';

import MainLayout from './shared/MainLayout';
import { withRestaurants } from '../hoc/Restaurants';
import Loader from './shared/Loader';
import ComponentLoader from './shared/ComponentLoader';
import logo_image from '../assets/images/logo.png';
import delivery_cart from '../assets/images/delivery.svg';
import { finnishNumber } from '../utils/common';
import { language } from '../constants';

class Restaurants extends Component {
  state = {
    restaurantType: '',
  };
  componentDidMount() {
    this.props.getRestaurantTypes();
    this.fetchData();
  }

  componentDidUpdate(prevProps) {
    if (this.props.location.search !== prevProps.location.search) {
      this.fetchData();
    }
  }

  fetchData = () => {
    const parsed = queryString.parse(this.props.location.search);
    let keys = [];
    let values = [];
    Object.keys(parsed).forEach((single) => {
      let temp = single;
      if (single === 'type') {
        temp = 'restaurant_type.id';
        this.setState({
          restaurantType: parsed[single],
        });
      }
      keys.push(temp);
      values.push(parsed[single]);
    });
    this.props.getRestaurants(values, keys);
  };

  onChangeType = (id) => {
    this.props.history.push(`/restaurants?type=${id}`);
  };

  render() {
    const {
      isRestaurantListLoading,
      isRestaurantTypeLoading,
      restaurantList,
      restaurantTypes,
      lang,
    } = this.props;
    const { restaurantType } = this.state;

    const typesList = restaurantTypes.map((single) => {
      const { id, name, restaurant_count } = single;
      return (
        <li
          key={id}
          className={`category-name d-flex justify-content-between ${
            restaurantType === id + '' ? 'active-link' : ''
          }`}
          onClick={() => {
            this.onChangeType(id);
          }}
        >
          {name}
          <span className='badge'>{restaurant_count}</span>
        </li>
      );
    });

    if (isRestaurantTypeLoading) {
      return <Loader />;
    }

    const showRestaurantsList = restaurantList.map((restaurant) => {
      const {
        id,
        name,
        isOpen,
        cover_image,
        address,
        distance,
        currency,
        minimum_order_amount,
        delivery_order,
        is_busy,
      } = restaurant;
      return (
        <div className='col-lg-4 col-md-6 com-sm-12' key={id}>
          <div className='card restaurant-card'>
            <div className='card-body'>
              <NavLink to={`/restaurants/${id}`}>
                <div className='restaurant-image'>
                  {is_busy === '1' && (
                    <div className='busy-wrapper'>
                      <span
                        className='badge badge-warning'
                        style={{ height: 'auto' }}
                      >
                        Busy
                      </span>
                    </div>
                  )}
                  {delivery_order === '1' && (
                    <div className='delivery-wrapper'>
                      <img
                        src={delivery_cart}
                        alt='delivery'
                        style={{ width: '100%', height: '100%' }}
                        title='Delivery Available'
                      />
                    </div>
                  )}
                  <div className='ribbon-wrapper'>
                    <div className={`ribbon ${isOpen ? 'green' : 'red'}`}>
                      {isOpen ? 'Open' : 'Closed'}
                    </div>
                  </div>
                  <img
                    src={cover_image || logo_image}
                    alt={name}
                    className='dashboard-image'
                  />
                </div>
                <span>{name}</span>
              </NavLink>
              <p className='text-muted'>
                <small>
                  {`${finnishNumber(minimum_order_amount)}${
                    currency ? currency.currency_symbol : ''
                  } minimum`}
                </small>
              </p>

              <p className='text-muted'>
                <small>{address}</small>
              </p>
              <p className='text-muted'>
                {distance !== 'N/A' && <small>{`${finnishNumber(distance)} KM`}</small>}
              </p>
            </div>
          </div>
        </div>
      );
    });

    return (
      <MainLayout showSearchRestaurant={true}>
        <section className='page-header mb-3'>
          <div className='container'>
            <h1>{language(lang).RESTAURANTS}</h1>
          </div>
        </section>
        <section className='main-content'>
          <div className='container'>
            <div className='row'>
              <div className='col-lg-2 col-md-4 categories-list'>
                <ul className='nav flex-column'>
                  <li
                    className='category-name d-flex justify-content-between'
                    onClick={() => {
                      this.onChangeType('');
                    }}
                  >
                    {language(lang).ALL}
                  </li>
                  {typesList}
                </ul>
              </div>
              <div
                className='col-lg-10 col-md-8 col-sm-12'
                style={{ minHeight: '55vh' }}
              >
                {isRestaurantListLoading ? (
                  <ComponentLoader />
                ) : (
                  <div className='row'>{showRestaurantsList}</div>
                )}
              </div>
            </div>
          </div>
        </section>
      </MainLayout>
    );
  }
}

const mapStateToProps = (state) => ({
  lang: state.language.language,
});
const mapDispatchToProps = (dispatch) => ({});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRestaurants(Restaurants));
