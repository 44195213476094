import React, { Component } from 'react';
import moment from 'moment';
import { NavLink } from 'react-router-dom';
import { connect } from 'react-redux';
import queryString from 'query-string';

import MainLayout from './shared/MainLayout';
import { withOrderHistory } from '../hoc/OrderHistory';
import OrderDetail from './modals/OrderDetails';
import CustomPagination from './shared/Pagination';
import Loader from './shared/ComponentLoader';
import { language } from '../constants';
import { resetCart } from '../actions/carts';

class OrderHistory extends Component {
  state = {
    data: {},
    showModal: false,
  };

  componentDidMount() {
    const params = queryString.parse(this.props.location.search);
    if (params.success === 'true') {
      localStorage.removeItem('new_order');
      localStorage.removeItem('full_order');
      localStorage.removeItem('PARSED_RESTAURANT_ORDER');
      localStorage.removeItem('FULL_RESTAURANT_ORDER');
      localStorage.removeItem('FULL_NEW_ORDER');
      localStorage.removeItem('PARSED_NEW_ORDER');
      this.props.resetCart();
      this.props.history.push('/order-history');
    }
    this.props.getOrders();
  }

  showOrderDetail = (data) => {
    this.setState({
      data,
      showModal: true,
    });
  };
  closeOrderDetail = () => {
    this.setState({
      data: {},
      showModal: false,
    });
  };

  render() {
    const {
      currentPage,
      from,
      isFetchingOrderHistory,
      lastPage,
      orders,
      nextPageUrl,
      prevPageUrl,
      changePagination,
      lang,
    } = this.props;
    const { data, showModal } = this.state;

    const ordersList = orders.map((order) => {
      const {
        id,
        created_at,
        status,
        restaurant,
        is_paid,
        pre_order_status,
      } = order;
      if (is_paid === 'unpaid') {
        return null;
      }
      return (
        <div
          className='card order-card'
          key={id}
          onClick={() => {
            this.showOrderDetail(order);
          }}
        >
          <div className='card-body'>
            <div className='row'>
              <div className='col-12'>
                <span>{`Order #${id}`}</span>
              </div>
            </div>
            <div className='row'>
              <div className='col-12'>
                <span>{`${moment(created_at).format('llll')}`}</span>
              </div>
            </div>
            <div className='row'>
              <div className='col-12'>
                <span>{restaurant ? restaurant.name : ''}</span>
              </div>
            </div>
            <div className='row'>
              <div className='col-12'>
                {status === 'delivery' ? (
                  <span className='badge badge-success'>
                    {language(lang).DELIVERY}
                  </span>
                ) : status === 'pending' ? (
                  <span className='badge badge-secondary'>
                    {language(lang).PENDING}
                  </span>
                ) : status === 'ready' ? (
                  <span className='badge badge-info'>
                    {language(lang).DELIVERY}
                  </span>
                ) : status === 'deny' ? (
                  <span className='badge badge-danger'>
                    {language(lang).DENY}
                  </span>
                ) : status === 'receive' ? (
                  <span className='badge badge-primary'>
                    {language(lang).RECEIVE}
                  </span>
                ) : status === 'on_way' ? (
                  <span className='badge badge-primary'>
                    {language(lang).ON_THE_WAY}
                  </span>
                ) : (
                  ''
                )}
                {status === 'pending' && pre_order_status === 'accepted' ? (
                  <span
                    className='badge badge-success'
                    style={{ marginLeft: '4px' }}
                  >
                    {language(lang).ACCEPTED_BY_RESTAURANT}
                  </span>
                ) : (
                  ''
                )}
              </div>
            </div>

            <div className='row'>
              <div className='col-12'>
                <NavLink to={`/track-order/${id}`}>
                  {language(lang).TRACK_THIS_ORDER}
                </NavLink>
              </div>
            </div>
          </div>
        </div>
      );
    });

    return (
      <MainLayout showSearchRestaurant={true}>
        <OrderDetail
          isShow={showModal}
          closeModal={this.closeOrderDetail}
          data={data}
        />
        <section className='page-header'>
          <div className='container'>
            <h1>{language(lang).ORDER_HISTORY}</h1>
          </div>
        </section>
        <section className='page-content'>
          <div className='container'>
            <div className='row'>
              <div className='col-12'>
                {isFetchingOrderHistory ? (
                  <Loader />
                ) : (
                  <React.Fragment>
                    {ordersList}
                    <CustomPagination
                      from={from}
                      prevPageUrl={prevPageUrl}
                      nextPageUrl={nextPageUrl}
                      currentPage={currentPage}
                      paginationButton={changePagination}
                      lastPage={lastPage}
                    />
                  </React.Fragment>
                )}
              </div>
            </div>
          </div>
        </section>
      </MainLayout>
    );
  }
}

const mapStateToProps = (state) => ({
  lang: state.language.language,
});
const mapDispatchToProps = (dispatch) => ({
  resetCart: () => dispatch(resetCart()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withOrderHistory(OrderHistory));
