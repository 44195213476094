import React, { Component } from 'react';
import { Modal } from 'react-bootstrap';
import { connect } from 'react-redux';
import Swal from 'sweetalert2';

import SocialButton from '../shared/SocialButton';
import { setProfile } from '../../actions/profile';
import { getInfo } from '../../services';
import { profileUtils } from '../../utils/profile';

import { requestLogin, socialLogin } from '../../services';
import { language } from '../../constants';

class Login extends Component {
  state = {
    data: {
      username: '',
      password: '',
    },
    error: {
      username: '',
      password: '',
    },
    isProcessing: false,
  };

  onChange(e) {
    let { data, error } = this.state;
    data[e.target.name] = e.target.value;
    error[e.target.name] = '';
    this.setState({ data, error });
  }

  onCloseModal = () => {
    this.setState({
      data: {
        username: '',
        password: '',
      },
      error: {
        username: '',
        password: '',
      },
    });
  };

  onSubmit = () => {
    if (!this.state.isProcessing) {
      this.setState({
        isProcessing: true,
      });
      let { data, error } = this.state;
      if (data.username && data.password) {
        requestLogin(data)
          .then((res) => {
            this.setState({
              isProcessing: false,
            });
            localStorage.setItem('access_token', res.data.access_token);
            const userId = profileUtils.getUserId(res.data.access_token);
            if (userId) {
              getInfo(userId).then((res) => {
                this.props.setProfile(res.data);
              });
              this.props.closeModal();
            }
          })
          .catch((err) => {
            if (err.response) {
              error.username = err.response.data.message;
            }
            this.setState({ error, isProcessing: false });
          });
      } else {
        if (!data.username) {
          error.username = 'Username is required';
        }
        if (!data.password) {
          error.password = 'Password is required';
        }
        this.setState({ error, isProcessing: false });
      }
    }
  };

  onSocialLoginSuccess = (user) => {
    socialLogin({
      account_id: user._profile.id,
      login_type: user._provider,
      token: user._token.idToken
        ? user._token.idToken
        : user._token.accessToken,
    })
      .then((res) => {
        localStorage.setItem('access_token', res.data.access_token);
        const userId = profileUtils.getUserId(res.data.access_token);
        if (userId) {
          getInfo(userId).then((res) => {
            this.props.setProfile(res.data);
          });
          this.props.closeModal();
        }
      })
      .catch((err) => {
        console.log(err.response);
      });
  };

  onSocialLoginFailure = () => {
    Swal.fire({
      title: 'Login Failed',
      icon: 'warning',
      showCancelButton: false,
      confirmButtonColor: '#ef692a',
      confirmButtonText: 'Ok',
    });
  };

  onkeyPress = (e) => {
    if (e.keyCode === 13) {
      this.onSubmit();
    }
  };

  render() {
    const { show, closeModal, goToRegister, goToForgot, lang } = this.props;
    const { data, error, isProcessing } = this.state;
    return (
      <Modal show={show} onHide={closeModal}>
        <Modal.Header closeButton>
          <Modal.Title>
            {language(lang).LOGIN} <strong>Mulas</strong>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <input
            className={`form-control ${
              error.username ? 'is-invalid' : 'auth-input'
            }`}
            name='username'
            value={data.username}
            onChange={(e) => {
              this.onChange(e);
            }}
            placeholder={language(lang).ENTER_YOUR_USERNAME}
          />
          {error.username && (
            <span className='invalid-feedback auth-input'>
              {error.username}
            </span>
          )}
          <input
            className='form-control auth-input'
            placeholder={language(lang).ENTER_YOUR_PASSWORD}
            onChange={(e) => {
              this.onChange(e);
            }}
            name='password'
            value={data.password}
            type='password'
            onKeyDown={this.onkeyPress}
          />
          <div className='mb-2'>
            <span className='action-link'>
              <small onClick={goToForgot}>
                {language(lang).FORGOT_YOUR_PASSWORD}
                {'?'}
              </small>
            </span>
          </div>
          <button className='signin-button' onClick={this.onSubmit}>
            {language(lang).LOGIN} <strong>Mulas</strong>
            {isProcessing && (
              <span
                className='spinner-border spinner-border-sm'
                role='status'
                aria-hidden='true'
              ></span>
            )}
          </button>
          <span className='form-separator text-center'>
            <i>or</i>
          </span>
          <div className='row new-row'>
            <div className='col'>
              <SocialButton
                className='facebook-button'
                provider='facebook'
                appId={process.env.REACT_APP_FACEBOOK_APP_ID}
                onLoginSuccess={this.onSocialLoginSuccess}
                onLoginFailure={this.onSocialLoginFailure}
              >
                {language(lang).CONTINUE_WITH_FACEBOOK}
              </SocialButton>
            </div>
          </div>
          <div className='row new-row'>
            <div className='col'>
              <SocialButton
                className='google-button'
                provider='google'
                appId={process.env.REACT_APP_GOOGLE_CLIENT_ID}
                onLoginSuccess={this.onSocialLoginSuccess}
                onLoginFailure={this.onSocialLoginFailure}
              >
                {language(lang).CONTINUE_WITH_GOOGLE}
              </SocialButton>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer className='auth-footer'>
          <span className='text-muted'>
            <small>
              <span className='action-link' onClick={goToRegister}>
                {language(lang).DID_NOT_HAVE_ACCOUNT}
              </span>
            </small>
          </span>
        </Modal.Footer>
      </Modal>
    );
  }
}

const mapStateToProps = (state) => ({
  lang: state.language.language,
});
const mapDispatchToProps = (dispatch) => ({
  setProfile: (data) => dispatch(setProfile(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Login);
