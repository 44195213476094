import React from "react";
import { Fragment } from "react";
import RatingStar from "./RatingStar";

import Button from "../UI/Button";

const RestaurantRating = (props) => {
  let rating = 0;

  const updateRatingHandler = (count) => {
    rating = count;
  };

  const submitRatingHandler = () => {
    props.onNext(rating);
  };

  return (
    <Fragment>
      <h2>How was the food?</h2>

      <h5>(Your feedback will help restaurant improve their services)</h5>

      <RatingStar onUpdate={updateRatingHandler} />

      <Button onClick={submitRatingHandler} isProcessing={props.isProcessing}>
        Next
      </Button>
    </Fragment>
  );
};

export default RestaurantRating;
