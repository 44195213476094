import React from 'react';
import { connect } from 'react-redux';

import MainLayout from './shared/MainLayout';
import { language } from '../constants';

const PrivacyPolicy = (props) => (
  <MainLayout>
    <section className='page-header'>
      <div className='container'>
        <h1>{language(props.lang).PRIVACY_POLICY}</h1>
      </div>
    </section>
    <section className='page-header'>
      <div className='container'>
        <p className='unset-margin'>
          Bytemantra Enterprises Oy and certain Bytemantra group companies
          (“Bytemantra”, “we”) process personal data of their users of
          Bytemantra’s food ordering services (“Mulas App”) and the visitors of
          the website mulas.fi (“Website”).
        </p>
        <p>
          In this Privacy Statement, the word “Bytemantra Services” refers
          jointly to the Website and the Bytemantra App. In this Privacy
          Statement, the word “User” or “you” refers jointly to our and our
          group companies’ customers, representatives and other authorized users
          of our customer organizations, potential customers and the users of
          the Bytemantra Services.
        </p>
        <p>
          Our Privacy Statement explains, for example, the types of personal
          data we process, how we process the personal data and how you may
          exercise your rights as a data subject (e.g. right to object, right of
          access).
        </p>
        <p>
          Some of our services might be subject to a separate privacy policy. If
          a separate privacy policy applies to a particular service, we will
          post it in connection with the service in question.
        </p>
        <p>
          This Privacy Statement may be updated from time to time in order to
          reflect the changes in data processing practices or otherwise. You can
          find the current version on the Website. We will not make substantial
          changes to this Privacy Statement or reduce the rights of the Users
          under this Privacy Statement without providing a notice thereof.
        </p>

        <p className='unset-margin'>
          <h3>1. DATA CONTROLLER(S)</h3>
        </p>
        <p className='unset-margin'>
          This Privacy Statement applies to processing of personal data carried
          out by Bytemantra.
        </p>
        <p>
          In relation to the processing of personal data of Users in the below
          countries, Bytemantra Oy and the local Bytemantra group company are
          acting as joint data controllers. This means that Bytemantra
          Enterprises Oy and the local Bytemantra group company together
          determine the purposes for and means by which personal data is
          processed.
        </p>
        <p>
          Bytemantra Enterprises Oy has been appointed responsible for handling
          all data subject requests and questions relating to the personal data
          processing of the Bytemantra group on behalf of the local joint
          controllers.
        </p>
        <p>
          In relation to the processing of personal data of Users in other
          countries than the ones listed above, Bytemantra Enterprises Oy acts
          as the sole data controller.
        </p>

        <p className='unset-margin'>
          <h3>2. BYTEMANTRA’S CONTACT DETAILS</h3>
        </p>
        <p className='unset-margin'>Name: Bytemantra Oy</p>
        <p className='unset-margin'>Business ID: 3006633-4 </p>
        <p className='unset-margin'>
          Correspondence address: Linnavuorentie 19, 00940 Helsinki, Finland{' '}
        </p>
        <p className='unset-margin'>E-mail address: info@mulas.fi </p>
        <p className='unset-margin'>
          <a href='https://bytemantra.com/'>Bytemantra.com</a>
        </p>
        <p>Contact person: Biplov Basnet</p>

        <p className='unset-margin'>
          <h3>3. PERSONAL DATA PROCESSED AND SOURCES OF DATA</h3>
        </p>
        <p>
          The personal data collected and processed by us can be divided into
          two general data categories: User Data and Analytics Data.
        </p>
        <p>
          <u>User Data</u>
        </p>
        <p>
          User Data is personal data collected directly from you or from our
          customer organization on behalf of which you are using the Bytemantra
          Services (“Customer Organization”), as the case may be. We may collect
          User Data from our Users and Customer Organizations in a variety of
          ways, including, after conclusion of a service agreement with the
          Customer Organization or when Users register to the Bytemantra
          Services, subscribe to a newsletter or fill out a form. Further,
          please note that we also collect details of any transactions and
          payments you carry out through the Bytemantra Services.
        </p>
        <p>
          <i>
            User Data that is necessary in order to use the Bytemantra Services
          </i>
        </p>
        <p className='unset-margin'>
          The following personal data collected and processed by us is necessary
          in order for a proper performance of the contract between you and us
          as well as for our legitimate interest whilst fulfilling our
          contractual obligations towards our Customer Organizations and for the
          compliance with our legal obligations.
        </p>
        <p className='unset-margin'>
          After conclusion of the service agreement between us and the Customer
          Organization, the Customer Organization provides us with your full
          name and email address.
        </p>
        <p className='unset-margin'>
          When you register to the Bytemantra Services and create a user
          account, you need to provide us with the following information:
        </p>
        <ul className='bullet-list'>
          <ul>
            <li>full name</li>
            <li>telephone number</li>
            <li>email address</li>
            <li>
              information relating to your payment instrument(s) such as the
              number of your payment instrument(s) and the expiration date of
              your payment instrument(s) (required for the purposes of ordering
              food products via the Bytemantra Services, however not stored by
              Bytemantra, since Bytemantra uses a third party payment service
              provider for processing of payments)
            </li>
          </ul>
        </ul>
        <p>
          <i>User Data you give us voluntarily</i>
        </p>
        <p>
          Your user or customer experience may be enhanced by providing us with
          the following information:
        </p>
        <h4>Additional Account Information:</h4>
        <ul>
          <li>a picture</li>
          <li>delivery address</li>
          <li>
            location data (if you consent to the processing of your location
            data)
          </li>
          <li>
            other information you provide either when creating a user account or
            later when modifying your account profile
          </li>
        </ul>
        <h4>
          Other Information. We may also process other information provided by
          you voluntarily such as:
        </h4>
        <ul>
          <li>information you provide when rating our services</li>
          <li>marketing opt-ins and opt-outs</li>
          <li>
            information you provide by phone or in email or chat correspondence
            with us, including call recordings of your calls with our customer
            service
          </li>
        </ul>
        <p className='unset-margin'>
          In addition to User Data collected from you and the Customer
          Organization, we process certain personal data third party service
          providers provide about you.
        </p>
        <p>
          If you connect or login to your account with Facebook, Facebook shares
          with us personal information about you such as your profile picture, a
          sample of your Facebook friends and your Facebook ID.{' '}
        </p>
        <p>
          <u>Analytics Data</u>
        </p>
        <p className='unset-margin'>
          Although we do not normally use Analytics Data to identify you as an
          individual, you can sometimes be recognized from it, either alone or
          when combined or linked with User Data. In such situations, Analytics
          Data can also be considered personal data under applicable laws and we
          will treat such data as personal data.
        </p>
        <p>
          We may automatically collect the following Analytics Data when you
          visit or interact with the Bytemantra Services:
        </p>
        <h4>Device Information</h4>
        <p className='unset-margin'>
          We collect the following information relating to the technical device
          you use when using the Bytemantra Services:
          <ul className='bullet-list'>
            <li>device and device identification number, device IMEI</li>
            <li>country</li>
            <li>IP address</li>
            <li>browser type and version</li>
            <li>operating system</li>
            <li>Internet service providers</li>
            <li>advertising identifier of your device</li>
            <li>visitor identifier</li>
          </ul>
        </p>
        <h4>Usage Information</h4>
        <p className='unset-margin'>
          We collect information on your use of the Bytemantra Services, such
          as:
        </p>
        <ul className='bullet-list'>
          <li>time spent on the Bytemantra Services</li>
          <li>interaction with the Bytemantra Services</li>
          <li>
            information on your orders made through the Bytemantra Services
          </li>
          <li>
            the URL of the website you visited before and after visiting the
            Bytemantra Services
          </li>
          <li>the time and date of your visits to the Bytemantra Services</li>
          <li>the sections of the Bytemantra Services you visited</li>
          <li>
            the products you searched for while using the Bytemantra Services
          </li>
        </ul>
        <p>
          <u>Cookies</u>
        </p>
        <p className='unset-margin'>
          We use various technologies to collect and store Analytics Data and
          other information when the Users visit the Bytemantra Services,
          including cookies and web beacons.
        </p>
        <p className='unset-margin'>
          Cookies are small text files sent and saved on your device that allows
          us to identify visitors of the Bytemantra Services and facilitate the
          use of the Bytemantra Services and to create aggregate information of
          our visitors. This helps us to improve the Bytemantra Services and
          better serve our Users. The cookies will not harm your device or
          files. We use cookies to tailor the Bytemantra Services and the
          information we provide in accordance with the individual interests of
          our Users
        </p>
        <p className='unset-margin'>
          The Users may choose to set their web browser to refuse cookies, or to
          alert when cookies are being sent.
        </p>
        <p className='unset-margin'>
          Please note that some parts of the Bytemantra Services may not
          function properly if use of cookies is refused.
        </p>
        <p>
          A web beacon is a technology that allows identifying readers of
          websites and emails e.g. for identifying whether an email has been
          read.
        </p>
        <p>
          <u>Web analytics services</u>
        </p>
        <p className='unset-margin'>
          The Bytemantra Services use pseudonymized identifiers to track and
          predict your app and service usage and preferences.
        </p>
        <p className='unset-margin'>
          You can opt-out of the use of these identifiers by changing your
          operating system settings as follows:
        </p>
        <ul className='bullet-list'>
          <li>
            Advertising identifiers can be disabled on iOS mobile devices by
            turning on the Limit Ad Tracking tab (Settings → Privacy →
            Advertising → Limit Ad Tracking). For an overview and more
            information on the advertising identifier, please see Apple
            Advertising and Privacy site.
          </li>
          <li>
            Bytemantra visitor identifiers can be disabled on iOS and Android
            mobile devices by changing your settings (for iOS: Settings →
            Bytemantra → Bytemantra settings → Limit Tracking, and for Android:
            Bytemantra application → Profile tab → Settings icon in the
            top-right corner).
          </li>
        </ul>
        <p className='unset-margin'>
          <h3>
            4. PURPOSES AND LEGITIMATE GROUNDS FOR THE PROCESSING OF YOUR
            PERSONAL DATA
          </h3>
        </p>
        <p className='unset-margin'>
          <u>Purposes</u>
        </p>
        <p>
          There are several purposes of the processing of your personal data by
          Bytemantra:
        </p>
        <p className='unset-margin'>
          <i>
            To provide the Bytemantra Services and carry out our contractual
            obligations (legal ground: performance of a contract and legitimate
            interest)
          </i>
        </p>
        <p className='unset-margin'>
          Bytemantra processes your personal data to be able to offer the
          Bytemantra Services to you under the contract between you and
          Bytemantra or between Customer Organization and Bytemantra.
        </p>
        <p>
          We use the data for example to handle your payments or any refunds
          (where applicable) and to provide our partners (the restaurants and
          our courier partners) with the information necessary for the
          preparation or delivery of your order. If you contact our customer
          service, we will use the information provided by you to answer your
          questions or solve your complaint.
        </p>
        <p className='unset-margin'>
          <i>
            For our legal obligations (legal ground: compliance with a legal
            obligation)
          </i>
        </p>
        <p>
          Bytemantra processes data to enable us to administer and fulfil our
          obligations under law. This includes data processed for complying with
          our bookkeeping obligations and providing information to relevant
          authorities such as tax authorities.
        </p>
        <p className='unset-margin'>
          <i>
            For claims handling and legal processes (legal ground: legitimate
            interest)
          </i>
        </p>
        <p>
          We may also process information about your use of the Bytemantra
          Services to improve the quality of the Bytemantra Services e.g. by
          analyzing any trends in the use of the Bytemantra Services. In order
          to ensure that our services are in line with your needs, personal data
          can be used for things like customer satisfaction surveys. When
          possible, we will do this using only aggregated, non-personally
          identifiable data.
        </p>
        <p className='unset-margin'>
          <u>Legal grounds for processing</u>
        </p>
        <p className='unset-margin'>
          Bytemantra processes your personal data to perform our contractual
          obligations towards you or the Customer Organization and to comply
          with legal obligations. Furthermore, we process your personal data to
          pursue our legitimate interest to run, maintain and develop our
          business and to create and maintain customer relationships. When
          choosing to use your data on the basis of our legitimate interests, we
          weigh our own interests against your right to privacy and e.g. provide
          you with easy to use opt-out from our marketing communications and use
          pseudonymized or non-personally identifiable data when possible.
        </p>
        <p>
          In some parts of the Bytemantra Services, you may be requested to
          grant your consent for the processing of personal data. In this event,
          you may withdraw your consent at any time.
        </p>
        <p className='unset-margin'>
          <h3>5. RECIPIENTS</h3>
        </p>
        <p className='unset-margin'>
          We only share your personal data within the organization of Bytemantra
          if and as far as reasonably necessary for the purposes of this Privacy
          Statement.
        </p>
        <p>
          We do not share your personal data with third parties outside of
          Bytemantra’s organization unless one of the following circumstances
          applies:
        </p>
        <p className='unset-margin'>
          <i>
            For the purposes set out in this Privacy Statement and to authorized
            service providers
          </i>
        </p>
        <p className='unset-margin'>
          To the extent that third parties (such as the restaurants which
          prepare your order, our courier partners who deliver your order and
          Customer Organization which may pay your order) need access to
          personal data in order for us to perform the Bytemantra Services, we
          provide such third parties with your data. Furthermore, we may provide
          your personal data to our affiliates or to authorized service
          providers who perform services for us (including data storage,
          accounting, sales and marketing) to process it for us and to payment
          service providers to process your payments to us.
        </p>
        <p className='unset-margin'>
          When data is processed by third parties on behalf of Bytemantra,
          Bytemantra has taken the appropriate contractual and organizational
          measures to ensure that your data are processed exclusively for the
          purposes specified in this Privacy Statement and in accordance with
          all applicable laws and regulations and subject to our instructions
          and appropriate obligations of confidentiality and security measures.
        </p>
        <p>
          Please bear in mind that if you provide personal data directly to a
          third party, such as through a link in the Bytemantra Services, the
          processing is typically based on their policies and standards.
        </p>
        <p className='unset-margin'>
          <i>For legal reasons and legal processes</i>
        </p>
        <p>
          We may share your personal data with third parties outside Bytemantra
          if we have a good-faith belief that access to and use of the personal
          data is reasonably necessary to: (i) meet any applicable law,
          regulation, and/or court order; (ii) detect, prevent, or otherwise
          address fraud, crime, security or technical issues; and/or (iii)
          protect the interests, properties or safety of Bytemantra, the Users
          or the public as far as in accordance with the law. When possible, we
          will inform you about such processing.
        </p>
        <p className='unset-margin'>
          <i>For other legitimate reasons</i>
        </p>
        <p>
          If Bytemantra is involved in a merger, acquisition or asset sale, we
          may transfer your personal data to the third party involved. However,
          we will continue to ensure the confidentiality of all personal data.
          We will give notice to all the Users concerned when the personal data
          are transferred or become subject to a different privacy statement.
        </p>
        <p className='unset-margin'>
          <i>With your explicit consent</i>
        </p>
        <p>
          We may share your personal data with third parties outside Bytemantra
          when we have your explicit consent to do so. You have the right to
          withdraw this consent at all times free of charge by contacting us.
        </p>
        <p className='unset-margin'>
          <h3>6. YOUR RIGHTS</h3>
        </p>
        <p className='unset-margin'>
          <i>Right of access</i>
        </p>
        <p>
          You have the right to access and be informed about your personal data
          processed by us. We give you the possibility to view certain data
          through your user account with the Bytemantra Services or request a
          copy of your personal data.
        </p>
        <p className='unset-margin'>
          <i>Right to withdraw consent</i>
        </p>
        <p>
          In case the processing is based on a consent granted by the User, the
          User may withdraw the consent at any time free of charge. Withdrawing
          a consent may lead to fewer possibilities to use the Bytemantra
          Services. The withdrawal of consent does not affect the lawfulness of
          processing based on consent before its withdrawal.
        </p>
        <p className='unset-margin'>
          <i>Right to rectify</i>
        </p>
        <p>
          You have the right to have incorrect or incomplete personal data we
          have stored about you corrected or completed by contacting us. You can
          correct or update some of your personal data through your user account
          in the Bytemantra Services.
        </p>
        <p className='unset-margin'>
          <i>Right to erasure</i>
        </p>
        <p>
          You may also ask us to delete your personal data from our systems. We
          will comply with such request unless we have a legitimate ground to
          not delete the data.
        </p>
        <p className='unset-margin'>
          <i>Right to object</i>
        </p>
        <p>
          You may have the right to object to certain use of your personal data
          if such data are processed for other purposes than necessary for the
          performance of the Bytemantra Services or for compliance with a legal
          obligation. If you object to the further processing of your personal
          data, this may lead to fewer possibilities to use the Bytemantra
          Services.
        </p>
        <p className='unset-margin'>
          <i>Right to restriction of processing</i>
        </p>
        <p>
          You may request us to restrict processing of personal data for example
          when your data erasure, rectification or objection requests are
          pending and/or when we do not have legitimate grounds to process your
          data. This may however lead to fewer possibilities to use the
          Bytemantra Services.
        </p>
        <p className='unset-margin'>
          <i>How to use your rights</i>
        </p>
        <p>
          You have the right to receive the personal data you have provided to
          us yourself in a structured and commonly used format and to
          independently transmit those data to a third party.
        </p>
        <p className='unset-margin'>
          <i>How to use your rights</i>
        </p>
        <p>
          The abovementioned rights may be used by sending a letter or an e-mail
          to us on the addresses set out above, including the following
          information: the full name, address, e-mail address and a phone
          number. We may request the provision of additional information
          necessary to confirm the identity of the User. We may reject or charge
          requests that are unreasonably repetitive, excessive or manifestly
          unfounded.
        </p>
      </div>
    </section>
  </MainLayout>
);

const mapStateToProps = (state) => ({
  lang: state.language.language,
});
const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(PrivacyPolicy);
