import React from 'react';
import SocialLogin from 'react-social-login';
import { GrFacebookOption, GrGoogle } from 'react-icons/gr';

class SocialButton extends React.Component {
  render() {
    const { className } = this.props;
    return (
      <button onClick={this.props.triggerLogin} {...this.props}>
        {className === 'facebook-button' ? (
          <React.Fragment>
            <GrFacebookOption
              style={{ marginRight: '10px', marginTop: '-3px' }}
            />
            {this.props.children}
          </React.Fragment>
        ) : (
          <React.Fragment>
            <GrGoogle style={{ marginRight: '10px', marginTop: '-3px' }} />
            {this.props.children}
          </React.Fragment>
        )}
      </button>
    );
  }
}

export default SocialLogin(SocialButton);
