import React, { Component } from 'react';
import SlidingPane from 'react-sliding-pane';
import { MdArrowBack } from 'react-icons/md';
import { connect } from 'react-redux';

import CartItems from './CartItems';
import EmptyCart from './EmptyCart';

import 'react-sliding-pane/dist/react-sliding-pane.css';

class Cart extends Component {
  state = {
    delivery_type: '',
    delivery_address: '',
  };

  onChangeDeliveryType = (e) => {
    this.setState({
      delivery_type: e.target.value,
    });
  };

  onChangeDeliveryAddress = (e) => {
    this.setState({
      delivery_address: e.target.value,
    });
  };

  render() {
    const { isOpen, closeCart, cartData } = this.props;

    return (
      <SlidingPane
        closeIcon={<MdArrowBack />}
        isOpen={isOpen}
        from='right'
        width={window.orientation > -1 ? '100vw' : '400px'}
        onRequestClose={closeCart}
      >
        {cartData.items.length > 0 ? (
          <React.Fragment>
            <div className='row'>
              <div className='col-sm-12 text-center'>
                <span className='cart-title'>
                  {cartData.restaurantInfo.name || '-'}
                </span>
              </div>
            </div>
            <CartItems data={cartData} />
          </React.Fragment>
        ) : (
          <EmptyCart />
        )}
      </SlidingPane>
    );
  }
}

const mapStateToProps = (state) => ({
  cartData: state.cartData,
});
const mapDispatchToProps = () => ({});

export default connect(mapStateToProps, mapDispatchToProps)(Cart);
