import moment from 'moment';
import { connect } from 'react-redux';
import queryString from 'query-string';
import React, { useEffect, useState } from 'react';

import { MapWithDirection } from '../utils/map';
import { language } from '../constants';
import MainLayout from './shared/MainLayout';
import driver from '../assets/images/driver.png';
import * as services from '../services/orders';
import { resetCart } from '../actions/carts';
import OrderRating from './review/OrderRating';
import { setCurrentOrder } from '../actions/orders';

const TrackOrder = (props) => {
  const [data, setData] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [showOrderRating, setShowOrderRating] = useState(false);
  const [reviews, setReviews] = useState([]);
  const [isReviewLoading, setIsReviewLoading] = useState(true);
  const [isSkipped, setIsSkipped] = useState(false);
  const [isDelivered, setIsDelivered] = useState(false);
  const [isPickup, setIsPickup] = useState(false);
  const [status, setStatus] = useState('');

  useEffect(() => {
    const params = queryString.parse(props.location.search);
    if (params.success === 'true') {
      props.resetCart();
      localStorage.removeItem('new_order');
      localStorage.removeItem('full_order');
      localStorage.removeItem('PARSED_RESTAURANT_ORDER');
      localStorage.removeItem('FULL_RESTAURANT_ORDER');
      localStorage.removeItem('FULL_NEW_ORDER');
      localStorage.removeItem('PARSED_NEW_ORDER');
    }
    services
      .fetchOrderById(props.match.params.id)
      .then((res) => {
        props.setCurrentOrder(res.data);
        setData(res.data);
        setIsSkipped(!!res.data.skipped_review);
        if (res.data.delivery_type === 'driver') {
          setIsDelivered(res.data.status === 'delivery');
        } else {
          setIsDelivered(res.data.status === 'ready');
        }
        setIsLoading(false);
        setStatus(res.data.status);
        setIsPickup(res.data.delivery_type !== 'driver');
      })
      .catch((err) => {
        props.history.push('/order-history');
      });

    services
      .getReview(props.match.params.id)
      .then((res) => {
        setReviews(res.data);
      })
      .catch(() => {})
      .finally(() => {
        setIsReviewLoading(false);
      });

    const interval = setInterval(() => {
      services.fetchOrderById(props.match.params.id).then((res) => {
        setData(res.data);
      });
    }, 60000);

    return () => clearInterval(interval);
  }, []);

  if (reviews.length === 0 && !isReviewLoading && !isSkipped && isDelivered) {
    return (
      <OrderRating
        resetCurrentOrder={props.setCurrentOrder}
        deliveryType={data.delivery_type}
      />
    );
  }

  const latitude = data.driver ? parseFloat(data.driver.location.latitude) : 0;
  const longitude = data.driver
    ? parseFloat(data.driver.location.longitude)
    : 0;

  return (
    <MainLayout>
      <section className='page-header'>
        <div className='container'>
          <h1>{`${language(props.lang).TRACK_ORDER} # ${data.id || ''}`}</h1>
        </div>
      </section>
      <section className='page-content'>
        <div className='container'>
          {data.kitchen_time && data.estimate_delivery_time ? (
            <React.Fragment>
              <div className='row'>
                <div className='col-sm-12'>
                  <i className='fa fa-clock' style={{ color: '#ef692a' }}></i>{' '}
                  <span style={{ color: '#ef692a' }}>
                    <b>
                      {language(props.lang).ESTIMATED_TIME}:{' '}
                      {moment(data.estimate_delivery_time).format('hh:mm A')}
                    </b>{' '}
                    <small>
                      {moment(data.estimate_delivery_time).format('MMMM Do')}
                    </small>
                  </span>
                </div>
              </div>
              <hr />
            </React.Fragment>
          ) : null}
          <div className='row'>
            <div className='col-sm-12 col-md-5'>
              {data.created_at ? (
                <div className='d-flex' style={{ alignItems: 'center' }}>
                  <div className='active-track-button'></div>
                  <div className='track-label text-muted'>
                    {language(props.lang).ORDER_PLACED}
                    <small>
                      &nbsp;{moment(data.created_at).format('Do MMM, hh:mm A')}
                    </small>
                  </div>
                </div>
              ) : null}
              {data.pre_order_status === 'accepted' ? (
                <div className='d-flex' style={{ alignItems: 'center' }}>
                  <div className='active-track-button'></div>
                  <div className='track-label text-muted'>
                    {language(props.lang).ACCEPTED_BY_RESTAURANT}
                  </div>
                </div>
              ) : null}
              {data.kitchen_receive_at ? (
                <React.Fragment>
                  <div className='d-flex'>
                    <div className='vertical-line'></div>
                  </div>
                  <div className='d-flex' style={{ alignItems: 'center' }}>
                    <div className='active-track-button'></div>
                    <div className='track-label text-muted'>
                      {language(props.lang).ORDER_RECEIVED_BY_RESTAURANT}
                      <small>
                        &nbsp;
                        {moment(data.kitchen_receive_at).format(
                          'Do MMM, hh:mm A'
                        )}
                      </small>
                    </div>
                  </div>
                </React.Fragment>
              ) : null}
              {data.kitchen_delivery_at ? (
                <React.Fragment>
                  <div className='d-flex'>
                    <div className='vertical-line'></div>
                  </div>
                  <div className='d-flex' style={{ alignItems: 'center' }}>
                    <div className='active-track-button'></div>
                    <div className='track-label text-muted'>
                      {language(props.lang).ORDER_READY_BY_RESTAURANT}
                      <small>
                        &nbsp;
                        {moment(data.kitchen_delivery_at).format(
                          'Do MMM, hh:mm A'
                        )}
                      </small>
                    </div>
                  </div>
                </React.Fragment>
              ) : null}
              {data.delivery_type === 'driver' ? (
                <React.Fragment>
                  {data.pickup_time ? (
                    <React.Fragment>
                      <div className='d-flex'>
                        <div className='vertical-line'></div>
                      </div>
                      <div className='d-flex' style={{ alignItems: 'center' }}>
                        <div className='active-track-button'></div>
                        <div className='track-label text-muted'>
                          {language(props.lang).ORDER_PICKED_BY_DRIVER}
                          <small>
                            &nbsp;
                            {moment(data.pickup_time).format('Do MMM, hh:mm A')}
                          </small>
                        </div>
                      </div>
                    </React.Fragment>
                  ) : null}
                  {data.delivery_time ? (
                    <React.Fragment>
                      <div className='d-flex'>
                        <div className='vertical-line'></div>
                      </div>
                      <div className='d-flex' style={{ alignItems: 'center' }}>
                        <div className='active-track-button'></div>
                        <div className='track-label text-muted'>
                          {language(props.lang).ORDER_DELIVERED}
                          <small>
                            &nbsp;
                            {moment(data.delivery_time).format(
                              'Do MMM, hh:mm A'
                            )}
                          </small>
                        </div>
                      </div>
                    </React.Fragment>
                  ) : null}
                </React.Fragment>
              ) : null}
            </div>
            <div className='col-sm-12 col-md-7'>
              {!isLoading ? (
                <MapWithDirection
                  id='restaurant-map'
                  center={{
                    lat: parseFloat(
                      data.driver ? data.driver.location.latitude : 0
                    ),
                    lng: parseFloat(
                      data.driver ? data.driver.location.latitude : 0
                    ),
                  }}
                  zoom={8}
                  origin={`${
                    data.restaurant
                      ? `${data.restaurant.address}, ${data.restaurant.country.name}`
                      : ''
                  }`}
                  destination={`${data.delivery_address},${data.city ? `${data.city},` : ''}${
                    data.country ? data.country.name : ''
                  }`}
                  onMapLoad={(map) => {
                    if (
                      data.delivery_type === 'driver' &&
                      data.driver.location
                    ) {
                      new window.google.maps.Marker({
                        icon: driver,
                        position: {
                          lat: latitude,
                          lng: longitude,
                        },
                        map: map,
                        title: 'Driver Location',
                      });
                    }
                  }}
                />
              ) : null}
            </div>
          </div>
        </div>
      </section>
    </MainLayout>
  );
};

const mapStateToProps = (state) => ({
  lang: state.language.language,
});
const mapDispatchToProps = (dispatch) => ({
  resetCart: () => dispatch(resetCart()),
  setCurrentOrder: (data) => dispatch(setCurrentOrder(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(TrackOrder);
