import React from 'react';
import loader from '../../assets/images/eclipse.gif';

const ComponentLoader = () => (
  <div className='d-flex justify-content-center'>
    <img src={loader} alt='loader' />
  </div>
);

export default ComponentLoader;
