import React, { Component } from 'react';
import restaurant from '../assets/images/restaurant.png'

class Map extends Component {
  constructor(props) {
    super(props);
    this.onScriptLoad = this.onScriptLoad.bind(this);
  }

  onScriptLoad() {
    const map = new window.google.maps.Map(
      document.getElementById(this.props.id),
      this.props.options
    );
    this.props.onMapLoad(map);
  }

  componentDidMount() {
    if (!window.google) {
      var s = document.createElement('script');
      s.type = 'text/javascript';
      s.src = `https://maps.google.com/maps/api/js?key=${process.env.REACT_APP_GOOGLE_MAP_KEY}`;
      var x = document.getElementsByTagName('script')[0];
      x.parentNode.insertBefore(s, x);
      s.addEventListener('load', (e) => {
        this.onScriptLoad();
      });
    } else {
      this.onScriptLoad();
    }
  }

  render() {
    return <div style={{ width: '100%', height: '36vh' }} id={this.props.id} />;
  }
}

export default Map;

export class MapWithDirection extends Component {
  constructor(props) {
    super(props);
    this.onScriptLoad = this.onScriptLoad.bind(this);
  }

  onScriptLoad() {
    const directionsService = new window.google.maps.DirectionsService();
    const directionsRenderer = new window.google.maps.DirectionsRenderer({
      suppressMarkers: true,
    });
    const map = new window.google.maps.Map(
      document.getElementById(this.props.id),
      {
        zoom: this.props.zoom,
        center: this.props.center,
        styles: [
          {
            featureType: 'water',
            stylers: [{ color: '#46bcec' }, { visibility: 'on' }],
          },
          { featureType: 'landscape', stylers: [{ color: '#f2f2f2' }] },
          {
            featureType: 'road',
            stylers: [{ saturation: -100 }, { lightness: 45 }],
          },
          {
            featureType: 'road.highway',
            stylers: [{ visibility: 'simplified' }],
          },
          {
            featureType: 'road.arterial',
            elementType: 'labels.icon',
            stylers: [{ visibility: 'off' }],
          },
          {
            featureType: 'administrative',
            elementType: 'labels.text.fill',
            stylers: [{ color: '#444444' }],
          },
          { featureType: 'transit', stylers: [{ visibility: 'off' }] },
          { featureType: 'poi', stylers: [{ visibility: 'off' }] },
        ],
      }
    );

    // const start = new window.google.maps.LatLng(this.props.origin.lat, this.props.origin.lng)

    directionsRenderer.setMap(map);
    directionsService.route(
      {
        origin: {
          query: this.props.origin,
        },
        destination: {
          query: this.props.destination,
        },
        travelMode: window.google.maps.TravelMode.DRIVING,
      },
      (response, status) => {
        if (status === 'OK') {
          directionsRenderer.setDirections(response);
          // add custom markers
          var route = response.routes[0];
          // start marker
          addMarker(route.legs[0].start_location);
        }
      }
    );

    const addMarker = (position) => {
      return new window.google.maps.Marker({
        icon: restaurant,
        size: 20,
        position: position,
        map,
        title: 'Restaurant',
      });
    };
    this.props.onMapLoad(map);
  }

  componentDidMount() {
    if (!window.google) {
      var s = document.createElement('script');
      s.type = 'text/javascript';
      s.src = `https://maps.google.com/maps/api/js?key=${process.env.REACT_APP_GOOGLE_MAP_KEY}`;
      var x = document.getElementsByTagName('script')[0];
      x.parentNode.insertBefore(s, x);
      s.addEventListener('load', (e) => {
        this.onScriptLoad();
      });
    } else {
      this.onScriptLoad();
    }
  }

  render() {
    return <div style={{ width: '100%', height: '36vh' }} id={this.props.id} />;
  }
}
