import React, { Component } from 'react';
import { fetchOrders } from '../services/orders';

export function withOrderHistory(WrappedComponent) {
  return class OrderHistory extends Component {
    state = {
      isFetchingOrderHistory: false,
      orders: [],
      from: 1,
      lastPage: 1,
      currentPage: 1,
      nextPageUrl: '',
      prevPageUrl: '',
      filters: [],
    };

    getOrders = () => {
      const { filters, currentPage } = this.state;
      this.setState({ isFetchingOrderHistory: true });
      fetchOrders(filters, currentPage)
        .then((res) => {
          this.setState({ isFetchingOrderHistory: false });
          if (res.status === 200) {
            this.setState({
              orders: res.data.data,
              from: res.data.from,
              lastPage: res.data.last_page,
              nextPageUrl: res.data.next_page_url,
              prevPageUrl: res.data.prev_page_url,
            });
          }
        })
        .catch((err) => {
          this.setState({ isFetchingOrderHistory: false });
        });
    };

    changePagination = (page) => {
      this.setState({ currentPage: page }, () => {
        this.getOrders();
      });
    };

    changeStatus = (status) => {
      let filters = [
        {
          key: 'status',
          value: status,
        },
      ];
      this.setState(
        {
          filters,
        },
        () => {
          this.getOrders();
        }
      );
    };

    render() {
      const {
        currentPage,
        from,
        isFetchingOrderHistory,
        lastPage,
        orders,
        nextPageUrl,
        prevPageUrl,
      } = this.state;
      return (
        <WrappedComponent
          currentPage={currentPage}
          from={from}
          isFetchingOrderHistory={isFetchingOrderHistory}
          lastPage={lastPage}
          orders={orders}
          nextPageUrl={nextPageUrl}
          prevPageUrl={prevPageUrl}
          getOrders={this.getOrders}
          changePagination={this.changePagination}
          changeStatus={this.changeStatus}
          {...this.props}
        />
      );
    }
  };
}
