import React, { Component } from 'react';
import { Modal } from 'react-bootstrap';
import Swal from 'sweetalert2';
import { connect } from 'react-redux';
import PhoneInput from 'react-phone-number-input';

import { createUser } from '../../services/auth';
import { getInfo } from '../../services';
import { language } from '../../constants';
import { profileUtils } from '../../utils/profile';
import { setProfile } from '../../actions/profile';

class Register extends Component {
  state = {
    data: { ...this.data },
    error: { ...this.data },
    isProcessing: false,
  };

  data = {
    password: '',
    first_name: '',
    last_name: '',
    email: '',
    address: '',
    phone_no: '',
    mobile_no: '',
    zip_code: '',
  };

  onChange = (e) => {
    const { name, value } = e.target;
    let { data, error } = this.state;
    data[name] = value;
    error[name] = '';
    this.setState({ data, error });
  };

  onSubmit = () => {
    if (!this.state.isProcessing) {
      this.setState({
        isProcessing: true,
      });
      createUser(this.state.data)
        .then((res) => {
          this.setState({
            isProcessing: false,
            data: { ...this.data },
            error: { ...this.data },
          });
          localStorage.setItem('access_token', res.data.access_token);
          const userId = profileUtils.getUserId(res.data.access_token);
          if (userId) {
            getInfo(userId).then((res) => {
              this.props.setProfile(res.data);
            });
            this.props.closeModal();
          }
        })
        .catch((err) => {
          if (err.response.status === 422) {
            this.setState({
              error: err.response.data,
              isProcessing: false,
            });
          }
          if (err.response.status === 500) {
            this.setState({
              isProcessing: false,
            });
            Swal.fire({
              title: '500',
              text: 'Something went wrong',
              icon: 'error',
            });
          }
        });
    }
  };

  onMobileNumberChange = (value) => {
    let { data, error } = this.state;
    error['mobile_no'] = '';
    data['mobile_no'] = value;
    this.setState({
      data,
      error,
    });
  };

  render() {
    const { show, closeModal, goToLogin, lang } = this.props;
    const { data, error, isProcessing } = this.state;
    return (
      <Modal show={show} onHide={closeModal}>
        <Modal.Header closeButton>
          <Modal.Title>
            {language(lang).REGISTER_IN} <strong>Mulas</strong>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <FormRow
            name='first_name'
            value={data.first_name}
            placeholder={language(lang).ENTER_YOUR_FIRST_NAME}
            error={error.first_name}
            onChange={this.onChange}
          />
          <FormRow
            name='last_name'
            value={data.last_name}
            placeholder={language(lang).ENTER_YOUR_LAST_NAME}
            error={error.last_name}
            onChange={this.onChange}
          />
          <FormRow
            name='email'
            value={data.email}
            placeholder={language(lang).ENTER_YOUR_EMAIL_ADDRESS}
            error={error.email}
            onChange={this.onChange}
          />
          <FormRow
            name='password'
            value={data.password}
            placeholder={language(lang).ENTER_YOUR_PASSWORD}
            error={error.password}
            onChange={this.onChange}
            type='password'
          />
          <FormRow
            name='address'
            value={data.address}
            placeholder={language(lang).ENTER_YOUR_ADDRESS}
            error={error.address}
            onChange={this.onChange}
          />
          <FormRow
            name='city'
            value={data.city}
            placeholder={language(lang).CITY}
            error={error.city}
            onChange={this.onChange}
          />
          <PhoneInput
            name='mobile_no'
            onChange={this.onMobileNumberChange}
            placeholder={language(lang).ENTER_YOUR_PHONE_NUMBER}
            defaultCountry='FI'
            value={data.mobile_no}
          />
          {error.mobile_no && (
            <div className='invalid-feedback auth-input'>{error.mobile_no}</div>
          )}
          <FormRow
            name='zip_code'
            value={data.zip_code}
            placeholder={language(lang).ENTER_YOUR_ZIP_CODE}
            error={error.zip_code}
            onChange={this.onChange}
            type='number'
            min={0}
          />
          <button className='signin-button' onClick={this.onSubmit}>
            {language(lang).REGISTER_IN} <strong>Mulas</strong>
            {isProcessing && (
              <span
                className='spinner-border spinner-border-sm'
                role='status'
                aria-hidden='true'
              ></span>
            )}
          </button>
        </Modal.Body>
        <Modal.Footer className='auth-footer'>
          <span className='text-muted'>
            <small>
              {language(lang).ALREADY_HAVE_AN_ACCOUNT}{' '}
              <span className='action-link' onClick={goToLogin}>
                {language(lang).LOGIN}
              </span>
            </small>
          </span>
        </Modal.Footer>
      </Modal>
    );
  }
}

const mapStateToProps = (state) => ({
  lang: state.language.language,
});
const mapDispatchToProps = (dispatch) => ({
  setProfile: (data) => dispatch(setProfile(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Register);

const FormRow = ({ name, type, onChange, error, placeholder, min, value }) => (
  <React.Fragment>
    <input
      name={name}
      className={`form-control ${error ? 'is-invalid' : 'auth-input'}`}
      type={type || 'text'}
      onChange={onChange}
      placeholder={placeholder}
      min={min}
      value={value}
    />
    {error && <div className='invalid-feedback auth-input'>{error}</div>}
  </React.Fragment>
);
