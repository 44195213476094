import React, { useState, useEffect } from 'react';
import { loadStripe } from '@stripe/stripe-js';
import {
  CardElement,
  Elements,
  useElements,
  useStripe,
  PaymentRequestButtonElement,
} from '@stripe/react-stripe-js';
import Swal from 'sweetalert2';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';

import store from '../Store';
import { resetOrder } from '../actions/orders';
import { finnishNumber } from '../utils/common';
import { language } from '../constants';
import paytrailImg from '../assets/images/paytrail.png';

const CARD_OPTIONS = {
  iconStyle: 'solid',
  hidePostalCode: true,
  style: {
    base: {
      iconColor: '#c4f0ff',
      color: '#000000',
      fontWeight: 500,
      fontFamily: 'Poppins',
      fontSize: '14px',
    },
    invalid: {
      iconColor: '#dc3545',
      color: '#dc3545',
    },
  },
};

const CardField = ({ onChange, label = '' }) => (
  <div
    style={{
      border: '1px solid #ccc',
      borderRadius: '4px',
      height: '34px',
      padding: '6px 12px',
      marginBottom: '5px',
    }}
  >
    <CardElement options={CARD_OPTIONS} onChange={onChange} />
  </div>
);

const CheckoutForm = (props) => {
  const stripe = useStripe();
  const elements = useElements();
  const [error, setError] = useState(null);
  const [cardComplete, setCardComplete] = useState(false);
  const [processing, setProcessing] = useState(false);
  const [billingDetails, setBillingDetails] = useState({
    email: '',
    phone: '',
    name: '',
    address: { line1: '', line2: '', city: '' },
  });

  const [paymentRequest, setPaymentRequest] = useState(null);
  const [distance, setDistance] = useState('');
  const [phoneError, setPhoneError] = useState(false);
  const [lang, setLang] = useState('fin');
  const [isCard, setIsCard] = useState(false);

  useEffect(() => {
    if (stripe) {
      const pr = stripe.paymentRequest({
        country: 'FI',
        currency: 'eur',
        total: {
          label: 'Total',
          amount: parseInt(props.data.total * 100 + ''),
        },
        requestPayerName: true,
        requestPayerEmail: true,
      });

      // Check the availability of the Payment Request API.
      pr.canMakePayment().then((result) => {
        if (result) {
          setPaymentRequest(pr);
        }
      });

      pr.on('paymentmethod', async (ev) => {
        // Confirm the PaymentIntent without handling potential next actions (yet).
        const { error: confirmError } = await stripe.confirmCardPayment(
          props.client_key,
          { payment_method: ev.paymentMethod.id },
          { handleActions: false }
        );

        if (confirmError) {
          // Report to the browser that the payment failed, prompting it to
          // re-show the payment interface, or show an error message and close
          // the payment interface.
          ev.complete('fail');
        } else {
          // Report to the browser that the confirmation was successful, prompting
          // it to close the browser payment method collection interface.
          ev.complete('success');
          // Let Stripe.js handle the rest of the payment flow.
          const { error } = await stripe.confirmCardPayment(props.client_key);
          if (error) {
            // The payment failed -- ask your customer for a new payment method.

            Swal.fire({
              title: 'Payment Unsuccessful',
              text: 'Please choose another payment method.',
              icon: 'error',
              confirmButtonColor: '#ef692a',
              confirmButtonText: 'Ok',
            });
          } else {
            Swal.fire({
              title: 'Payment Successful',
              icon: 'success',
              confirmButtonColor: '#ef692a',
              confirmButtonText: 'Ok',
            }).then((result) => {
              if (result.value) {
                store.dispatch(resetOrder());
                props.redirectToHome();
                props.onResetCart();
              }
            });
          }
        }
      });
    }
  }, [stripe]);

  useEffect(() => {
    setBillingDetails({ ...props.billingDetails });
    if (props.billingDetails.phone.length === 10) {
      setPhoneError(false);
    } else {
      setPhoneError(true);
    }
  }, [props.billingDetails]);

  useEffect(() => {
    let data = props.data;
    setDistance(
      data.customer_restaurant_distance
        ? data.customer_restaurant_distance.distance
        : ''
    );
  }, [props.data]);

  useEffect(() => {
    setLang(props.lang);
  }, [props.lang]);

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (phoneError) {
      return;
    }

    if (!stripe || !elements) {
      // Stripe.js has not loaded yet. Make sure to disable
      // form submission until Stripe.js has loaded.
      return;
    }

    if (error) {
      elements.getElement('card').focus();
      return;
    }
    if (cardComplete) {
      setProcessing(true);
    }

    const payload = await stripe.createPaymentMethod({
      type: 'card',
      card: elements.getElement(CardElement),
      billing_details: billingDetails,
    });

    setProcessing(false);

    if (payload.error) {
      setError(payload.error);
    } else {
      setProcessing(true);
      const completePayment = await stripe.confirmCardPayment(
        props.client_key,
        {
          payment_method: payload.paymentMethod.id,
        }
      );
      if (completePayment.error) {
        Swal.fire({
          title: 'Payment Unsuccessful',
          text: completePayment.error.message || 'Payment Failed',
          icon: 'error',
          confirmButtonColor: '#ef692a',
          confirmButtonText: 'Ok',
        });
        setProcessing(false);
      } else {
        Swal.fire({
          title: 'Payment Successful',
          icon: 'success',
          confirmButtonColor: '#ef692a',
          confirmButtonText: 'Ok',
        }).then((result) => {
          if (result.value) {
            store.dispatch(resetOrder());
            props.redirectToHome();
            props.onResetCart();
          }
        });
      }
    }
  };

  const handlePayTrailSubmit = () => {
    setProcessing(true);
    const { paytrail } = props;
    if (paytrail.href) {
      window.location.href = props.paytrail.href;
      return null;
    }
    let form = document.createElement('form');
    form.setAttribute('method', 'POST');
    form.setAttribute('action', 'https://payment.paytrail.com/channel-payment');

    for (let key in paytrail) {
      if (key === 'items') {
        paytrail.items.forEach((item, o) => {
          Object.keys(item).forEach((itemKey) => {
            let hiddenField = document.createElement('input');
            hiddenField.setAttribute('type', 'hidden');
            hiddenField.setAttribute('name', `${itemKey}[${o}]`);
            hiddenField.setAttribute('value', paytrail.items[o][itemKey]);
            form.appendChild(hiddenField);
          });
        });
      } else {
        let hiddenField = document.createElement('input');
        hiddenField.setAttribute('type', 'hidden');
        hiddenField.setAttribute('name', key);
        hiddenField.setAttribute('value', paytrail[key] || '');
        form.appendChild(hiddenField);
      }
    }
    document.body.appendChild(form);
    form.submit();
  };

  const newForm = (
    <form
      className='Form'
      onSubmit={handleSubmit}
      style={{ marginTop: '1rem' }}
    >
      <div className='row'>
        <div className='col-sm-12 col-md-6'>
          <span style={{ fontSize: '24px' }}>
            {language(lang).CUSTOMER_DETAILS}
          </span>
          <div className='form-group row'>
            <label className='col-sm-3 col-form-label text-left'>
              {language(lang).NAME}
            </label>
            <div className='col-sm-10 col-md-8'>
              <input
                type='text'
                className='form-control'
                value={billingDetails.name}
                onChange={(e) => {
                  setBillingDetails({
                    ...billingDetails,
                    name: e.target.value,
                  });
                }}
              />
            </div>
          </div>
          <div className='form-group row'>
            <label className='col-sm-3 col-form-label text-left'>
              {language(lang).EMAIL}
            </label>
            <div className='col-sm-10 col-md-8'>
              <input
                type='text'
                className='form-control'
                value={billingDetails.email}
                onChange={(e) => {
                  setBillingDetails({
                    ...billingDetails,
                    email: e.target.value,
                  });
                }}
              />
            </div>
          </div>
          <div className='form-group row'>
            <label className='col-sm-3 col-form-label text-left'>
              {language(lang).STREET}
            </label>
            <div className='col-sm-10 col-md-8'>
              <input
                type='text'
                className='form-control'
                value={billingDetails.address && billingDetails.address.line1}
                onChange={(e) => {
                  setBillingDetails({
                    ...billingDetails,
                    address: {
                      line1: e.target.value,
                      line2: billingDetails.address.line2,
                      city: billingDetails.address.city,
                    },
                  });
                }}
              />
            </div>
          </div>
          <div className='form-group row'>
            <label className='col-sm-3 col-form-label text-left'>
              {language(lang).CITY}
            </label>
            <div className='col-sm-10 col-md-8'>
              <input
                type='text'
                className='form-control'
                value={billingDetails.address && billingDetails.address.city}
                onChange={(e) => {
                  setBillingDetails({
                    ...billingDetails,
                    address: {
                      line1: billingDetails.address.line1,
                      line2: billingDetails.address.line2,
                      city: e.target.value,
                    },
                  });
                }}
              />
            </div>
          </div>
          <div className='form-group row'>
            <label className='col-sm-3 col-form-label text-left'>
              {language(lang).PHONE}
            </label>
            <div className='col-sm-10 col-md-8'>
              <input
                type='number'
                min={0}
                className='form-control'
                value={billingDetails.phone}
                placeholder='eg. 0401234567'
                onChange={(e) => {
                  setBillingDetails({
                    ...billingDetails,
                    phone: e.target.value,
                  });
                  if (e.target.value.length === 10) {
                    setPhoneError(false);
                  } else {
                    setPhoneError(true);
                  }
                }}
              />
              {phoneError && (
                <span style={{ color: '#dc3545', fontSize: '80%' }}>
                  {language(lang).INVALID_PHONE_NUMBER}
                </span>
              )}
            </div>
          </div>

          {distance ? (
            <div className='form-group row'>
              <label className='col-sm-3 col-form-label text-left'>
                {language(lang).DISTANCE}
              </label>
              <label className='col-sm-10 col-md-8 col-form-label text-left'>
                {finnishNumber(distance)}
              </label>
            </div>
          ) : null}
        </div>

        <div className='col-sm-12 col-md-6'>
          <span style={{ fontSize: '24px' }}>
            {language(lang).ORDER_NOTES_DETAILS}
          </span>

          <textarea
            onChange={(e) => {
              setBillingDetails({
                ...billingDetails,
                address: {
                  line1: billingDetails.address.line1,
                  line2: e.target.value,
                  city: billingDetails.address.city,
                },
              });
            }}
            className='form-control'
            placeholder={language(lang).ADDITIONAL_INFO_FOR_RESTAURANT}
            rows={5}
          >
            {billingDetails.address.line2}
          </textarea>

          <div className='mt-3'>
            {props.paytrail && (
              <div
                className='pay-button paytrail-button'
                onClick={handlePayTrailSubmit}
              >
                {language(lang).PAYTRAIL_BANK_PAYMENT}
                <img src={paytrailImg} style={{ height: '100%' }} />
                {processing ? (
                  <span
                    className='spinner-border spinner-border-sm'
                    role='status'
                    aria-hidden='true'
                  ></span>
                ) : (
                  ''
                )}
              </div>
            )}
            {isCard ? (
              <React.Fragment>
                <CardField
                  onChange={(e) => {
                    setError(e.error);
                    setCardComplete(e.complete);
                  }}
                />

                <button
                  className='checkout-button'
                  type='submit'
                  disabled={!stripe}
                >
                  {language(lang).PAY} €{finnishNumber(props.data.total)}
                  {processing && (
                    <span
                      className='spinner-border spinner-border-sm'
                      role='status'
                      aria-hidden='true'
                    ></span>
                  )}
                </button>
                <div className='text-right'>
                  <span
                    className='action-link'
                    onClick={() => {
                      setIsCard(false);
                    }}
                  >
                    {language(lang).SHOW_OTHER_OPTIONS}
                  </span>
                </div>
              </React.Fragment>
            ) : (
              <React.Fragment>
                {!props.paytrail && (
                  <div
                    className='pay-button'
                    onClick={() => {
                      setIsCard(true);
                    }}
                  >
                    {language(lang).PAY} €{finnishNumber(props.data.total)}
                    <i
                      className='fa fa-credit-card'
                      style={{ marginLeft: '5px' }}
                    />
                  </div>
                )}

                {paymentRequest && (
                  <PaymentRequestButtonElement options={{ paymentRequest }} />
                )}
              </React.Fragment>
            )}
          </div>
        </div>
      </div>
    </form>
  );

  return <React.Fragment>{newForm}</React.Fragment>;
};

// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.
const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY);

const Stripe = (props) => {
  let {
    client_key,
    data,
    billingDetails,
    onResetCart,
    currency,
    lang,
    paytrail,
  } = props;

  const redirectToHome = () => {
    props.history.push(`/track-order/${data.id}?success=true`);
  };

  return (
    <div>
      <Elements stripe={stripePromise}>
        <CheckoutForm
          client_key={client_key}
          data={data}
          billingDetails={billingDetails}
          redirectToHome={redirectToHome}
          onResetCart={onResetCart}
          currency={currency}
          lang={lang}
          paytrail={paytrail}
        />
      </Elements>
    </div>
  );
};

const mapStateToProps = (state) => ({
  lang: state.language.language,
});
const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Stripe));
