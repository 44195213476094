import { reducerConstants } from '../constants';

export default function orders(
  state = {
    detail: {},
    reduxOrder: {},
    currentOrder: {},
  },
  action
) {
  switch (action.type) {
    case reducerConstants.INITIATE_ORDER:
      return { ...state, detail: action.payload };
    case reducerConstants.RESET_ORDER:
      return { ...state, detail: {} };
    case reducerConstants.SAVE_ORDER:
      return { ...state, reduxOrder: action.payload };
    case reducerConstants.CURRENT_ORDER:
      return { ...state, currentOrder: action.payload };
    default:
      return state;
  }
}
